import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import SessionsFilterBar from "../../components/SessionsFilterBar";
import { getApplications, getSessions } from "../../services/api_services";
import { ReadWorkspace } from "../../types/workspaceCRUD";
import { Notif } from "../../types/notif";
import { ReadApplication } from "../../types/applicationsCRUD";
import { AuthContext } from "../../contexts/auth_context";

type ReportRecord = {
  name: string;
  totalCount: number;
  genuineCount: number;
  attackCount: number;
  acceptedCount: number;
  rejectedCount: number;
  attackType: {
    paper: number;
    screen: number;
    replay: number;
    mask3d: number;
    other: number;
  };
  gender: {
    man: number;
    woman: number;
  };
  geography: {
    caucasian: number;
    asian: number;
    african: number;
    latinAmerican: number;
    middleEast: number;
    other: number;
  };
  glasses: {
    yes: number;
    no: number;
  };
  environment: {
    indoor: number;
    outdoor: number;
  };
  age: {
    junior: number;
    senior: number;
  };
  rejected: {
    occlusion: number;
    movement: number;
    position: number;
    luminosity: number;
    attack: number;
    child: number;
    duplicated: number;
  };
};

export default function ReportPage({ onNotif }: { onNotif: Notif }) {
  const [loading, setLoading] = useState<number>(0);
  const [totalReport, setTotalReport] = useState<ReportRecord>();
  const [detailsReports, setdetailsReports] = useState<ReportRecord[]>([]);

  const auth = React.useContext(AuthContext);

  const [selWorkspaces, setSelWorkspaces] = React.useState<ReadWorkspace[]>([]);

  const _generateSessionsReport = (sessions: any[], name: string): ReportRecord => {
    return sessions.reduce<ReportRecord>(
      (acc: ReportRecord, curr: any) => {
        for (const annotation of curr.annotations) {
          acc.totalCount += 1;
          if (annotation.rejected !== -1) {
            acc.rejectedCount += 1;
            switch (annotation.rejected) {
              case 0:
                acc.rejected.occlusion += 1;
                break;
              case 1:
                acc.rejected.movement += 1;
                break;
              case 2:
                acc.rejected.position += 1;
                break;
              case 3:
                acc.rejected.luminosity += 1;
                break;
              case 4:
                acc.rejected.attack += 1;
                break;
              case 5:
                acc.rejected.child += 1;
                break;
              case 6:
                acc.rejected.duplicated += 1;
                break;
            }
          }
          if (annotation.label === 0) continue; // session not annotated, move on to the next

          if (annotation.label === 1) {
            // session is annotated as genuine
            acc.genuineCount += 1;
          } else if (annotation.label === 2) {
            // session is annotated as attack
            acc.attackCount += 1;
            switch (annotation.attackType) {
              case 0:
                acc.attackType.paper += 1;
                break;
              case 1:
                acc.attackType.screen += 1;
                break;
              case 2:
                acc.attackType.replay += 1;
                break;
              case 3:
                acc.attackType.mask3d += 1;
                break;
              case 4:
                acc.attackType.other += 1;
                break;
            }
          }
          switch (annotation.environment) {
            case -1:
              break;
            case 0:
              acc.environment.indoor += 1;
              break;
            case 1:
              acc.environment.outdoor += 1;
              break;
          }

          switch (annotation.gender) {
            case -1:
              break;
            case 0:
              acc.gender.man += 1;
              break;
            case 1:
              acc.gender.woman += 1;
              break;
          }
          switch (annotation.geography) {
            case -1:
              break;
            case 0:
              acc.geography.caucasian += 1;
              break;
            case 1:
              acc.geography.asian += 1;
              break;
            case 2:
              acc.geography.african += 1;
              break;
            case 3:
              acc.geography.latinAmerican += 1;
              break;
            case 4:
              acc.geography.middleEast += 1;
              break;
            case 5:
              acc.geography.other += 1;
              break;
          }
          switch (annotation.glasses) {
            case -1:
              break;
            case 0:
              acc.glasses.yes += 1;
              break;
            case 1:
              acc.glasses.no += 1;
              break;
          }
          switch (annotation.age) {
            case -1:
              break;
            case 0:
              acc.age.junior += 1;
              break;
            case 1:
              acc.age.senior += 1;
              break;
          }
        }
        return acc;
      },
      {
        name: name,
        totalCount: 0,
        genuineCount: 0,
        attackCount: 0,
        acceptedCount: 0,
        rejectedCount: 0,
        gender: {
          man: 0,
          woman: 0,
        },
        geography: {
          caucasian: 0,
          asian: 0,
          african: 0,
          latinAmerican: 0,
          middleEast: 0,
          other: 0,
        },
        glasses: {
          yes: 0,
          no: 0,
        },
        environment: {
          indoor: 0,
          outdoor: 0,
        },
        age: {
          junior: 0,
          senior: 0,
        },
        rejected: {
          occlusion: 0,
          movement: 0,
          position: 0,
          luminosity: 0,
          attack: 0,
          child: 0,
          duplicated: 0,
        },
        attackType: {
          paper: 0,
          screen: 0,
          replay: 0,
          mask3d: 0,
          other: 0,
        },
      }
    );
  };

  const _runReport = async (apps: ReadApplication[]) => {
    setLoading(0);
    const pageSize = 500;
    let totalSessions: any[] = [];
    for (let page = 0; page < 100; page++) {
      const sessions = await getSessions(
        onNotif,
        page,
        pageSize,
        [null, null],
        selWorkspaces.map((s) => s.id)
      );
      if (!sessions) break;
      totalSessions = [...totalSessions, ...sessions];
      setLoading(totalSessions.length);
      if (sessions.length < pageSize) break;
    }

    const byApp = new Map<string, any[]>();
    totalSessions.forEach((session: any) => {
      const entry = byApp.get(session.application.name);
      if (!entry) byApp.set(session.application.name, [session]);
      else {
        entry.push(session);
        byApp.set(session.application.name, entry);
      }
    });
    const totalReport = _generateSessionsReport(totalSessions, "Global");
    const reports = apps
      .sort((a1, a2) => a1.name.localeCompare(a2.name))
      .map((app) => _generateSessionsReport(byApp.get(app.name) ?? [], app.name));
    setTotalReport(totalReport);
    setdetailsReports(reports);
    setLoading(-1);
  };

  useEffect(() => {
    (async () => {
      const apps = await getApplications(
        onNotif,
        selWorkspaces.length > 0 ? selWorkspaces.map((ws) => ws.id) : [auth.user!.workspaceId],
        false,
        auth.user?.token
      );
      await _runReport(apps ?? []);
    })();
  }, [selWorkspaces]); // eslint-disable-line react-hooks/exhaustive-deps

  const _renderRecord = (record: ReportRecord) => {
    const renderRecordAnnotationEntry = (name: string, entry: any) => {
      return (
        <>
          <Typography variant="h6" component="h6">
            {name}
          </Typography>
          {Object.keys(entry).map((k) => {
            return <Typography key={k} variant="body1">{`${k} ${(entry as any)[k]}`}</Typography>;
          })}
        </>
      );
    };
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
        wrap="nowrap"
      >
        <Grid item xs={3}>
          {renderRecordAnnotationEntry("Gender", record.gender)}
        </Grid>
        <Grid item xs={3}>
          {renderRecordAnnotationEntry("Geography", record.geography)}
        </Grid>
        <Grid item xs={3}>
          {renderRecordAnnotationEntry("Environment", record.environment)}
        </Grid>
        <Grid item xs={3}>
          {renderRecordAnnotationEntry("Glasses", record.glasses)}
        </Grid>
        <Grid item xs={3}>
          {renderRecordAnnotationEntry("Age", record.age)}
        </Grid>
      </Grid>
    );
  };

  const DetailsRow = (props: { record: ReportRecord; defaultOpen: boolean }) => {
    const { record, defaultOpen } = props;
    const [open, setOpen] = React.useState(defaultOpen);

    return (
      <>
        <TableRow key={record.name}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {record.name}
          </TableCell>
          <TableCell align="right">{record.totalCount}</TableCell>
          <TableCell align="right">{record.genuineCount}</TableCell>
          <TableCell align="right">{record.rejectedCount}</TableCell>
          <TableCell align="right">{record.rejected.occlusion}</TableCell>
          <TableCell align="right">{record.rejected.movement}</TableCell>
          <TableCell align="right">{record.rejected.position}</TableCell>
          <TableCell align="right">{record.rejected.luminosity}</TableCell>
          <TableCell align="right">{record.rejected.attack}</TableCell>
          <TableCell align="right">{record.rejected.child}</TableCell>
          <TableCell align="right">{record.rejected.duplicated}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box m={2}>{_renderRecord(record)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const _renderDetailsTable = (records: ReportRecord[], defaultOpen: boolean) => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Accepted</TableCell>
              <TableCell align="right">Rejected</TableCell>
              <TableCell align="right">Occlusion</TableCell>
              <TableCell align="right">Movement</TableCell>
              <TableCell align="right">Position</TableCell>
              <TableCell align="right">Luminosity</TableCell>
              <TableCell align="right">Attack</TableCell>
              <TableCell align="right">Child</TableCell>
              <TableCell align="right">Duplicated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <DetailsRow key={record.name} record={record} defaultOpen={defaultOpen} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <SessionsFilterBar
        selWorkspaces={selWorkspaces}
        setSelWorkspaces={setSelWorkspaces}
        onNotif={onNotif}
      />
      <Box m={1} />
      {loading === -1 && totalReport ? (
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Typography variant="h5" component="h5">
            Global Report
          </Typography>
          <Box m={1} />
          {_renderDetailsTable([totalReport], true)}
          <Box m={3} />
          <Typography variant="h5" component="h5">
            Details
          </Typography>
          <Box m={1} />
          {_renderDetailsTable(detailsReports, false)}
        </Box>
      ) : (
        <Box
          mt={20}
          display="flex"
          flex="1"
          flexGrow={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
          <Box m={1} />
          <Typography variant="body1">{`Loaded ${loading} records...`}</Typography>
        </Box>
      )}
    </>
  );
}
