export type LivenessResult = "genuine" | "fail" | "error" | "not_reached";
export type FaceComparisonResult = "match" | "mismatch" | "error" | "not_reached";

export enum ResultType {
  FACE_MATCHING = 0,
  CONDUCTOR = 1,
  CARDIAC = 2,
  PAD = 3,
  WEAK_FACE_MATCHING = 4,
  FACE_QUALITY = 5,
}
