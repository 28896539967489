import { FaceComparisonResult, LivenessResult } from "./results";
import { SubscriptionKind } from "./subscriptionsCRUD";

export type PreprocessingParams = {
  singleFace: boolean;
  faceInCenter: boolean;
};

export const MEDIA_TYPES = <const>{
  target: 0,
  reference: 1,
};

export type MediaType = typeof MEDIA_TYPES[keyof typeof MEDIA_TYPES];

export type Media = {
  type: MediaType;
  link: string;
  blob?: string;
  deviceType: string;
  deviceModel: string;
  gdprConsent: string;
  faceDetectionError?: FaceDetectionError;
  preprocessingParams?: PreprocessingParams;
  framesDetection?: unknown;
};

export type Result = {
  type: number;
  modelId: string;
  isGenuine: boolean;
  threshold: number;
  score: number;
  probability: number;
  rndError?: RndError;
};

export interface SessionAppFields {
  id: string;
  name: string;
  workspaceID: string;
  isHidden: boolean;
  isActive: boolean;
  environment: string;
  workspaceName: string;
}

// Note (Guillaume): we reify json data from the database which contains snake case key names.
// Until we perform some data validation, the following type will have snake cased keys
export type SessionAnnotation = {
  type?: number;
  label?: number;
  attack_type?: number;
  gender?: number;
  geography?: number;
  environment?: number;
  glasses?: number;
  rejected?: number;
  face_match?: number;
  age?: number;
  target_face_quality?: number[];
  reference_face_quality?: number[];
};

export enum ConfidenceLevel {
  UNKNOW = 0,
  CONFIDENT = 2,
  NOT_CONFIDENT = 1,
}

export type InsufficientFrameCountError = {
  count: number;
  required: number;
};

export type LandmarkProcessingError = {
  referenceFail: boolean;
  targetFail: boolean;
};

// TODO: Centralize + Simplify
export type FaceDetectionError = {
  errorOneof?:
    | { $case: "corruptedMedia"; corruptedMedia: {} }
    | { $case: "failedLandmarksProcessing"; failedLandmarksProcessing: {} }
    | { $case: "failedFaceDetection"; failedFaceDetection: {} }
    | { $case: "insufficientFrameCount"; insufficientFrameCount: InsufficientFrameCountError }
    | { $case: "multipleFacesDetected"; multipleFacesDetected: {} };
};

export type RndError = {
  padError?: InsufficientFrameCountError;
  recoTargetError?: InsufficientFrameCountError;
  recoRefError?: InsufficientFrameCountError;
  recoLandmarkError?: LandmarkProcessingError;
};

export type Session = {
  results: Array<Result>;
  medias: Array<Media>;
  annotation: SessionAnnotation;
  lifecycle: {
    status: number;
    sync_date?: Date;
    requested_sync: boolean;
    requested_deletion_date?: Date;
  };
  id: string;
  createdAt: Date;
  application: SessionAppFields;
  childrenIDs: Array<string>;
  children: Array<Session>;
  parentID: string;
  hasSynchronizedSession?: boolean;
  videoMetadata?: string;
  modelVersions?: {
    faceComparison?: string;
    liveness?: string;
    vqc?: string;
  };
  liveness?: LivenessResult;
  faceComparison?: FaceComparisonResult;
  livenessConfidence?: ConfidenceLevel;
  faceComparisonConfidence?: ConfidenceLevel;
  tempUserDomain?: string;
};

export type SimplifiedSession = {
  id: string;
  createdAt: Date;
  application: SessionAppFields;
  subscriptionKind: SubscriptionKind;
  hasSynchronizedSession: boolean;
  liveness?: LivenessResult;
  faceComparison?: FaceComparisonResult;
  nRetry: number;
  gdprConsent: string;
};
