import { DialogProps, Typography, Button, Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Notif } from "../../types/notif";
import { ModalV2 as Modal } from "../../components/Modal";
import { getApiKeySecret } from "../../services/api_services";
import { ReadApplication } from "../../types/applicationsCRUD";
import { useScopedTranslation } from "../../i18n";

type Props = DialogProps & {
  onClose: () => void;
  onNotif: Notif;
  application: ReadApplication;
};

export default function ShowApiKeyModal({ open, onClose, onNotif, application }: Props) {
  const { t } = useScopedTranslation("api_page.display_dialog");

  const [apiKey, setApiKey] = useState<string>();

  useEffect(() => {
    (async () => {
      setApiKey(await getApiKeySecret(onNotif, application.id));
    })();
  }, [application, onNotif]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      hideCancel
      onClose={onClose}
      title={t("title", { applicationName: application.name })}
      maxWidth="md"
      body={
        <Box py={2}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>{apiKey}</Typography>
            </Grid>
          </Grid>
        </Box>
      }
      action={
        <Box display="flex" alignItems="center">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button variant="outlined" disableElevation onClick={() => onClose()} fullWidth>
                {t("button_close")}
              </Button>
            </Grid>

            <Grid item xs={8}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => (apiKey ? navigator.clipboard.writeText(apiKey) : undefined)}
                fullWidth
              >
                {t("button_copy_to_clipboard")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
}
