import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
// components
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import { RGX_EMAIL, RGX_EMAIL_DOMAIN } from "../../constants/regexp";
// constants
import { Routes } from "../../constants/routes";
// contexts
import { AuthContext } from "../../contexts/auth_context";
import AuthenticationService from "../../services/authentication_service";
// utils
import { getSessionUser } from "../../utils/auth_util";
import theme from "../../config/theme";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { Path } from "history";
import { SessionUser } from "../../types/auth";
import { useScopedTranslation } from "../../i18n";
import PasswordInput from "../../components/forms/formComponents/PasswordInput";
import { AuthPageContainer } from "./AuthPageBase";
import { NotifProps } from "../../types/notif";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: { margin: theme.spacing(3, 0, 2) },
}));

interface SignInErrorInterface {
  global?: string | undefined;
  email?: string | undefined;
  password?: string | undefined;
}

export default function SignInPage(
  routerProps: RouteComponentProps<{}, {}, { from: { pathname: string } } | undefined>
) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { t, tCommon, i18n } = useScopedTranslation("signin_page");
  const auth = useContext(AuthContext);

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<SignInErrorInterface>({});
  // DS-514: We disable SSO
  // const [ssoLink, setSSOLink] = useState<string>("");
  // const [ssoProvider, setSSOProvider] = useState<string>("");

  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState<boolean>(false);

  const signInHandler = useCallback(
    async (userData: SessionUser, historyRoute: Path) => {
      const sessionUser = await getSessionUser(userData);
      if (!sessionUser) auth.unsetAuthUser();
      else auth.setAuthUser(sessionUser);
      const requestedRoute = routerProps.location.state?.from.pathname;
      const routesWithoutRedirect: string[] = [
        Routes.HOME,
        Routes.SIGNIN,
        Routes.SIGNUP,
        Routes.FINALIZE_SIGNUP,
        Routes.RESET_PASSWORD,
      ];
      if (!requestedRoute || routesWithoutRedirect.includes(requestedRoute)) history.push(historyRoute);
      else history.push(requestedRoute);
    },
    [auth, history, routerProps.location.state]
  );

  async function handleSignIn() {
    setLoading(true);
    const user = await AuthenticationService.signIn(userEmail, userPassword, ({ textKey }: NotifProps) => {
      if (textKey && textKey.startsWith("error")) {
        setErrors({ global: "notif." + textKey });
      }
    });
    if (user) {
      await signInHandler(user, Routes.HOME + location.search);
    }
    setLoading(false);
  }

  async function handleUserEmailEdited(newEmail: string) {
    setUserEmail(newEmail);
    if (newEmail.match(RGX_EMAIL)) {
      const emailDomain = RGX_EMAIL_DOMAIN.exec(newEmail)?.[1] ?? null;
      if (!emailDomain) return;

      // DS-514: We disable SSO
      // const data = await AuthenticationService.generateSSOLinkData(emailDomain);
      // setSSOLink(data?.link ?? "");
      // setSSOProvider(data?.workspaceName ?? "");
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await AuthenticationService.SSOinit();
      } catch (error) {
        console.error("Error initializing SSO:", error);
      }
      if (!AuthenticationService.isSSOCallback(location)) return;
      const grant = AuthenticationService.getGrantFromKeycloak();
      setLoading(true);
      try {
        const user = await AuthenticationService.loginSSO(grant.token);
        await signInHandler({ ...user, refresh_token: grant.refresh_token }, Routes.SESSIONS);
      } catch (error) {
        setErrors({ global: error.message });
      }
      setLoading(false);
    })();
  }, [location, signInHandler]);

  return (
    <AuthPageContainer title={t("title")}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleSignIn();
        }}
      >
        {errors.global && (
          <Box
            color="common.white"
            bgcolor="error.light"
            py={2}
            px={1}
            marginBottom={2}
            borderRadius={theme.shape.borderRadius}
          >
            <b>{i18n.t(errors.global)}</b>
          </Box>
        )}

        <CustomTextField
          value={userEmail}
          onChange={(e) => handleUserEmailEdited(e.target.value)}
          onBlur={(e) => handleUserEmailEdited(e.target.value.trim())}
          helperText={errors.email ?? ""}
          error={!!errors.global || !!errors.email}
          label={t("field_email")}
          autoComplete="email"
          autoFocus
        />

        <PasswordInput label={t("field_password")} password={userPassword} onChange={setUserPassword} />

        <Button
          disabled={loading}
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          className={classes.submit}
          disableElevation
        >
          {loading ? tCommon("loading") : t("button_sign_in")}
        </Button>

        {/* DS-514: We disable SSO */}
        {/* <Button
          disabled={loading || !ssoLink}
          fullWidth
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            window.location.href = ssoLink;
          }}
        >
          {ssoProvider ? t("soo_with_provider", { provider: ssoProvider }) : t("sso")}
        </Button> */}
      </form>
      <Typography
        style={{
          fontSize: 12,
          marginTop: 15,
        }}
      >
        {/* eslint-disable-next-line */}
        <a href={"#"} onClick={() => setIsForgotPasswordOpen(true)}>
          {t("forgot_password")}
        </a>
      </Typography>
      <ForgotPasswordModal isOpen={isForgotPasswordOpen} onClose={() => setIsForgotPasswordOpen(false)} />
    </AuthPageContainer>
  );
}
