import { Box, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ResetPasswordForm from "../../components/forms/ResetPasswordForm";

import { Routes } from "../../constants/routes";
import { useScopedTranslation } from "../../i18n";
import AuthenticationService from "../../services/authentication_service";
import { AuthPageContainer } from "./AuthPageBase";

export default function FinalizeUserSignup() {
  const history = useHistory();
  const loc = useLocation();
  const { t } = useScopedTranslation("finalize_signup_page");

  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState(false);

  const getTokenFromQuery = () => {
    const q = new URLSearchParams(loc.search);
    return q.get("token");
  };

  useEffect(() => {
    setToken(getTokenFromQuery());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function resetPassword(password: string) {
    setError(false);

    try {
      await AuthenticationService.resetPasswordStep2(token!, password);
      history.push({
        pathname: `${Routes.SIGNIN}`,
        search: loc.search,
      });
    } catch (error) {
      setError(true);
    }
  }

  if (!token) {
    return <Box>{t("missing_token")}</Box>;
  }

  return (
    <AuthPageContainer title={t("title")}>
      <ResetPasswordForm
        onValidate={(passw) => resetPassword(passw)}
        validateButtonTitle={t("button_sign_up")}
      />
      {error && (
        <Paper elevation={1} style={{ color: "red", padding: "2%", textAlign: "center" }}>
          {t("generic_error")}
        </Paper>
      )}
    </AuthPageContainer>
  );
}
