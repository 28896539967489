import { useState, MouseEvent } from "react";

import { Button, ListItemText, makeStyles, Menu, MenuItem } from "@material-ui/core";

import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../i18n";
import { MenuArrowDown } from "./MenuArrowDown";

const languageMenuId = "language-select-menu";

const useStyles = makeStyles(() => ({
  menuItemFocused: {
    backgroundColor: "lightgray",
  },
}));

export function LanguageSwitchMenu() {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentLanguage = i18n.language.split("-")[0]; // extract the ISO 639-1 country code
  const isLanguageMenuOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={(e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
        aria-controls={languageMenuId}
      >
        {currentLanguage}
        <MenuArrowDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={languageMenuId}
        keepMounted
        open={isLanguageMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        {Object.entries(LANGUAGES).map(([lang, { nativeName }], idx) => (
          <MenuItem
            onClick={() => {
              i18n.changeLanguage(lang);
              setAnchorEl(null);
            }}
            className={clsx(currentLanguage === lang && classes.menuItemFocused)}
            key={idx}
          >
            <ListItemText primary={nativeName} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
