import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme, // TODO (Guillaume): Use `createTheme` when Mui fixes their shit (https://stackoverflow.com/a/64135466)
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      light: "#3D59E8",
      main: "#0A175C",
      contrastText: "#fff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#3D59E8",
      main: "#3D59E8",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    success: {
      main: "#05DB91",
    },
    error: {
      main: "#E47589",
    },
  },
  typography: {
    fontFamily: ['"Mulish"', '"Roboto"', '"Helvetica"', '"Arial"', "sans-serif"].join(","),
  },
});

theme.overrides = {
  ...theme.overrides,
  MuiPopover: {
    paper: {
      boxShadow: "0px 10px 30px rgba(57, 57, 57, 0.08)",
    },
  },
  MuiFormControl: {
    root: {
      margin: `${theme.spacing(1)}px 0`,
      minWidth: 120,
    },
    marginNormal: {
      marginTop: theme.spacing(1),
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: "none",
    },
    stickyHeader: {
      backgroundColor: "white",
    },
  },
  MuiTableContainer: {
    root: {
      border: "none",
    },
  },
  MuiTableHead: {
    root: {
      border: "none",
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: theme.palette.common.white,
    },
    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
  MuiTab: {
    selected: {
      color: theme.palette.common.black,
    },
    root: {
      backgroundColor: "transparent",
      borderRadius: theme.shape.borderRadius,
      zIndex: 1,
    },
    wrapper: {
      flexDirection: "row",
      justifyContent: "center",
      height: "100%",
      "@global": {
        ".MuiSvgIcon-root": {
          marginBottom: "0px !important",
          marginRight: theme.spacing(1),
        },
      },
    },
    labelIcon: {
      minHeight: "auto",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  MuiTabs: {
    indicator: {
      height: "100%",
      zIndex: 0,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[200],
    },
    root: {
      // backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
    },
    flexContainer: {
      height: "100%",
    },
  },
  MuiChip: {
    label: {
      color: theme.palette.common.black,
    },
  },
};

export default responsiveFontSizes(theme);
