import { Authorizations } from "@unissey/utils";

import { Button, Tooltip, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";

import CreateTeamMember from "./CreateTeamMember";
import TitledSection from "../../components/TitledSection";
import UpdateTeamMember from "./UpdateTeamMember";
import CustomTable from "../../components/CustomTable";

import { getUsers } from "../../services/api_services";
import { Notif } from "../../types/notif";
import { AuthorizationException, ReadUser, Role, UpdateUser } from "../../types/userCRUD";
import { AuthContext } from "../../contexts/auth_context";
import { getSessionUser, hasAuthorizations } from "../../utils/auth_util";
import { useScopedTranslation } from "../../i18n";

function userOrdering(user1: ReadUser, user2: ReadUser) {
  const wsNameOrdering = user1.workspace.name.localeCompare(user2.workspace.name);
  if (wsNameOrdering !== 0) return wsNameOrdering;
  const firstNameOrdering = user1.firstName.localeCompare(user2.firstName);
  if (firstNameOrdering !== 0) return firstNameOrdering;
  const lastNameOrdering = user1.lastName.localeCompare(user2.lastName);
  if (lastNameOrdering !== 0) return lastNameOrdering;
  return 0;
}
export default function TeamPage({ onNotif }: { onNotif: Notif }) {
  const auth = useContext(AuthContext);
  const { t, tCommon } = useScopedTranslation("team_page");

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<ReadUser[]>();
  const [memberToEdit, setMemberToEdit] = useState<Required<UpdateUser>>({
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    position: "",
    roleIds: "",
  });
  const [userToEditTargetWorkspaceId, setUserToEditTargetWorkspaceId] = useState<string>();

  const canEditUser = hasAuthorizations(auth, [Authorizations.USER_EDIT]);
  const canEditWorkspace = hasAuthorizations(auth, [Authorizations.WORKSPACE_EDIT]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setMembers((await getUsers(onNotif))?.sort(userOrdering));
      setIsLoading(false);
    })();
  }, [auth.user, onNotif, canEditWorkspace, isCreate, isEdit]);

  function AddUserButton() {
    if (!canEditUser) return null;
    return (
      <Button onClick={() => setIsCreate(true)} variant="outlined">
        {t("button_invite")}
      </Button>
    );
  }

  function getRoleColumn(roles: Role[], exceptions: AuthorizationException[], userIteration: number) {
    let displayRoles, displayExceptions;

    if (roles.length > 0) {
      displayRoles = roles.map((role, idx) => (
        <Tooltip title={<h3> {role.description} </h3>} key={idx}>
          {<Typography>{role.name}</Typography>}
        </Tooltip>
      ));
    }
    if (exceptions.length > 0) {
      const exceptionsTooltipTitle = exceptions.map((e) => (
        <h3>
          {e.id} : {e.name}
        </h3>
      ));
      displayExceptions = (
        <Tooltip title={exceptionsTooltipTitle} key={userIteration}>
          {<Typography color="error">{t("table.authorization_exceptions")}</Typography>}
        </Tooltip>
      );
    }

    if (roles.length === 0 && exceptions.length === 0) return "N/A";
    else return [displayRoles, displayExceptions];
  }

  function EditUserButton({ member }: { member: ReadUser }) {
    if (!hasAuthorizations(auth, [Authorizations.USER_EDIT])) return null;
    return (
      <Button
        onClick={() => {
          // setMemberToEdit({ ...member, roleIds: member.roles.map((role) => role.id) });
          setMemberToEdit({ ...member, roleIds: member.roles[0]?.id ?? "" });
          setIsEdit(true);
          setUserToEditTargetWorkspaceId(member.workspace.id);
        }}
        variant="outlined"
      >
        {tCommon("button_edit")}
      </Button>
    );
  }

  const getRows = (members: ReadUser[]) => {
    return members.map((member: ReadUser, idx) => [
      <Typography>
        {member.firstName} {member.lastName}
      </Typography>,
      <Typography>{member.workspace.name}</Typography>,
      <Typography component="span">{getRoleColumn(member.roles, member.exceptions, idx)}</Typography>,
      <EditUserButton member={member} />,
    ]);
  };

  const headers = [t("table.header_name"), t("table.header_workspace"), t("table.roles"), ""];

  return (
    <>
      <TitledSection
        child={
          members ? (
            <CustomTable
              rows={getRows(members)}
              heads={headers}
              isLoading={isLoading}
              setMinHeight
              lastColAlignRight={canEditUser}
            />
          ) : (
            <h1>{tCommon("loading")}</h1>
          )
        }
        action={<AddUserButton />}
      />
      {canEditUser && memberToEdit ? (
        <UpdateTeamMember
          setUserToEdit={setMemberToEdit}
          userToEdit={memberToEdit}
          targetWorkspaceId={userToEditTargetWorkspaceId}
          open={isEdit}
          onClose={() => setIsEdit(false)}
          onNotif={onNotif}
          onEdit={() => {
            // FIXME: The following condition will probably be placed on the edition button if we decide to move
            //  the update of your own user to the profile page
            if (memberToEdit!.id === auth.user!.userId)
              (async () => {
                const sessionUser = await getSessionUser(auth.user!);
                if (!sessionUser) auth.unsetAuthUser();
                else auth.setAuthUser(sessionUser);
              })();
            setIsEdit(false);
          }}
        />
      ) : null}
      {canEditUser ? (
        <CreateTeamMember open={isCreate} onClose={() => setIsCreate(false)} onNotif={onNotif} />
      ) : null}
    </>
  );
}
