import { EN } from "@unissey/sdk-react__next";
import { ErrorCode, FieldCode, Roles } from "@unissey/utils";
import { errorCodeKey, fieldCodeKey, roleKey } from "..";
import { config } from "../../constants/env";

export default {
  button_create: "Create",
  button_edit: "Edit",
  button_delete: "Delete",
  button_cancel: "Cancel",
  button_confirm: "Confirm",
  button_back: "Back",

  loading: "Loading…",

  no_data: "No data available",

  clear_all: "Clear all",
  select_all: "Select all",
  clear_all_filters: "Clear all filters",

  settings: {
    injection_activation_title: "Activation of anti-injection measures",
    injection_activation_help: "Activation at the beginning of the process only",
    preset_title: "Acquisition presets",
  },

  roles: {
    [roleKey(Roles.SUPER_ADMINISTRATOR)]: "Super Administrator",
    [roleKey(Roles.RD_USER)]: "R&D user",
    [roleKey(Roles.BUSINESS_USER)]: "Business administrator",
    [roleKey(Roles.SALES_USER)]: "Sales user",
    [roleKey(Roles.TECHNICAL_ADMINISTRATOR)]: "Technical Administrator",
    [roleKey(Roles.ADMINISTRATOR)]: "Administrator",
    [roleKey(Roles.USER)]: "User",
    [roleKey(Roles.DEMO_ACCESS)]: "Demo access",
  },

  roles_descriptions: {
    [roleKey(Roles.SUPER_ADMINISTRATOR)]: "Full administrator - All authorizations",
    [roleKey(Roles.RD_USER)]:
      "Manage all workspaces (incl. sub-workspaces) and access sessions details (R&D)",
    [roleKey(Roles.BUSINESS_USER)]: "Manage all workspaces (incl. sub-workspaces) and subscriptions",
    [roleKey(Roles.SALES_USER)]: "View all workspaces (incl. sub-workspaces) and manage temporary users",
    [roleKey(Roles.TECHNICAL_ADMINISTRATOR)]:
      "Manage all workspaces (incl. sub-workspaces) and manage integration through API Keys",
    [roleKey(Roles.ADMINISTRATOR)]: "Manage all workspaces (incl. sub-workspaces)",
    [roleKey(Roles.USER)]: "View all workspaces (incl. sub-workspaces)",
    [roleKey(Roles.DEMO_ACCESS)]: "Access Unissey demo (with no access to other tabs)",
  },

  sub_kind: {
    free_demo: "Free Demo",
    integrated_demo: config.useContinuousIntegrationSubscriptions
      ? "Continuous integration"
      : "Trial subscription",
    license: "License",
    pay_as_you_go: "Pay-as-you-go",
    per_packet: "Bucket subscription",
  },

  page_headers: {
    sessions: "Sessions",
    demo: "Demo",
    demo_capture: "Demo Capture",
    profile: "Profile",
    workspaces: "Workspace",
    team: "Team",
    subscriptions: "Subscriptions & Consumption tracker",
    applications: "API Keys",
    documentation: "Documentation",
    report: "Report",
  },
  nav_menu: {
    demo: "Demo",
    sessions: "Sessions",
    workspaces: "Workspace",
    team: "Team",
    subscriptions: "Subscriptions",
    applications: "API Keys",
    documentation: "Documentation",
    report: "Report",
  },
  nav_top_user_menu: {
    profile: "Profile",
    logout: "Log Out",
  },

  demo_page: {
    title: "Terms of Use",
    terms_of_use: `I certify that I have read and I accept the <0>Terms of Use</0>`,
    privacy_policy: `
        I acknowledge that I have read the <0>Privacy Policy</0>
        and have understood all of its terms relating to the processing of my personal data and my image.
        I expressly accept the processing of my personal data and the capture of my image by UNISSEY for the
        purposes of using the Application, to improve it and the algorithms it contains, as well as for
        statistical purposes. You may exercise your rights to your personal data at any time, following the
        procedure described in the Privacy Policy
    `,

    network_err: "Network Error",

    terms_of_use_url: "https://storage.googleapis.com/unissey-shared/unissey-en-terms-of-service.pdf",
    privacy_policy_url: "https://storage.googleapis.com/unissey-shared/unissey-en-privacy-policy.pdf",

    liveness: "Liveness",
    liveness_plus_face_comparison: "Liveness + Face Comparison",

    results_screen: {
      liveness: "Liveness",
      face_comparison: "Face Comparison",
      finish: "Finish",
      results: "Results",
      max_retries_reached: "You have reached the maximum amount of retries",
      retry: "Try again",
    },

    sdk_strings: {
      ...EN,
    },

    analysing: "Analysing",

    injection_instructions: {
      title_page1: "Activating anti-injection measures (1/2)",
      title_page2: "Activating anti-injection measures (2/2)",

      subtitle: "instructions to read before first use",

      content: {
        what_is_injection_header: "What is an attack by injection ?",
        what_is_injection_text:
          "As opposed to presentation attacks (where the fraudster physically shows his attack instrument to the camera to be captured on the video recording), an injection attack (or digital attack) tries to modify the data flow sent by the camera to the application using digital tools, such as a virtual camera.",

        measures_header: "Unissey's measures to detect injection",
        measures_text_p1:
          "We have developed <0>counter-measures to guarantee the genuineness and authenticity of the camera used and of the video registered</0>, to detect attacks by video injection.",
        measures_text_p2:
          "Activating these measures hence allows the detection of such fraud attempts. However, it also increases the risk of a false rejection (i.e. a genuine person being wrongly rejected by the solution). Indeeed, certain device configurations do not allow our algorithms to guarantee the reliability of the browser and of the camera and hence to guarantee the security of the solution. When in doubt, the Unissey solution will reject the request.",

        prevention_header: "Preventing false rejection",
        prevention_text_p1: "To reduce the false rejection rate, we recommend:",
        prevention_bullet1:
          "the use of a popular browser, Chrome <0>{{iconChrome}}</0> or Safari <1>{{iconSafari}}</1> being preferred",
        prevention_bullet2: "the use of your integrated camera",
        prevention_bullet3: "that you make sure the camera is not open in another tab (mandatory)",
        prevention_text_p2:
          "We do not provide the end users details on why they may be rejected (regardless of the attack type), and so, not to provide any clue to a potential fraudster. Our integrating clients will however have access to this level of details.",

        limitation_header: "Limitation - beta version",
        limitation_text:
          "These counter-measures are a first level of protection, i.e. they significantly reduce the probability of success of an injection attack made by fraudsters with little experience, but they do not yet guarantee a high level of security. More advanced counter-measures will be integrated as developments are held.",
      },

      button_next: "next",
      button_confirm: "I understand",
      button_prev: "Previous",
    },
  },
  signin_page: {
    title: "Sign in",
    button_sign_in: "Sign in",
    sso: "Single sign-on",
    field_email: "Email address",
    field_password: "Password",
    soo_with_provider: "Sign in with {{provider}}",
    forgot_password: "Forgot password?",
    forgot_password_dialog: {
      title: "Reset password",
      content: "Enter the email associated with your account",
      instructions:
        "If you have an account with Unissey, you will receive an email with a link to finalize your password reset.",
      field_email: "Email address",
      button_ok: "Ok",
      button_cancel: "Cancel",
    },
  },

  signup_page: {
    title: "Sign up",
    error_invalid_password: "Invalid password",
    field_email: "Email address",
    field_password: "Password",
    field_confirm_password: "Confirm password",
    field_accept_terms_of_use: "I Agree to the terms of services and data policy.",
    button_sign_up: "Sign up",
    already_have_an_account: "Already have an account?",
    sign_in_link: "Sign in",
  },
  reset_password_page: {
    title: "Reset password",
    generic_error: "Error during password edition, please try again later",
    field_new_password: "New password",
    field_confirm_new_password: "Confirm new password",
    button_reset_password: "Reset password",
    notice_password_dont_match: "Passwords do not match",
    gauge: {
      password_strength: "Password strength",
      tip_when_weak: "Increase strength by adding more and varied characters.",
      score_weak: "Too weak",
      score_good: "Good",
    },
    chips: {
      "label_at-least-10-chars": "10 characters",
      "label_at-least-one-digit": "1 digit",
      "label_at-least-one-lowercase": "1 lowercase letter",
      "label_at-least-one-uppercase": "1 uppercase letter",
      "label_at-least-one-special-char": "1 special character",
      "tooltip_special-chars": "One of",
    },
    missing_token: "Invalid or missing token",
  },
  finalize_signup_page: {
    title: "Finalize sign up",
    generic_error:
      "You have missed the deadline to activate your account, please contact your Unissey administrator",
    missing_token: "Invalid or missing token",
    button_sign_up: "Sign up",
  },
  profile_page: {
    information: {
      title: "Information",
      first_name: "First name",
      last_name: "Last name",
      email: "Email address",
      assigned_roles: "Assigned role",
      workspace: "Workspace",
    },

    change_password: "Update password",

    button_saving: "Saving…",
    button_delete_account: "Delete my account",

    change_password_dialog: {
      title: "Authorize operation",
      content: `
        In order to authorize this operation, we need to confirm your identity.
        Please enter your previous password before confirming.
      `,
      field_current_password: "Current password",
    },
  },
  documentation_page: {
    table: {
      header_type: "Type",
      header_link: "Link",
    },
    previous_sdk_version_notice:
      "If you need to read the documentation of the previous SDK, please ask your contact at Unissey.",
  },
  restricted_access_page: {
    title: "Restricted access.",
    content: "If you believe this is an error, please contact your system administrator.",
    user_authorizations: "User authorizations",
    required_authorizations: "Required authorizations",
  },
  workspace_page: {
    button_create: "Create workspace",
    button_create_temp_access: "Create temporary access",

    link_temporary_extract: "Temporary workspace extract",

    button_invite: "Invite a member",
    own_workspace: "Your workspace",
    table: {
      header_name: "Workspace name",
    },

    update_member: {
      title: "Edit team member",
      fields: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email Address",
        role: "Select role",
        personalDataReader: "Give personal data reader access to this user",
        personalDataReaderSubMsg: "I certify this user has read and accepted the GDPR awareness process",
      },
      save: "Save",
    },

    members_listing: {
      headers: {
        firstColumn: "Team member",
        secondColumn: "Role",
        thirdColumn: "Personal data",
      },
      personal_data: {
        yes: "Yes",
        no: "No",
      },
    },

    edit_dialog: {
      title: "Edit workspace",
      field_workspace_name: "Workspace name",
      button_delete: "Delete workspace",
    },

    create_dialog: {
      title: "Create a workspace",

      subtitle_general_information: "General information",
      subtitle_subscription: "Subscription",
      field_workspace_name: "Workspace name",

      team_members_section_title: "Team members",

      subtitle_administrator: "Administrator",
      notice_send_invite_to_administrator:
        "By creating this workspace, you will automatically send an invitation to the administrator above",

      subscription_fields: {
        kind: "Type de souscription",
        name: "Nom",
        max_sessions: "Max session count",
        start_date: "Subscription start date",
        end_date: "Subscription end date",
      },

      administrator_fields: {
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email Address",
        role: "Role",
        personalDataReader: "Give personal data reader access to this user",
        personalDataReaderSubMsg: "I certify this user has read and accepted the GDPR awareness process",
      },

      button_create_and_invite: "Create",
    },

    delete_confirm_dialog: {
      title: "Deleting {{firstName}} {{lastName}}",
      message: "Do you want to remove {{firstName}} {{lastName}} from {{workspaceName}} workspace?",
      cancelBtn: "Cancel",
      deleteBtn: "Delete",
    },

    create_temp_access_dialog: {
      title: "Create a temporary access",

      field_first_name: "First name",
      field_last_name: "Last name",
      field_email: "Email address",

      button_create: "Create",
      button_create_and_invite: "Create and send invitation",
      button_finish: "Finish",
      button_send_email: "Send email",
      button_copy_link: "Copy link",

      notice_created_and_email_sent: "A temporary access has been created for <0>{{email}}</0>.",
      notice_interact_when_activated:
        "The user can interact with our plateforme during {{minutes}}min as soon as the access is activated.",
      notice_send_procedure:
        "The URL link below can be sent to them manually by the administrator (by copying/pasting it in an email) or by sending an automatic email.",
    },

    temp_demo_usage_dialog: {
      title: "Temporary demo usage",

      field_start: "Start date",
      field_end: "End date",

      column_email: "Email",
      column_created_at: "Creation date",
      column_activated_at: "Activation date",
      column_session_count: "Session count",

      filter_domain: "domain",

      never_activated: "Never",
    },
  },
  team_page: {
    button_invite: "Invite",

    table: {
      header_name: "Name",
      header_workspace: "Workspace",
      roles: "Role",

      authorization_exceptions: "Authorization exceptions", // Displayed in red in the roles column when applicable
    },

    create_user_dialog: {
      title: "Invite someone to your team",
      field_select_workspace: "Select a workspace",
      button_invite: "Invite",
    },
  },
  subscription_page: {
    button_create: "Create subscription",

    table: {
      header_name: "Name",
      header_workspace: "Workspace",
      header_status: "Status",
      header_type: "Type",
      header_start_date: "Start date",
      header_end_date: "End date",
      header_max_sessions: "Max sessions",
      header_session_count: "Session count",
    },

    details_modal: {
      title: "Subscription",

      title_details: "Details",
      row_name: "Subscription plan name",
      row_type: "Subscription plan type",
      row_status: "Status",
      row_workspace: "Workspace name",
      row_max_sessions: "Allowed number of sessions in plan",
      row_start_date: "Start date",
      row_end_date: "End date",

      status_active: "Active",
      status_inactive: "Inactive",

      title_consumption: "Consumption",
      row_total_session_count: "Total number of sessions conducted",
      row_remaining_session_count: "Total number of remaining sessions in plan",

      title_details_consumption: "Detailed consumption by API Key",

      "N/A": "N/A",
    },

    create_dialog: {
      title: "Create subscription",
      button_create: "Create subscription",
    },

    edit_dialog: {
      title: "Edit subscription",
      button_delete: "Delete subscription",
    },

    // This is reused by the two dialogs above
    form: {
      field_subscription_name: "Subscription name",
      field_workspace: "Select workspace",
      field_subscription_type: "Select subscription type",
      field_max_sessions: "Max session count",
      field_start_date: "Subscription start date",
      field_end_date: "Subscription end date",
    },
  },
  api_page: {
    button_create: "Create API Key",

    no_data: "No API keys available. Technical administrators can create API keys from this page.",

    table: {
      header_name: "Name",
      header_subscription_name: "Subscription name",
      header_subscription_kind: "Subscription kind",
      header_workspace: "Workspace",
      header_key: "Key",

      key_revoked: "Revoked",

      button_display: "Display",
      button_revoke: "Revoke",
    },

    create_dialog: {
      title: "Create an API Key",

      field_application_name: "Api Key name",
      field_workspace: "Select workspace",
      field_subscription: "Select subscription",

      button_create: "Create API Key",
    },

    edit_dialog: {
      title: "Edit API Key",

      field_application_name: "API key name",
    },

    display_dialog: {
      title: "Display API key: {{applicationName}}",

      button_copy_to_clipboard: "Copy to clipboard",
      button_close: "Close",
    },
  },
  sessions_page: {
    search_session_id: "Search a specific session ID…",
    invalid_id: "Unknown session ID",
    status_selection: {
      label_all: "All",
      label_synchronized: "Synchronized",
      label_unsynchronized: "Unsynchronized",
    },

    filters: {
      label_search_by_id: "Search a specific session id",
      label_filter_workspace: "Search a workspace",
      label_filter_applications: "Search an API key",
      label_filter_start_date: "After date",
      label_filter_end_date: "Before date",
    },

    table: {
      header_date: "Date",
      header_workspace: "Workspace",
      header_application: "Application",
      header_subscription_type: "Subscription type",
      header_liveness: "Liveness",
      header_face_comparison: "Face comparison",
      header_retries: "Attempts",
      header_status: "Status",
      header_gdpr: "GDPR Consent",

      chip_synchronized: "Synchronized",
      chip_unsynchronized: "Unsynchronized",
    },

    details_modal: {
      breadcrumbs_sessions: "Sessions",
      button_previous: "Previous",
      button_next: "Next",

      title_video: "Video",
      title_video_plural: "Video - Attempt {{attempt}}/{{count}}",
      title_reference_picture: "Reference picture",
      title_target_picture: "Target picture",

      notice_media_unavailable_gdpr: "Personal data not available due to GDPR consent not given",
      notice_media_unavailable_missing_auth:
        "You do not have the authorization to view personal data. For any purpose, please contact your administrator.",

      title_results: "Results",
      row_results_liveness: "Liveness",
      row_results_face_comparison: "Face comparison",
      label_date: "Date",
      label_application: "API key",

      title_details_liveness: "Liveness",
      title_details_face_comparison: "Face comparison",
      title_details_face_quality: "Face quality",
      title_details_metadata: "Metadata",
      title_preprocessing_params: "Preprocessing Params",
      title_preprocessing_error: "Preprocessing Error",
      title_frames_detection: "Frames Detection",

      title_details_models: "Models",
      header_model_type: "Type",
      header_model_version: "Version",
      model_version_liveness: "Liveness",
      model_version_face_comparison: "Face comparison",
      model_version_vqc: "VQC",

      title_lifecycle: "Lifecycle",
      lifecycle_synchronized: "SYNCHRONIZED",
      lifecycle_unsynchronized: "UNSYNCHRONIZED",
      requested_deletion: "Requested deletion",
      request_sync: "Request sync",
      request_unsync: "Request unsync",

      title_annotations: "Annotations",

      info_session_unavailable: "Session not available",
      verification: "Verification",
      decision: "Decision",
      confidence: "Confidence",
      company: "Company",
      confidenceLevelHelp:
        "Represents the degree of confidence in the session's results. When the levels of confidence are low, the session needs to be reviewed to guarantee security.",
    },
  },

  strong_confirm_dialog: {
    title: "Authorize operation",
    content: `
      In order to authorize this operation, please enter "<0>{{confirmText}}</0>"
      before confirming.
    `,
    field_confirm_text: "Confirmation text",
  },

  // This does not belong to a page because it is reused by:
  //  · workspace -> create
  //  · team -> invite
  create_user_dialog: {
    field_first_name: "First name",
    field_last_name: "Last name",
    field_email: "Email address",

    field_role_team_invite: "Select role",
    field_role_workspace_create: "Assigned role",

    notice_gdpr_personal_data_reader_selected: `
      The Personal Data Reader role gives access to sensitive personal data.
      This role should only be assigned if the individual is aware of the GDPR.
    `,
  },

  transient_demo_page: {
    notice_error_missing_user_id: "Sorry, but that web page link is malformed",
    notice_error_user_expired: "Your temporary access has expired, but the adventure still goes on!",
    notice_error_help_text: "If you think this is an error, please contact us at: <0>{{salesLeadEmail}}</0>",

    notice_welcome_p1: "Welcome to the Unissey demo platform!",
    notice_welcome_p2:
      "Once started you will have 15 minutes to use our Liveness and Face Comparison solutions",
    notice_welcome_p3: "Feel free to test as much as you can!",
    notice_welcome_p4: "Your 15 minutes trial has started",

    notice_welcome_p5: "You have",
    notice_welcome_p6: "remaining",

    start_button: "Start my 15 minutes trial",
  },

  chips: {
    "N/A": "N/A",
    genuine: "Success",
    fail: "Fail",
    match: "Match",
    mismatch: "Mismatch",
    error: "Error",
    not_reached: "Not Reached",
  },

  notif: {
    success: {
      api_key_revoked: "{{applicationName}} was successfully revoked",
      api_key_created: "New API key {{applicationName}} was successfully created!",
      api_key_edited: "API key {{applicationName}} was successfully edited!",

      account_deleted: "Your account has been deleted",

      subscription_created: "Subscription successfully created",
      subscription_edited: "Subscription successfully edited",

      workspace_created: "New workspace {{workspaceName}} was successfully created!",
      workspace_edited: "Workspace successfully edited",
      workspace_deleted: "Workspace successfully deleted",

      user_created: "User created",
      user_edited: "User successfully edited",
      user_deleted: "User successfully deleted",

      link_copied_to_clipboard: "Link copied to clipboard",
      transient_demo_email_sent: "A temporary access has been sent to {{email}}",
    },

    error: {
      api_key_edit_missing_fields: "Every required field must be filled out to edit the API key",
      user_edit_id_not_found: "Cannot edit user: cannot find user identifier",

      invalid_date_filter_input: "Filter by date inputs are invalid",
      missing_api_key_fields: "Every required field must be filled out to create an API key",

      [errorCodeKey(ErrorCode.notFound.APPLICATION_NOT_FOUND)]: "Application not found",
      [errorCodeKey(ErrorCode.notFound.SESSION_NOT_FOUND)]: "Session not found",
      [errorCodeKey(ErrorCode.notFound.SUBSCRIPTION_NOT_FOUND)]: "Subscription not found",
      [errorCodeKey(ErrorCode.notFound.SUBSCRIPTIONS_NOT_FOUND)]: "No subscription found",
      [errorCodeKey(ErrorCode.notFound.USER_NOT_FOUND)]: "User not found",
      [errorCodeKey(ErrorCode.notFound.USERS_NOT_FOUND)]: "No users found",
      [errorCodeKey(ErrorCode.notFound.TOKEN_NOT_FOUND)]: "Token not found",
      [errorCodeKey(ErrorCode.notFound.WORKSPACE_NOT_FOUND)]: "Workspace not found",
      [errorCodeKey(ErrorCode.notFound.WORKSPACES_NOT_FOUND)]: "No workspace found",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_CANNOT_FIND_USER)]: "User not found",
      [errorCodeKey(ErrorCode.notFound.SSO_AUTH_CANNOT_FIND_USER_ID)]: "User not found",
      [errorCodeKey(ErrorCode.notFound.SESSION_HAS_EXPIRED)]: "Session has expired",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_USER_NOT_FOUND)]: "Unknown user",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_WORKSPACE_NOT_FOUND)]: "Workspace not found",

      [errorCodeKey(ErrorCode.invArgs.ROLE_VERIFICATION_FAILED)]: "Failed to assign role",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_REMOVE_LAST_WORKSPACE_ADMINISTRATOR)]:
        "At least one administrator is needed to manage your workspace. They cannot be deleted",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_EDIT_LAST_WORKSPACE_ADMINISTRATOR_ROLE)]:
        "At least one administrator is needed to manage your workspace. The role cannot be changed",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_CREATE_FREE_DEMO_API_KEY)]:
        "Failed to create a Free Demo application key",
      [errorCodeKey(ErrorCode.invArgs.INVALID_SUBSCRIPTION_ID)]: "Invalid subscription identifier",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_NAME_EXISTS)]: "Workspace name already exists",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_NAME_EXISTS_SCHEDULED_DELETION)]:
        "Workspace name is unavailable because it already exists and is being deleted",
      [errorCodeKey(ErrorCode.invArgs.SSO_LOGIN_INVALID_EMAIL)]: "Invalid email",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_INVALID_ID)]: "Invalid workspace identifier",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SESSION)]:
        "Failed to create session, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.GET_SESSION)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.GET_SESSIONS)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.SYNC_SESSION)]:
        "Failed to synchronize session, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.REVIEW_SESSION)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.EDIT_USER_ROLES)]: "Failed to edit user role",
      [errorCodeKey(ErrorCode.invArgs.GET_USER_BY_AUTH_ID)]: "Action failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.AUTHENTIFY_SSO)]: "Action failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.RESET_PASSWORD)]: "Reset failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.VALIDATE_PASSWORD_RESET_TOKEN)]: "Reset failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.EDIT_PASSWORD)]: "Edit failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.GET_USER)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.EDIT_USER)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.NEW_USER)]: "Failed to create user, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_WORKSPACE)]:
        "Failed to create workspace, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_WORKSPACE)]:
        "Failed to edit workspace, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.DELETE_USER)]: "Failed to delete user, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LIST_APPLICATIONS)]:
        "Failed to list application, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_APPLICATION)]:
        "Failed to create application, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_APPLICATION)]:
        "Failed to update application, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SUBSCRIPTION)]:
        "Failed to create subscription, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_SUBSCRIPTION)]:
        "Failed to update subscription, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LOGIN)]: "Failed to connect, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LOGIN_SSO)]: "Failed to connect, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.REFRESH_LOGIN_TOKEN)]:
        "Failed to connect, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SUBSCRIPTION_NAME_ALREADY_EXISTS)]:
        "A subscription with that name already exists. Please rename your subscription",
      [errorCodeKey(ErrorCode.invArgs.CREATE_WORKSPACE_NAME_TOO_LONG)]:
        "Workspace creation failed: limited to 30 characters",

      [errorCodeKey(ErrorCode.permDenied.CANNOT_ACCESS_ROUTE_MISSING_AUTHORIZATION)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_MAX_USES_EXCEEDED)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_WRONG_TYPE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.MISSING_API_KEY_AUTHORIZATION)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.LIST_API_KEY_UNAUTHORIZED_WORKSPACE_IDS)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SUB_WORKSPACE_AUTHORIZATION)]:
        "Cannot access this workspace: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SESSION_DATA_WRITE_AUTHORIZATION)]:
        "Cannot annotate session: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SESSION_LIST_AUTHORIZATION)]:
        "Cannot access sessions: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_LIST_AUTHORIZATION)]:
        "Cannot access user roles: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_IAM_AUTHORIZATION)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_EDIT_AUTHORIZATION)]:
        "Cannot edit users: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.WORKSPACE_CANNOT_MODIFY_OWN)]: "You cannot edit your own workspace",
      [errorCodeKey(ErrorCode.permDenied.WORKSPACE_SPECIFIED_CANNOT_BE_MODIFIED)]:
        "You cannot edit this workspace",
      [errorCodeKey(ErrorCode.permDenied.MISSING_WORKSPACE_LIST_AUTHORIZATION)]:
        "Cannot acces this page: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.NO_TOKEN_PROVIDED)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_CANNOT_DECODE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.KEYCLOAK_BAD_PASSWORD)]:
        "Failed to connect: incorrect login or password",
      [errorCodeKey(ErrorCode.permDenied.SQREEN_SUSPICIOUS_USER)]:
        "Failed to connect: please contact your administrator",

      [errorCodeKey(ErrorCode.notAuthed.TOKEN_DATA_NOT_VALID)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.CANNOT_AUTHENTICATE_REQUEST)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_LOGIN_FAIL)]:
        "Failed to connect: maximum connection attempts reached, please try again in 10 min",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_RESET_PASSWORD_REQ_FAIL)]:
        "Failed to connect: maximum passowrd reset attempts reached, please try again in 10 min",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_RESET_PASSW_REQ_FAIL)]:
        "Failed to connect: maximum passowrd reset attempts reached, please try again in 10 min",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_USERNAME_PASSWORD)]:
        "Failed to connect: incorrect login or password",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_EXPIRED_TOKEN)]: "Please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_TOKEN)]: "Please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_CODE)]: "Please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_CANNOT_REFRESH_EXPIRED_TOKEN)]: "Please reconnect",

      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_CONNECT)]:
        "Failed to reach authentication service: please try again",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_EDIT_PASSWORD)]:
        "Failed to edit password, please try again later",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_CREATE_USER)]:
        "Failed to create user, please try again later",
      [errorCodeKey(ErrorCode.internal.TOKEN_CANNOT_GENERATE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.internal.TOKEN_CANNOT_DECODE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.internal.ROLES_NOT_FOUND)]: "Roles not found",
      [errorCodeKey(ErrorCode.internal.USER_DELETION_FAILED)]: "Failed to delete user",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_INTERNAL_EXCEPTION)]: "Please reconnect later",
      [errorCodeKey(ErrorCode.internal.CANNOT_GENERATE_EMAIL_TEMPLATE)]:
        "Cannot perform action, please try again later",
      [errorCodeKey(ErrorCode.internal.CANNOT_SEND_EMAIL)]: "Cannot perform action, please try again later",
      [errorCodeKey(ErrorCode.internal.ENUM_VALUE_NOT_FOUND)]: "Please try again later",
      [errorCodeKey(ErrorCode.internal.INVALID_REQUEST)]: "Please try again later",
      [errorCodeKey(ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION)]: "Unknown error. Please try again later",

      [fieldCodeKey(FieldCode.EMAIL)]: "email",
      [fieldCodeKey(FieldCode.USERNAME)]: "user name",
      [fieldCodeKey(FieldCode.PASSWORD)]: "password",
      [fieldCodeKey(FieldCode.FIRSTNAME)]: "first name",
      [fieldCodeKey(FieldCode.LASTNAME)]: "last name",
      [fieldCodeKey(FieldCode.POSITION)]: "position",
      [fieldCodeKey(FieldCode.USER_ID)]: "identifier",
      [fieldCodeKey(FieldCode.USER_AUTH_ID)]: "identifier",
      [fieldCodeKey(FieldCode.ROLE_ID)]: "role",
      [fieldCodeKey(FieldCode.TOKEN)]: "token",
      [fieldCodeKey(FieldCode.TIME_RANGE)]: "time range",
      [fieldCodeKey(FieldCode.SESSION_ID)]: "session identifier",
      [fieldCodeKey(FieldCode.SESSION_ID_LIST)]: "session list",
      [fieldCodeKey(FieldCode.SESSION_SYNC)]: "session sync status",
      [fieldCodeKey(FieldCode.WORKSPACE_ID)]: "workspace identifier",
      [fieldCodeKey(FieldCode.WORKSPACE_ID_LIST)]: "workspace list",
      [fieldCodeKey(FieldCode.WORKSPACE_NAME)]: "workspace name",
      [fieldCodeKey(FieldCode.WORKSPACE_DOMAIN)]: "workspace domain",
      [fieldCodeKey(FieldCode.APPLICATION_ID)]: "application identifier",
      [fieldCodeKey(FieldCode.APPLICATION_ID_LIST)]: "application list",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_NAME)]: "subscription name",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_KIND)]: "subscription type",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_MAX_SESSION_COUNT)]: "maximum session count",
      [fieldCodeKey(FieldCode.LABEL_LIST)]: "labels",
      [fieldCodeKey(FieldCode.MEDIAS)]: "media",
      [fieldCodeKey(FieldCode.PARENT_SESSION_ID)]: "parent session identifier",
      [fieldCodeKey(FieldCode.RESULT_MISSING)]: "result",
      [fieldCodeKey(FieldCode.FACE_MATCHING_RESULT)]: "face comparison result",
      [fieldCodeKey(FieldCode.LIVENESS_RESULT)]: "liveness result",
      [fieldCodeKey(FieldCode.IS_REVIEWED)]: "result",
      [fieldCodeKey(FieldCode.RESULT_TYPE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_IS_GENUINE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_PROBABILITY)]: "result",
      [fieldCodeKey(FieldCode.RESULT_SCORE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_TRIES)]: "result",
      [fieldCodeKey(FieldCode.RESULT_HEART_RATE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_SIGNAL_LIST)]: "result",
      [fieldCodeKey(FieldCode.RESULT_SPECTRUM_LIST)]: "result",
      [fieldCodeKey(FieldCode.RESULT_REVIEW)]: "result",
      [fieldCodeKey(FieldCode.RESULT_MODEL_ID)]: "result",
      [fieldCodeKey(FieldCode.RESULT_THRESHOLD)]: "threshold result",
      [fieldCodeKey(FieldCode.APPLICATION_NAME)]: "application name",
      [fieldCodeKey(FieldCode.REFERRER_LIST)]: "referrers",
    },
  },
} as const;
