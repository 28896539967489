// TODO: use model of CreateTeamMember.tsx
import { Button, CircularProgress, DialogProps } from "@material-ui/core";
import React from "react";

import { ModalV2 as Modal } from "../../components/Modal";

import { Notif } from "../../types/notif";
import { AuthContext } from "../../contexts/auth_context";
import { useScopedTranslation } from "../../i18n";
import { useStores } from "../../hooks/use-stores";
import { ApiFetchError, doFetch } from "../../stores/utils";
import CreateWorkspaceForm from "./CreateWorkspaceForm";
import { observer, useLocalObservable } from "mobx-react-lite";
import { toJS } from "mobx";
import { WorkspaceCreateTeamMember } from "../../types/userCRUD";
import { WorkspaceCreateSubscription, WorkspaceCreation, WorkspaceCreationState } from "../../stores/forms";
import { Authorizations } from "@unissey/utils";

interface ICreateWorkspaceMember {
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;
  hasGdrp: boolean;
}

interface ICreateWorkspaceExtendedRequest {
  name: string;
  domain: string;
  parent: string;
  adminRoleIds: string | undefined;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  adminHasGdpr: boolean;
  members: ICreateWorkspaceMember[];
  subscription?: WorkspaceCreateSubscription;
}

function createWorkspaceExtended(payload: ICreateWorkspaceExtendedRequest) {
  return doFetch<string>("POST", "/workspaces/extended", payload);
}

type Props = DialogProps & {
  onClose: () => void;
  handleClose: () => void;
  onNotif: Notif;
};

function validateTeamMemberForm(form: WorkspaceCreateTeamMember): boolean {
  const nonEmptyFirstName = form.firstName.length > 0;
  const nonEmptyLastName = form.lastName.length > 0;
  const nonEmptyRoleId = form.roleIds.length > 0;
  const validEmail = /\S+@\S+\.\S+/.test(form.email);
  return nonEmptyFirstName && nonEmptyLastName && nonEmptyRoleId && validEmail;
}

function validateWsStateForm(form: WorkspaceCreationState): boolean {
  return form.workspaceName.length > 0;
}

function validateWorkspaceForm(form: WorkspaceCreation): boolean {
  const f = toJS(form);

  const usersValidation = Array.from(f.users).every(([_, v]) => validateTeamMemberForm(v));
  const adminValidation = validateTeamMemberForm(f.administrator);
  const wsValidation = validateWsStateForm(f.wsState);
  return usersValidation && adminValidation && wsValidation;
}

function CreateWorkspaceModal({ open, onClose, onNotif, handleClose }: Props) {
  const { workspaceStore } = useStores();
  const { t } = useScopedTranslation("workspace_page.create_dialog");

  const auth = React.useContext(AuthContext);
  const canCreateSubscription = !!auth.user?.authorizations.includes(Authorizations.SUBSCRIPTION_EDIT);
  const form = useLocalObservable(() => new WorkspaceCreation({ withSubscription: canCreateSubscription }));

  const handleCreateWS = async () => {
    form.setIsLoading(true);
    if (form && form.wsState && auth.user) {
      const { wsState, administrator, users, subscription } = toJS(form);
      const createWSReq: ICreateWorkspaceExtendedRequest = {
        name: wsState.workspaceName,
        parent: auth.user.workspaceId,
        domain: `http://${administrator.email.split("@", 2)[1]}`,
        adminFirstName: administrator.firstName,
        adminLastName: administrator.lastName,
        adminEmail: administrator.email,
        adminRoleIds: administrator.roleIds,
        adminHasGdpr: administrator.hasGdpr,
        members: [],
        subscription,
      };

      const usersToAdd = Array.from(users.values());
      for (const user of usersToAdd) {
        createWSReq.members.push({
          email: user.email,
          roleId: user.roleIds,
          firstName: user.firstName,
          lastName: user.lastName,
          hasGdrp: user.hasGdpr,
        });
      }

      try {
        const id = await createWorkspaceExtended(createWSReq);
        onNotif({
          textKey: "success.workspace_created",
          textParams: { workspaceName: form.wsState.workspaceName },
        });
        form.reset();
        await workspaceStore.fetchWorkspaceById(id);
      } catch (err) {
        const errMsg = err instanceof ApiFetchError ? err.fetchError : err.message || err;
        const errorFields = err instanceof ApiFetchError ? err.fieldsError : undefined;
        onNotif({
          textKey: errMsg,
          errorFields,
        });
      }
    }
    form.setIsLoading(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      maxWidth="md"
      body={<CreateWorkspaceForm form={form} />}
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleCreateWS}
          fullWidth
          startIcon={form.isLoading && <CircularProgress size={14} />}
          disabled={form.isLoading || !validateWorkspaceForm(form)}
        >
          {t("button_create_and_invite")}
        </Button>
      }
    />
  );
}

export default observer(CreateWorkspaceModal);
