import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { CheckBoxOutlineBlank, LockOutlined } from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import HelpIcon from "@material-ui/icons/Help";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { DeleteIcon } from "../../components/DeleteIcon";
import CustomDateTimePicker from "../../components/forms/formComponents/CustomDateTimePicker";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { useScopedTranslation } from "../../i18n";
import { getRoles } from "../../services/api_services";
import { ALLOWED_SUBSCRIPTION_KIND, WorkspaceCreation } from "../../stores/forms";
import { SubscriptionKind } from "../../types/subscriptionsCRUD";
import { Role, WorkspaceCreateTeamMember } from "../../types/userCRUD";
import { translatedRoleDescription, translatedRoleName } from "../../utils/i18n";

type UserFormProps = {
  id: string;
  roles: Role[];
  userForm: WorkspaceCreateTeamMember;
  onUserFieldChanged: <K extends keyof WorkspaceCreateTeamMember>(
    id: string,
    field: K,
    value: WorkspaceCreateTeamMember[K]
  ) => void;
};

function WorkspaceUserForm({ roles, id, userForm, onUserFieldChanged }: UserFormProps) {
  function roleSelectorRenderValue(): string {
    if (!roles) return "";
    return roles.find((r) => userForm.roleIds === r.id)?.name ?? "";
  }

  const classes = useStyles();
  const { t, tCommon } = useScopedTranslation("workspace_page.create_dialog");
  return (
    <Box py={2} id={id}>
      <Grid container spacing={2}>
        <CustomTextField
          width={6}
          name={"firstName"}
          label={t("administrator_fields.first_name")}
          value={userForm.firstName}
          onChange={(e) => onUserFieldChanged(id, "firstName", e.target.value)}
          onBlur={(e) => onUserFieldChanged(id, "firstName", e.target.value.trim())}
        />

        <CustomTextField
          width={6}
          name={"lastName"}
          label={t("administrator_fields.last_name")}
          value={userForm.lastName}
          onChange={(e) => onUserFieldChanged(id, "lastName", e.target.value)}
          onBlur={(e) => onUserFieldChanged(id, "lastName", e.target.value.trim())}
        />

        <CustomTextField
          width={6}
          name={"email"}
          label={t("administrator_fields.email")}
          value={userForm.email}
          onChange={(e) => onUserFieldChanged(id, "email", e.target.value)}
          onBlur={(e) => onUserFieldChanged(id, "email", e.target.value.trim())}
        />

        <Grid item xs={6}>
          <FormControl required fullWidth variant="outlined">
            <InputLabel id="select-role-label">{t("administrator_fields.role")}</InputLabel>
            <Select
              labelId="select-role-label"
              label={t("administrator_fields.role")}
              value={userForm.roleIds ?? ""}
              renderValue={roleSelectorRenderValue}
              MenuProps={{
                variant: "menu",
                getContentAnchorEl: null,
              }}
              onChange={(e) => onUserFieldChanged(id, "roleIds", e.target.value as string)}
              IconComponent={ArrowDropDownIcon}
            >
              <MenuItem key="placeholder" style={{ display: "none" }} />
              {roles ? (
                roles.map((role, idx) => (
                  <MenuItem dense value={role.id} key={idx}>
                    <ListItemText primary={translatedRoleName(role, tCommon)} />
                    <ListItemIcon>
                      <Tooltip
                        title={<Typography>{translatedRoleDescription(role, tCommon)}</Typography>}
                        interactive
                      >
                        <HelpIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </MenuItem>
                ))
              ) : (
                <Typography variant={"h6"} align={"center"}>
                  {tCommon("loading")}
                </Typography>
              )}
            </Select>
          </FormControl>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              className={classes.gdrpCheckbox}
              checked={userForm.hasGdpr}
              color="primary"
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              onChange={(e) => onUserFieldChanged(id, "hasGdpr", e.target.checked)}
              name="personal-data-reader"
            />
          }
          label={
            <Typography>
              <b>{t("administrator_fields.personalDataReader")}</b>
              <br /> {t("administrator_fields.personalDataReaderSubMsg")}
            </Typography>
          }
          labelPlacement="start"
        />
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles({
  gdrpCheckbox: {
    marginTop: "25px",
  },
  newMemberBtn: {
    background: "#F6F6F6",
    border: "1px dashed #3D59E8",
    color: "#3D59E8",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px",
    height: "70px",
    padding: "10px",
    textTransform: "none",
  },
  removeMemberBtn: {
    border: "1px dashed #3D59E8",
    borderRadius: "8px",
    margin: "15px",
    padding: "10px",
    position: "relative",
  },
  deleteBtn: {
    position: "absolute",
    top: "-15px",
    right: "-15px",
    padding: "5px",
    background: "#E47589",
  },
  deleteIcon: {
    background: "#E47589",
    color: "#FFFFFF",
    borderRaduis: "50%",
    width: "29px",
    height: "29px",
  },
  // HACK: we need to have the SVG content in white color
  "@global": {
    "#rmTeamMember > path": {
      fill: "#FFFFFF",
    },
  },
});

type Props = {
  form: WorkspaceCreation;
};

function CreateWorkspaceForm({ form }: Props) {
  const classes = useStyles();
  const generateId = (): string => Date.now().toString();
  const { t, tCommon } = useScopedTranslation("workspace_page.create_dialog");

  const [roles, setRoles] = useState<Role[]>();
  const [roleIdsByName, setRoleIdsByName] = useState<Record<string, string>>({});

  function roleSelectorRenderValue(): string {
    const role = roles?.find((r) => form.administrator.roleIds === r.id);
    if (role !== undefined) return translatedRoleName(role, tCommon);

    return "";
  }

  useEffect(() => {
    autorun(async () => {
      let rIdsByName: typeof roleIdsByName = {};
      const r = await getRoles(undefined, true);
      setRoles(r?.sort((a, b) => a.name.localeCompare(b.name)));
      r?.forEach((role) => {
        if (role.name === "Administrator") rIdsByName[role.name] = role.id;
      });
      if (rIdsByName["Administrator"]) {
        form.updateAdmin("roleIds", rIdsByName["Administrator"]);
      }
      setRoleIdsByName(rIdsByName);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGroup>
      <Typography component="p" variant="h6">
        {t("subtitle_general_information")}
      </Typography>

      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={12}
            label={t("field_workspace_name")}
            name={"workspaceName"}
            value={form.wsState.workspaceName}
            onChange={(e) => form.updateWorkspace("workspaceName", e.target.value)}
            onBlur={(e) => form.updateWorkspace("workspaceName", e.target.value.trim())}
          />
        </Grid>
      </Box>

      {form.subscription ? (
        <>
          <Typography component="p" variant="h6">
            {t("subtitle_subscription")}
          </Typography>

          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="select-subscription-type-label">{t("subscription_fields.kind")}</InputLabel>
                  <Select
                    labelId="select-subscription-type-label"
                    label={t("subscription_fields.kind")}
                    value={form.subscription.kind}
                    MenuProps={{
                      variant: "menu",
                      getContentAnchorEl: null,
                    }}
                    onChange={(e) => form.updateSubscription("kind", e.target.value as SubscriptionKind)}
                  >
                    {ALLOWED_SUBSCRIPTION_KIND.map((kind) => (
                      <MenuItem dense value={kind} key={kind}>
                        {tCommon(`sub_kind.${kind}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label={t("subscription_fields.name")}
                  value={form.subscription.name}
                  InputProps={{
                    endAdornment: <LockOutlined />,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  name="subscriptionMaxSessionCount"
                  type="number"
                  required={false}
                  fullWidth
                  onChange={(e) => {
                    const limit = parseInt(e.target.value, 10);
                    form.updateSubscription("maxSessionCount", isNaN(limit) ? undefined : limit);
                  }}
                  value={form.subscription.maxSessionCount}
                  label={t("subscription_fields.max_sessions")}
                />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <CustomDateTimePicker
                    value={form.subscription.startDate}
                    onChange={(date) => {
                      if (date) {
                        form.updateSubscription("startDate", date);
                      }
                    }}
                    label={t("subscription_fields.start_date")}
                    minDate={new Date()}
                    width="auto"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <CustomDateTimePicker
                    value={form.subscription.endDate ?? null}
                    onChange={(date) => {
                      form.updateSubscription("endDate", date ?? undefined);
                    }}
                    label={t("subscription_fields.end_date")}
                    minDate={new Date()}
                    width="auto"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      <Typography component="p" variant="h6">
        {t("subtitle_administrator")}
      </Typography>

      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={6}
            label={t("administrator_fields.first_name")}
            name={"firstName"}
            value={form.administrator.firstName}
            onChange={(e) => form.updateAdmin("firstName", e.target.value)}
            onBlur={(e) => form.updateAdmin("firstName", e.target.value.trim())}
          />

          <CustomTextField
            width={6}
            label={t("administrator_fields.last_name")}
            name={"lastName"}
            value={form.administrator.lastName}
            onChange={(e) => form.updateAdmin("lastName", e.target.value)}
            onBlur={(e) => form.updateAdmin("lastName", e.target.value.trim())}
          />

          <CustomTextField
            width={6}
            label={t("administrator_fields.email")}
            name={"email"}
            value={form.administrator.email}
            onChange={(e) => form.updateAdmin("email", e.target.value)}
            onBlur={(e) => form.updateAdmin("email", e.target.value.trim())}
          />

          <Grid item xs={6}>
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="select-role-label">{t("administrator_fields.role")}</InputLabel>
              <Select
                labelId="select-role-label"
                label={t("administrator_fields.role")}
                value={form.administrator.roleIds ?? ""}
                renderValue={roleSelectorRenderValue}
                MenuProps={{
                  variant: "menu",
                  getContentAnchorEl: null,
                }}
                onChange={(e) => form.updateAdmin("roleIds", e.target.value as string)}
                IconComponent={LockOutlined}
                disabled
              >
                <MenuItem key="placeholder" style={{ display: "none" }} />
                {roles ? (
                  roles.map((role, idx) => (
                    <MenuItem dense value={role.id} key={idx}>
                      <ListItemText primary={translatedRoleName(role, tCommon)} />
                      <ListItemIcon>
                        <Tooltip
                          title={<Typography>{translatedRoleDescription(role, tCommon)}</Typography>}
                          interactive
                        >
                          <HelpIcon />
                        </Tooltip>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                ) : (
                  <Typography variant={"h6"} align={"center"}>
                    {tCommon("loading")}
                  </Typography>
                )}
              </Select>
            </FormControl>
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                className={classes.gdrpCheckbox}
                onChange={(e) => form.updateAdmin("hasGdpr", e.target.checked)}
                color="primary"
                checked={form.administrator.hasGdpr}
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="personal-data-reader"
              />
            }
            label={
              <Typography>
                <b>{t("administrator_fields.personalDataReader")}</b>
                <br /> {t("administrator_fields.personalDataReaderSubMsg")}
              </Typography>
            }
            labelPlacement="start"
          />
        </Grid>
      </Box>

      <Box py={1} />

      {Object.keys(form.users).length > 0 ? (
        <Typography component="p" variant="h6">
          {t("team_members_section_title")}
        </Typography>
      ) : null}

      {Array.from(form.users).map(([key, user], idx) => (
        <Box className={classes.removeMemberBtn} key={idx}>
          <IconButton onClick={() => form.removeTeamMember(key)} className={classes.deleteBtn}>
            <DeleteIcon id="rmTeamMember" />
          </IconButton>
          <WorkspaceUserForm
            id={key}
            roles={roles as Role[]}
            userForm={user}
            onUserFieldChanged={form.updateTeamMember}
          />
        </Box>
      ))}

      <Box py={2}>
        <Grid container spacing={2}>
          <Button onClick={() => form.addTeamMember(generateId())} className={classes.newMemberBtn} fullWidth>
            + Add a team member
          </Button>
        </Grid>
      </Box>
    </FormGroup>
  );
}

export default observer(CreateWorkspaceForm);
