import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Breadcrumbs,
} from "@material-ui/core";
import SessionPage from "../SessionPage";
import React, { MouseEvent } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { SimplifiedSession } from "../../../types/session";
import { Notif } from "../../../types/notif";
import { useScopedTranslation } from "../../../i18n";

type Props = {
  onNotif: Notif;
  selectedSessionId: string | null;
  sessions: SimplifiedSession[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  _onSessionSelected: (sessionId: string | null) => void;
};

export default function SessionModal({
  onNotif,
  selectedSessionId,
  sessions,
  page,
  setPage,
  _onSessionSelected,
}: Props) {
  const { t } = useScopedTranslation("sessions_page.details_modal");

  const _getSessionIdx = (sessionId: string) => {
    return sessions.findIndex((session) => session.id === sessionId);
  };

  function _selectNextSession(e?: KeyboardEvent | MouseEvent) {
    if (e) e.preventDefault();
    if (!selectedSessionId) return;
    const sessionIdx = _getSessionIdx(selectedSessionId);

    if (sessionIdx === sessions.length - 1 || sessionIdx === -1) return;
    // if we reached the end of the loaded list, load the next page
    if (sessions.length - sessionIdx <= 2) setPage(page + 1);
    _onSessionSelected(sessions[sessionIdx + 1].id);
  }

  function _selectPreviousSession(e: KeyboardEvent | MouseEvent) {
    e.preventDefault();
    if (!selectedSessionId) return;
    const sessionIdx = _getSessionIdx(selectedSessionId);

    if (sessionIdx <= 0) return;
    _onSessionSelected(sessions[sessionIdx - 1].id);
  }

  useHotkeys("right", _selectNextSession, {}, [sessions, selectedSessionId]);
  useHotkeys("left", _selectPreviousSession, {}, [sessions, selectedSessionId]);

  return (
    <Dialog
      fullWidth
      classes={{ paperFullWidth: "calc(100% - 64px)" }}
      open={selectedSessionId !== null}
      onClose={() => _onSessionSelected(null)}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography variant="h6" color="textSecondary">
              {t("breadcrumbs_sessions")}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {selectedSessionId}
            </Typography>
          </Breadcrumbs>
          <Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={_selectPreviousSession}
              disabled={!selectedSessionId || _getSessionIdx(selectedSessionId) <= 0}
            >
              {t("button_previous")}
            </Button>
            <Box component="span" mr={1} />
            <Button
              color="primary"
              variant="outlined"
              onClick={_selectNextSession}
              disabled={!selectedSessionId || _getSessionIdx(selectedSessionId) === sessions.length - 1}
            >
              {t("button_next")}
            </Button>
          </Box>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <SessionPage onNotif={onNotif} />
      </DialogContent>
    </Dialog>
  );
}
