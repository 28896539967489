import { Box, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ResetPasswordForm from "../../components/forms/ResetPasswordForm";

import { Routes } from "../../constants/routes";
import { useScopedTranslation } from "../../i18n";
import AuthenticationServices from "../../services/authentication_service";
import { AuthPageContainer } from "./AuthPageBase";

export default function ResetPasswordPage() {
  const history = useHistory();
  const { t } = useScopedTranslation("reset_password_page");

  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const loc = useLocation();

  const getTokenFromQuery = () => {
    const q = new URLSearchParams(loc.search);
    return q.get("token");
  };

  useEffect(() => {
    setToken(getTokenFromQuery());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function resetPassword(password: string) {
    if (await AuthenticationServices.resetPasswordStep2(token!, password))
      history.push({
        pathname: `${Routes.SIGNIN}`,
        search: loc.search,
      });
    else setError("error");
  }

  if (!token) {
    return <Box>{t("missing_token")}</Box>;
  }

  return (
    <AuthPageContainer title={t("title")}>
      <ResetPasswordForm onValidate={(passw) => resetPassword(passw)} />
      {error && (
        <Paper elevation={1} style={{ color: "red", padding: "2%", textAlign: "center" }}>
          {t("generic_error")}
        </Paper>
      )}
    </AuthPageContainer>
  );
}
