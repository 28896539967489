// types
import { SimpleAuthEntity } from "@unissey/utils";
// constants
import {
  FACEMATCHING_EXPLANATION_SEEN,
  USER_LS_KEY,
  VIDEORECORD_EXPLANATION_SEEN,
} from "../constants/storage";
import { SessionUser } from "../types/auth";

export default class StorageService {
  /** Return user auth from local storage value */
  static getStoredUserAuth = (): SessionUser | null => {
    const auth = window.localStorage.getItem(USER_LS_KEY);

    if (auth) {
      const deserializeAuth: SessionUser = JSON.parse(auth);

      if (
        !("authorizations" in deserializeAuth) ||
        !("authorizedWorkspaceIds" in deserializeAuth) ||
        !("workspaceIsIntegrator" in deserializeAuth)
      ) {
        StorageService.clearUserAuth();
      } else {
        deserializeAuth.entity = new SimpleAuthEntity(
          deserializeAuth.workspaceId,
          deserializeAuth.userId,
          [deserializeAuth.workspaceId],
          deserializeAuth.authorizations
        );

        return deserializeAuth;
      }
    }
    return null;
  };
  static storeUserAuth = (user: SessionUser) => {
    window.localStorage.setItem(USER_LS_KEY, JSON.stringify(user));
  };
  static clearUserAuth = () => {
    window.localStorage.removeItem(USER_LS_KEY);
  };
  static storeTermsOfUse = (termsOfUse: boolean, privacyPolicy: boolean) => {
    // get auth
    const auth: SessionUser = JSON.parse(window.localStorage.getItem(USER_LS_KEY) || "{}");

    // add ToU keys
    auth.terms_of_use = termsOfUse;
    auth.privacy_policy = privacyPolicy;

    // store auth with ToU
    window.localStorage.setItem(USER_LS_KEY, JSON.stringify(auth));
  };

  // Store explanation seen
  static storeFaceMatchingExplanationSeen = () =>
    window.sessionStorage.setItem(FACEMATCHING_EXPLANATION_SEEN, "true");
  static storeVideoRecordExplanationSeen = () =>
    window.sessionStorage.setItem(VIDEORECORD_EXPLANATION_SEEN, "true");

  // Get explanation seen
  static getFaceMatchingExplanationSeen = () => window.sessionStorage.getItem(FACEMATCHING_EXPLANATION_SEEN);
  static getVideoRecordExplanationSeen = () => window.sessionStorage.getItem(VIDEORECORD_EXPLANATION_SEEN);
}
