import { Box, Slider } from "@material-ui/core";
import { useState } from "react";
import { HeaderFilterMenu } from ".";

type NumberRange = {
  after?: number;
  before?: number;
};

type Props = {
  initialRange: NumberRange;
  min: number;
  max: number;
  marks?: { value: number; label: string }[];
  onRangeChanged: (range: NumberRange) => void;
};

export function SliderFilter({ initialRange, max, marks, min, onRangeChanged }: Props) {
  const [value, setValue] = useState<[number, number]>([
    initialRange.after ?? min,
    initialRange.before ?? max,
  ]);

  const handleRangeChange = (event: object, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setValue(newValue as [number, number]);
    }
  };

  return (
    <HeaderFilterMenu
      onConfirm={() => {
        const [after, before] = value;
        onRangeChanged({ after, before });
      }}
      onCancel={() => {}}
      onClearAll={() => setValue([min, max])}
      activeIndicator={initialRange.after || initialRange.before ? "*" : undefined}
    >
      <Box>
        <Slider
          style={{ width: "300px" }}
          value={value}
          min={min}
          max={max}
          marks={marks}
          onChange={handleRangeChange}
          valueLabelDisplay="auto"
        />
      </Box>
    </HeaderFilterMenu>
  );
}
