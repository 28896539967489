import { Notif } from "../../types/notif";
import TitledSection from "../../components/TitledSection";
import React, { useEffect, useState, useMemo } from "react";
import { Button, capitalize } from "@material-ui/core";
import { Subscription } from "../../types/subscriptionsCRUD";
import { getSubscriptions } from "../../services/api_services";
import SubscriptionModal from "./SubscriptionModal";
import { AuthContext } from "../../contexts/auth_context";
import { hasAuthorizations } from "../../utils/auth_util";
import { Authorizations } from "@unissey/utils";
import EditSubscriptionModal from "./EditSubscriptionModal";
import CreateSubscriptionModal from "./CreateSubscriptionModal";
import { useScopedTranslation } from "../../i18n";
import DataTable, { DataTableColumn } from "../../components/DataTable";

type SubscriptionDisplay = {
  name: string;
  workspace: string;
  kind: string;
  startDate: Date;
  endDate: Date | string;
  status: string;
  maxSessions: number | "N/A";
  sessionsCount: number;
  editSubscription?: React.ReactNode;
};

export default function SubscriptionsPage({ onNotif }: { onNotif: Notif }) {
  const auth = React.useContext(AuthContext);
  const { t, tCommon } = useScopedTranslation("subscription_page");

  const [subscriptions, setSubscriptions] = useState<Subscription[]>();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number>();
  const [subToEdit, setSubToEdit] = useState<Subscription>();
  const [creationModalIsOpen, setCreationModalIsOpen] = useState<boolean>(false);
  const [editionModalIsOpen, setEditionModalIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);

  const canEditSubscription = hasAuthorizations(auth, [Authorizations.SUBSCRIPTION_EDIT]);

  const maxSessions = useMemo(() => {
    if (subscriptions) return Math.max(...subscriptions.map((sub) => sub.maxSessionCount ?? 0));
    return 0;
  }, [subscriptions]);

  const maxSessionsCount = useMemo(() => {
    if (subscriptions) return Math.max(...subscriptions.map((sub) => sub.sessionCount));
    return 0;
  }, [subscriptions]);

  const sessionCountMarks = (max: number) => {
    return [0, 0.25 * max, 0.5 * max, 0.75 * max, max];
  };

  useEffect(() => {
    const compareSubsFn = (a: Subscription, b: Subscription) => {
      let tmp = a.workspaceName.localeCompare(b.workspaceName);
      if (tmp !== 0) return tmp;
      tmp = a.kind.localeCompare(b.kind);
      if (tmp !== 0) return tmp;
      return a.name.localeCompare(b.name);
    };

    getSubscriptions(onNotif).then((subs) => {
      const sortedSubs = subs?.sort(compareSubsFn);
      setSubscriptions(sortedSubs);
      setIsLoading(false);
    });
  }, [onNotif, editionModalIsOpen, creationModalIsOpen]);

  const columns: DataTableColumn<SubscriptionDisplay>[] = [
    {
      dataKey: "name",
      title: t(`table.header_name`),
      sorter: (a, b) => (a.name < b.name ? -1 : 1),
      filter: {
        label: t(`table.header_name`),
        widget: "select",
        lookup: "contains",
        values: subscriptions?.map((sub) => capitalize(sub.name)),
      },
    },
    {
      dataKey: "workspace",
      title: t(`table.header_workspace`),
      sorter: (a, b) => (a.workspace < b.workspace ? -1 : 1),
      filter: {
        label: t(`table.header_workspace`),
        widget: "select",
        lookup: "contains",
        values: Array.from(
          new Set(subscriptions?.map((sub) => sub.workspaceName)) // to remove duplicates
        ),
      },
    },
    {
      dataKey: "kind",
      title: t(`table.header_type`),
      sorter: (a, b) => (a.kind < b.kind ? -1 : 1),
      filter: {
        label: t(`table.header_type`),
        lookup: "contains",
        widget: "select",
        values: Array.from(new Set(subscriptions?.map((sub) => tCommon(`sub_kind.${sub.kind}`)))),
      },
    },
    {
      dataKey: "status",
      title: t(`table.header_status`),
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
      filter: {
        label: t(`table.header_status`),
        widget: "select",
        lookup: "contains",
        values: ["Active", "Inactive"],
      },
    },
    {
      dataKey: "startDate",
      title: t(`table.header_start_date`),
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1),
      filter: {
        label: t(`table.header_start_date`),
        widget: "date",
        lookup: "interval",
      },
    },
    {
      dataKey: "endDate",
      title: t(`table.header_end_date`),
      sorter: (a, b) => {
        if (typeof a.endDate === "string") return 1;
        if (typeof b.endDate === "string") return -1;

        return a.endDate < b.endDate ? -1 : 1;
      },
      filter: {
        label: t(`table.header_end_date`),
        widget: "date",
        lookup: "interval",
      },
    },
    {
      dataKey: "maxSessions",
      title: t(`table.header_max_sessions`),
      filter: {
        label: t(`table.header_max_sessions`),
        widget: "slider",
        lookup: "interval",
        values: [0, maxSessions],
        marks: sessionCountMarks(maxSessions),
      },
      sorter: (a, b) =>
        (a.maxSessions === "N/A" ? -1 : a.maxSessions) < (b.maxSessions === "N/A" ? -1 : b.maxSessions)
          ? -1
          : 1,
    },
    {
      dataKey: "sessionsCount",
      title: t(`table.header_session_count`),
      filter: {
        label: t(`table.header_session_count`),
        widget: "slider",
        lookup: "interval",
        values: [0, maxSessionsCount],
        marks: sessionCountMarks(maxSessionsCount),
      },
      sorter: (a, b) => (a.sessionsCount < b.sessionsCount ? -1 : 1),
    },
  ];

  if (canEditSubscription) columns.push({ dataKey: "editSubscription", title: "" });

  const now = new Date();

  const displaySubscriptions = (): SubscriptionDisplay[] => {
    return (
      subscriptions?.map((sub) => {
        const isActive = isSubscriptionActive(sub, now);
        return {
          name: capitalize(sub.name),
          workspace: sub.workspaceName,
          kind: tCommon(`sub_kind.${sub.kind}`),
          status: isActive ? "Active" : "Inactive",
          startDate: sub.startDate,
          endDate: sub.endDate ? sub.endDate : "N/A",
          maxSessions: sub.maxSessionCount ?? "N/A",
          sessionsCount: sub.sessionCount,
          editSubscription: canEditSubscription ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setEditionModalIsOpen(true);
                setSubToEdit(sub);
              }}
              variant="outlined"
            >
              {tCommon("button_edit")}
            </Button>
          ) : (
            <></>
          ),
        };
      }) || []
    );
  };

  function onSubscriptionClick(index: number) {
    if (!subscriptions) return;
    setSelectedSubscriptionId(subscriptions[index].id);
  }

  function CreateSubscriptionButton() {
    if (!canEditSubscription) return null;
    return (
      <Button variant="outlined" onClick={() => setCreationModalIsOpen(true)}>
        {t("button_create")}
      </Button>
    );
  }

  return (
    <>
      <TitledSection
        child={
          <DataTable
            columns={columns}
            dataSource={displaySubscriptions()}
            onRowClick={onSubscriptionClick}
            lastColAlignRight={canEditSubscription}
            isLoading={isLoading}
          />
        }
        action={<CreateSubscriptionButton />}
      />
      <SubscriptionModal
        onNotif={onNotif}
        selectedSubscriptionId={selectedSubscriptionId}
        onClose={() => setSelectedSubscriptionId(undefined)}
      />
      {canEditSubscription ? (
        <CreateSubscriptionModal
          open={creationModalIsOpen}
          onClose={() => setCreationModalIsOpen(false)}
          onNotif={onNotif}
        />
      ) : null}
      {canEditSubscription && subToEdit ? (
        <EditSubscriptionModal
          subscription={subToEdit}
          open={editionModalIsOpen}
          onClose={() => {
            setSubToEdit(undefined);
            setEditionModalIsOpen(false);
          }}
          onNotif={onNotif}
        />
      ) : null}
    </>
  );
}

export function isSubscriptionActive(sub: Subscription, at: Date): boolean {
  const hasStarted = at >= sub.startDate;
  const hasEnded = sub.endDate ? at > sub.endDate : false;

  return hasStarted && !hasEnded;
}
