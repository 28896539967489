import { Authorizations } from "@unissey/utils";

import { Box, Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import { useEffect, useState, useContext } from "react";

import Modal from "../../components/Modal";
import UserForm from "../../components/forms/UserForm";
import CustomSelect from "../../components/forms/formComponents/CustomSelect";

import { AuthContext } from "../../contexts/auth_context";
import { Notif } from "../../types/notif";
import { TeamMemberForm } from "../../types/userCRUD";
import { hasAuthorizations } from "../../utils/auth_util";
import { createUser, getWorkspaces } from "../../services/api_services";
import { useScopedTranslation } from "../../i18n";

type Props = {
  onClose: () => void;
  onNotif: Notif;
};

export type SelectWorkspace = {
  name: string;
  id: string;
};

export default function CreateTeamMemberModal({ open, onClose, onNotif }: Props & DialogProps) {
  const auth = useContext(AuthContext);
  const { t } = useScopedTranslation("team_page.create_user_dialog");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<SelectWorkspace[]>([]);
  const [userToCreate, setUserToCreate] = useState<TeamMemberForm>({
    firstName: "",
    lastName: "",
    email: "",
    roleIds: "",
    workspaceid: auth.user!.workspaceId,
    ownerid: auth.user!.workspaceId,
  });

  const handleUserCreation = async () => {
    if (userToCreate && auth.user) {
      const user = {
        ...userToCreate,
        cguConsent: true,
      };
      setIsLoading(true);
      if (await createUser(onNotif, user)) onNotif({ textKey: "success.user_created" });
      onClose();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const onlyOwnWorkspace = [{ name: auth.user!.workspaceName, id: auth.user!.workspaceId }];
      if (hasAuthorizations(auth, [Authorizations.WORKSPACE_LIST]))
        setWorkspaces((await getWorkspaces(onNotif)) ?? onlyOwnWorkspace);
      else setWorkspaces(onlyOwnWorkspace);
      setIsLoading(false);
    })();
    // TODO:  missing dependency: 'auth'. Either include it or remove the dependency array.
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNotif, auth.user]);
  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      body={
        <FormGroup>
          <Grid container>
            <UserForm
              setFormData={setUserToCreate}
              userFormData={userToCreate}
              targetWorkspaceId={userToCreate.workspaceid}
            />
            <Box py={2} width="100%">
              <CustomSelect
                width={12}
                labelId="create-team-member-select-workspace-label"
                labelValue={t("field_select_workspace")}
                value={userToCreate.workspaceid}
                menuItems={workspaces.map((workspace) => [workspace.id, workspace.name])}
                onChange={(e) => setUserToCreate({ ...userToCreate, workspaceid: e.target.value as string })}
              />
            </Box>
          </Grid>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleUserCreation}
          startIcon={isLoading && <CircularProgress size={14} />}
        >
          {t("button_invite")}
        </Button>
      }
    />
  );
}
