// TODO: CRUD (use CRUD model of TeamPage.tsx)
import { Authorizations } from "@unissey/utils";
import { Button, Collapse, List, Typography, Tooltip, ListItem, Grid, Box, Link } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { autorun, runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks/use-stores";
import { AuthContext } from "../../contexts/auth_context";

import CreateWorkspaceModal from "./CreateWorkspaceModal";
import CustomTable from "../../components/CustomTable";
import TitledSection from "../../components/TitledSection";
import UpdateWorkspace from "./UpdateWorkspace";
import { InviteIcon } from "../../components/InviteIcon";
import { EditIcon } from "../../components/EditIcon";
import { DeleteIcon } from "../../components/DeleteIcon";

import { deleteUser, deleteWorkspace, getWorkspaces } from "../../services/api_services";
import { Notif } from "../../types/notif";
import { ReadUser, UpdateUserExtended } from "../../types/userCRUD";
import { ReadWorkspace } from "../../types/workspaceCRUD";
import { getSessionUser, hasAuthorizations } from "../../utils/auth_util";
import { useScopedTranslation } from "../../i18n";
import { Workspace } from "../../stores/workspace";
import { ApiFetchError } from "../../stores/utils";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CreateTeamMemberModal from "./CreateTeamMember";
import CreateTemporaryAccessModal from "./CreateTemporaryAccessModal";
import UpdateTeamMemberModal from "./UpdateTeamMemberModal";
import { makeStyles } from "@material-ui/core";
// import DeleteTeamMemberConfirmDialog from "./DeleteTeamMemberConfirmDialog";
import { ConfirmDialogV2 as ConfirmDialog } from "../../components/ConfirmDialog";

import TemporaryExtractModal from "./TemporaryExtractModal";
import { translatedRoleDescription, translatedRoleName } from "../../utils/i18n";

type WorkspaceActionsProps = {
  isMyWorkspace: boolean;
  workspace: Workspace;
};

const useStyles = makeStyles({
  actionBtn: {
    textTransform: "none",
  },
  createBtn: {
    background: "#05DB91",
    color: "#FFFFFF",
    fontWeight: "bold",
    textTransform: "none",
  },
});

function WorkspacePage({ onNotif }: { onNotif: Notif }) {
  const classes = useStyles();
  const { t, tCommon } = useScopedTranslation("workspace_page");

  const { workspaceStore } = useStores();
  const { addWorkspace, getWorkspace } = workspaceStore;

  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isCreatingTemporaryAccess, setIsCreatingTemporaryAccess] = useState<boolean>(false);
  const [isInviting, setInviting] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<ReadUser>();
  const [workspaceToEdit, setWorkspaceToEdit] = useState<ReadWorkspace>();
  const [memberToEdit, setMemberToEdit] = useState<Required<UpdateUserExtended>>({
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    position: "",
    roleIds: "",
    exceptions: [],
  });
  const [userToEditTargetWorkspaceId, setUserToEditTargetWorkspaceId] = useState<string>();
  const [isDeletingWorkspace, setIsDeletingWorkspace] = useState<boolean>(false);
  const [workspaceIdToDelete, setWorkspaceIdToDelete] = useState<string>("");
  const [workspaceNameToDelete, setWorkspaceNameToDelete] = useState<string>("");

  const [isViewingTemporaryExtract, setIsViewingTemporaryExtract] = useState(false);

  const auth = useContext(AuthContext);
  const canEditWorkspace = hasAuthorizations(auth, [Authorizations.WORKSPACE_EDIT]);
  const canEditUser = hasAuthorizations(auth, [Authorizations.USER_EDIT]);
  const canCreateTemporaryAccess = hasAuthorizations(auth, [Authorizations.USER_TEMPORARY]);
  const editionModalIsOpen = !!workspaceToEdit;

  useEffect(() => {
    autorun(async () => {
      workspaceStore.setLoading(true);
      // No need to fetch workspaces if the store already contains
      if (workspaceStore.workspaces.length > 0) {
        workspaceStore.setLoading(false);
        return;
      }

      const ws = (await getWorkspaces(onNotif))?.sort((a, b) => {
        //  Place own workspace first, then sort by name
        if (auth.user!.workspaceId === a.id) return -1;
        if (auth.user!.workspaceId === b.id) return 1;
        return a.name.localeCompare(b.name);
      });

      runInAction(() => {
        ws?.forEach((w, idx) => addWorkspace(new Workspace(idx, w)));
      });

      // DS-505: We expand our workspace if it's the only one
      if (workspaceStore.workspaces.length === 1) {
        await handleWorkspaceDropdownClick(workspaceStore.workspaces[0]);
      }

      workspaceStore.setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTeamMembersRows(members: ReadUser[]) {
    return members.map((member: ReadUser, idx: number) => {
      const hasExceptions = member.exceptions.length > 0;
      const hasPersonalData =
        hasExceptions && member.exceptions.some((e) => parseInt(e.id, 10) === Authorizations.SESSION_MEDIA);

      const exceptions = member.exceptions.filter((e) => parseInt(e.id, 10) !== Authorizations.SESSION_MEDIA);

      let displayExceptions = <></>;

      if (exceptions.length > 0) {
        const exceptionsTooltipTitle = exceptions.map((ex) => (
          <h3>
            {ex.id} : {ex.name}
          </h3>
        ));
        displayExceptions = (
          <Tooltip title={exceptionsTooltipTitle} key={idx}>
            {<Typography color="error">{tCommon("team_page.table.authorization_exceptions")}</Typography>}
          </Tooltip>
        );
      }

      const rows = [
        <Typography align="left" key={idx}>
          {member.firstName} {member.lastName}
        </Typography>,
        <Typography align="left" component="span">
          <Tooltip title={<h3> {translatedRoleDescription(member.roles[0], tCommon)} </h3>}>
            <Typography>{translatedRoleName(member.roles[0], tCommon)}</Typography>
          </Tooltip>
          {displayExceptions}
        </Typography>,
        <Typography align="left">
          {hasPersonalData ? t("members_listing.personal_data.yes") : t("members_listing.personal_data.no")}
        </Typography>,
      ];

      if (canEditUser) {
        rows.push(
          <List disablePadding={true}>
            <Grid container>
              <Grid item>
                <Button
                  className={classes.actionBtn}
                  onClick={() => {
                    const u: Required<UpdateUserExtended> = {
                      email: member.email,
                      exceptions: member.exceptions,
                      firstName: member.firstName,
                      id: member.id,
                      lastName: member.lastName,
                      position: member.position,
                      roleIds: member.roles[0]?.id ?? "",
                    };
                    setMemberToEdit({ ...u });
                    setIsEdit(true);
                    setUserToEditTargetWorkspaceId(member.workspace.id);
                  }}
                  startIcon={<EditIcon />}
                >
                  {tCommon("button_edit")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.actionBtn}
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setIsDeleting(true);
                    setUserToDelete(member);
                  }}
                >
                  {tCommon("button_delete")}
                </Button>
              </Grid>
            </Grid>
          </List>
        );
      }

      return rows;
    });
  }

  const handleWorkspaceDropdownClick = async (workspace: Workspace) => {
    if (!workspace.expanded && !Array.isArray(workspace.ws.teamMembers)) {
      try {
        await workspace.fetchTeamMembers();
      } catch (err) {
        const errMsg = err instanceof ApiFetchError ? err.fetchError : err.message || err;
        const errorFields = err instanceof ApiFetchError ? err.fieldsError : undefined;
        onNotif({
          textKey: errMsg,
          errorFields,
        });
        workspace.setLoading(false);
        return;
      }
    }
    workspace.toggleExpand();
  };

  function WorkspaceActionButtons({ isMyWorkspace, workspace }: WorkspaceActionsProps) {
    return (
      <>
        {canEditUser && (
          <Button
            className={classes.actionBtn}
            onClick={() => {
              workspaceStore.setWorkspaceForModal(workspace);
              setInviting(true);
            }}
            startIcon={<InviteIcon />}
          >
            {t("button_invite")}
          </Button>
        )}
        {canEditWorkspace && !isMyWorkspace && (
          <>
            <Button
              className={classes.actionBtn}
              onClick={() => setWorkspaceToEdit(workspace.ws.workspace)}
              startIcon={<EditIcon />}
            >
              {tCommon("button_edit")}
            </Button>

            <Button
              className={classes.actionBtn}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setIsDeletingWorkspace(true);
                setWorkspaceNameToDelete(toJS(workspace.ws.workspace.name));
                setWorkspaceIdToDelete(toJS(workspace.ws.workspace.id));
              }}
            >
              {tCommon("button_delete")}
            </Button>
          </>
        )}
      </>
    );
  }

  const teamMembersTableHeaders: string[] = [
    t("members_listing.headers.firstColumn"),
    t("members_listing.headers.secondColumn"),
    t("members_listing.headers.thirdColumn"),
  ];

  if (canEditUser) teamMembersTableHeaders.push("");

  function getWorkspacesRows() {
    const canListExternalUsers = auth.user!.authorizations.includes(Authorizations.USER_LIST_EXTERNAL);

    return workspaceStore.workspaces.map((workspace) => {
      const isMyWorkspace = auth.user!.workspaceId === workspace.ws.workspace.id;
      const isExpandable = isMyWorkspace || canListExternalUsers;

      const wsName = (
        <Typography className={classes.actionBtn}>
          {workspace.ws.workspace.name}{" "}
          <b className={classes.actionBtn}>
            {isMyWorkspace ? `(${t("own_workspace").toLowerCase()})` : null}
          </b>
        </Typography>
      );

      const row = [
        <List disablePadding={true}>
          <Grid container>
            <Grid item xs={8}>
              <ListItem>
                {isExpandable ? (
                  <Button
                    variant="text"
                    endIcon={workspace.expanded ? <ExpandLess /> : <ExpandMore />}
                    disableElevation
                    onClick={() => handleWorkspaceDropdownClick(workspace)}
                  >
                    {wsName}
                  </Button>
                ) : (
                  wsName
                )}
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem>
                <WorkspaceActionButtons isMyWorkspace={isMyWorkspace} workspace={workspace} />
              </ListItem>
            </Grid>
          </Grid>
          <Collapse
            style={{ marginLeft: "80px", width: "75%", justifyContent: "center" }}
            in={workspace.expanded}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding={true}>
              <TitledSection
                align="left"
                child={
                  workspace.ws.teamMembers ? (
                    <CustomTable
                      rows={getTeamMembersRows(workspace.ws.teamMembers)}
                      heads={teamMembersTableHeaders}
                      isLoading={workspace.loading}
                      setMinHeight
                      lastColAlignRight={canEditUser}
                      widths={["15%", "15%", "5%", "10%"]}
                    />
                  ) : (
                    <h1>{tCommon("loading")}</h1>
                  )
                }
              />
            </List>
          </Collapse>
        </List>,
      ];
      return row;
    });
  }

  function handleEditClose() {
    setWorkspaceToEdit(undefined);
    setIsCreate(false);
    setIsCreatingTemporaryAccess(false);
  }

  function CreateWorkspaceButton() {
    if (!canEditWorkspace) return null;
    return (
      <Button
        className={classes.createBtn}
        onClick={() => setIsCreate(true)}
        variant="contained"
        disableElevation
      >
        + {t("button_create")}
      </Button>
    );
  }

  function CreateTemporaryAccessButton() {
    if (!canCreateTemporaryAccess) return null;
    return (
      <Button
        className={classes.createBtn}
        onClick={() => setIsCreatingTemporaryAccess(true)}
        variant="contained"
        disableElevation
      >
        + {t("button_create_temp_access")}
      </Button>
    );
  }

  function TemporaryExtractLink() {
    if (!canCreateTemporaryAccess) return null;
    return (
      <Link
        onClick={() => setIsViewingTemporaryExtract(true)}
        underline="always"
        style={{ cursor: "pointer", margin: 8 }}
      >
        {t("link_temporary_extract")}
      </Link>
    );
  }

  const tableHeads: string[] = [t("table.header_name")];

  return (
    <>
      <div
        style={{ marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: "space-between" }}
      >
        <Box display="flex">
          <CreateWorkspaceButton />
          <Box m={1} />
          <CreateTemporaryAccessButton />
        </Box>
        <TemporaryExtractLink />
      </div>
      <CustomTable
        rows={getWorkspacesRows()}
        heads={tableHeads}
        setMinHeight
        lastColAlignRight={canEditWorkspace}
        isLoading={workspaceStore.isLoading}
      />
      {canEditWorkspace ? (
        <CreateWorkspaceModal
          open={isCreate}
          onClose={handleEditClose}
          onNotif={onNotif}
          handleClose={() => {
            setIsCreate(false);
            workspaceStore.orderWorkspaces(auth.user!.workspaceId);
          }}
        />
      ) : null}
      {canCreateTemporaryAccess ? (
        <CreateTemporaryAccessModal
          open={isCreatingTemporaryAccess}
          onClose={() => setIsCreatingTemporaryAccess(false)}
          onNotif={onNotif}
        />
      ) : null}
      {canCreateTemporaryAccess ? (
        <TemporaryExtractModal
          open={isViewingTemporaryExtract}
          onClose={() => setIsViewingTemporaryExtract(false)}
          onNotif={onNotif}
        />
      ) : null}

      {canEditWorkspace && workspaceToEdit ? (
        <UpdateWorkspace
          open={editionModalIsOpen}
          onClose={handleEditClose}
          workspace={workspaceToEdit}
          onNotif={onNotif}
        />
      ) : null}

      {canEditWorkspace && workspaceIdToDelete ? (
        <ConfirmDialog
          confirmText={workspaceNameToDelete}
          open={isDeletingWorkspace}
          onConfirm={async () => {
            if (await deleteWorkspace(onNotif, workspaceIdToDelete)) {
              workspaceStore.removeWorkspaceById(workspaceIdToDelete);
              onNotif({ textKey: "success.workspace_deleted" });
            }
            setWorkspaceNameToDelete("");
            setWorkspaceIdToDelete("");
            setIsDeletingWorkspace(false);
          }}
          onClose={() => {
            setWorkspaceNameToDelete("");
            setWorkspaceIdToDelete("");
            setIsDeletingWorkspace(false);
          }}
        />
      ) : null}

      {canEditUser && memberToEdit ? (
        <UpdateTeamMemberModal
          open={isEdit}
          userToEdit={memberToEdit}
          targetWorkspaceId={userToEditTargetWorkspaceId}
          onEdit={() => {
            if (memberToEdit!.id === auth.user!.userId) {
              (async () => {
                const sessionUser = await getSessionUser(auth.user!);
                if (!sessionUser) auth.unsetAuthUser();
                else auth.setAuthUser(sessionUser);
                if (userToEditTargetWorkspaceId) {
                  const targetWorkspace = workspaceStore.getWorkspace(userToEditTargetWorkspaceId);
                  if (targetWorkspace) {
                    console.info("[onEdit] targetWorkspace %s retrieved", targetWorkspace.ws.workspace.id);
                    await targetWorkspace.fetchTeamMembers();
                  }
                }
              })();
            }
            setIsEdit(false);
          }}
          onClose={() => setIsEdit(false)}
          onNotif={onNotif}
        />
      ) : null}
      {canEditUser ? (
        <CreateTeamMemberModal
          open={isInviting}
          onClose={() => {
            setInviting(false);
            workspaceStore.setWorkspaceForModal(undefined);
          }}
          onNotif={onNotif}
        />
      ) : null}
      {canEditUser && isDeleting ? (
        <ConfirmDialog
          confirmText={`${userToDelete?.firstName} ${userToDelete?.lastName}`}
          open={isDeleting}
          onClose={() => {
            setUserToDelete(undefined);
            setIsDeleting(false);
          }}
          onConfirm={async () => {
            if (await deleteUser(onNotif, userToDelete!.id)) {
              onNotif({ textKey: "success.user_deleted" });
              const w = getWorkspace(userToDelete!.workspace.id);
              w?.removeTeamMemberById(userToDelete!.workspace.id);
              await w?.fetchTeamMembers();
              setUserToDelete(undefined);
            }
            setIsDeleting(false);
          }}
        />
      ) : null}
    </>
  );
}

export default observer(WorkspacePage);
