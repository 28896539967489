import { useState, MouseEvent } from "react";

import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";

import { MenuArrowDown } from "./MenuArrowDown";

import { useScopedTranslation } from "../i18n";
import { isMobileDevice } from "../utils/misc_util";

type Props = {
  displayName: string;
  onProfileClicked: () => void;
  onLogoutClicked: () => void;
};

const profileMenuId = "primary-search-account-menu";

const useStyles = makeStyles((theme) => ({
  userButton: {
    maxWidth: isMobileDevice() ? "50%" : "auto",
  },
}));

export function ProfileMenu({ displayName, onProfileClicked, onLogoutClicked }: Props) {
  const { t } = useScopedTranslation("nav_top_user_menu");
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isProfileMenuOpen = Boolean(anchorEl);

  const onClick = (callback: () => void) => () => {
    setAnchorEl(null);
    callback();
  };

  return (
    <>
      <Button
        className={classes.userButton}
        startIcon={<AccountCircleIcon />}
        onClick={(e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
        aria-controls={profileMenuId}
      >
        {displayName}
        <MenuArrowDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={profileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isProfileMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={onClick(onProfileClicked)}>{t("profile")}</MenuItem>
        <MenuItem onClick={onClick(onLogoutClicked)}>{t("logout")}</MenuItem>
      </Menu>
    </>
  );
}
