export const SUBSCRIPTION_KINDS = <const>[
  "free_demo",
  "integrated_demo",
  "license",
  "pay_as_you_go",
  "per_packet",
];

export type SubscriptionKind = typeof SUBSCRIPTION_KINDS[number];

export type Subscription = {
  readonly id: number;
  readonly name: string;
  readonly kind: SubscriptionKind;
  readonly workspaceId: string;
  readonly workspaceName: string;
  startDate: Date;
  endDate?: Date;
  readonly maxSessionCount?: number;
  readonly sessionCount: number;
};

export type SubscriptionApp = {
  readonly id: string;
  readonly name: string;
  readonly sessionCount: number;
  readonly workspaceId: string;
  readonly workspaceName: string;
  readonly environment: string;
};

export type GetSubscriptionResponse = {
  subscription: Subscription;
  readonly applications: SubscriptionApp[];
};

export type SubscriptionFields = {
  name: string;
  kind: SubscriptionKind;
  maxSessionCount?: number;
  startDate: Date;
  endDate?: Date;
};

export type CreateSubscription = SubscriptionFields & { workspaceId: string };
export type UpdateSubscription = SubscriptionFields & { id: number };
