import { FormGroup, Grid, Box } from "@material-ui/core";

import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { TemporaryUserFormData } from "../../types/temporaryUserCRUD";
import { useScopedTranslation } from "../../i18n";

type Props = {
  temporaryUser: TemporaryUserFormData;
  onFieldChanged: <K extends keyof TemporaryUserFormData>(field: K, value: TemporaryUserFormData[K]) => void;
};

export default function CreateTemporaryAccessForm({ temporaryUser, onFieldChanged }: Props) {
  const { t } = useScopedTranslation("workspace_page.create_temp_access_dialog");

  return (
    <FormGroup>
      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={6}
            name={"firstName"}
            label={t("field_first_name")}
            value={temporaryUser.firstName}
            onChange={(e) => onFieldChanged("firstName", e.target.value)}
            onBlur={(e) => onFieldChanged("firstName", e.target.value.trim())}
          />
          <CustomTextField
            width={6}
            name={"lastName"}
            label={t("field_last_name")}
            value={temporaryUser.lastName}
            onChange={(e) => onFieldChanged("lastName", e.target.value)}
            onBlur={(e) => onFieldChanged("lastName", e.target.value.trim())}
          />
          <CustomTextField
            width={12}
            name={"email"}
            label={t("field_email")}
            value={temporaryUser.email}
            onChange={(e) => onFieldChanged("email", e.target.value)}
            onBlur={(e) => onFieldChanged("email", e.target.value.trim())}
          />
        </Grid>
      </Box>
    </FormGroup>
  );
}
