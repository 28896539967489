/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ root: { "& > * + *": { marginTop: theme.spacing(3) } } })
);

interface Props<T> {
  options: T[];
  values: T[];
  onChange: (values: T[]) => void;
  label: string;
  placeholder?: string;
}

export default function Tags<T extends { name: string }>({
  options,
  values,
  onChange,
  label,
  placeholder,
}: Props<T>) {
  return (
    <div
      className={
        // @ts-ignore
        useStyles().root
      }
    >
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        value={values}
        getOptionLabel={(o) => o.name}
        getOptionSelected={(option, value) => value.name === option.name}
        filterSelectedOptions
        onChange={(e: any, v: any[]) => onChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder || ""}
            InputLabelProps={{
              style: { fontSize: "small" },
            }}
          />
        )}
      />
    </div>
  );
}
