import { action, makeObservable, observable } from "mobx";
import { ReadUser } from "../types/userCRUD";
import { CreateWorkspace, ReadWorkspace } from "../types/workspaceCRUD";
import { doFetch } from "./utils";

const userOrdering = (user1: ReadUser, user2: ReadUser): number => {
  const wsNameOrdering = user1.workspace.name.localeCompare(user2.workspace.name);
  if (wsNameOrdering !== 0) return wsNameOrdering;
  const firstNameOrdering = user1.firstName.localeCompare(user2.firstName);
  if (firstNameOrdering !== 0) return firstNameOrdering;
  const lastNameOrdering = user1.lastName.localeCompare(user2.lastName);
  if (lastNameOrdering !== 0) return lastNameOrdering;
  return 0;
};

type WorkspaceState = {
  workspace: ReadWorkspace;
  teamMembers?: ReadUser[];
};

export class Workspace {
  ws: WorkspaceState;
  idx: number;
  loading: boolean;
  expanded: boolean;

  constructor(idx: number, workspace: ReadWorkspace) {
    makeObservable(
      this,
      {
        ws: observable,
        idx: observable,
        expanded: observable,
        loading: observable,
        setLoading: action.bound,
        toggleLoading: action.bound,
        toggleExpand: action.bound,
        addTeamMembers: action.bound,
        clearTeamMembers: action.bound,
        fetchTeamMembers: action.bound,
        upsertTeamMember: action.bound,
        removeTeamMemberById: action.bound,
      },
      { autoBind: true }
    );
    this.idx = idx;
    this.ws = { workspace };
    this.expanded = false;
    this.loading = false;
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  clearTeamMembers() {
    if (Array.isArray(this.ws.teamMembers)) {
      this.ws.teamMembers = [];
    }
  }

  async fetchTeamMembers() {
    this.setLoading(true);
    const users = await doFetch<ReadUser[]>("GET", `/users/workspace/${this.ws.workspace.id}`);
    // Cleaning old array
    this.clearTeamMembers();
    this.addTeamMembers(users.sort(userOrdering));
    this.setLoading(false);
  }

  addTeamMembers(members: ReadUser[]) {
    this.ws.teamMembers = Array.isArray(this.ws.teamMembers) ? this.ws.teamMembers.concat(members) : members;
  }

  upsertTeamMember(member: ReadUser) {
    if (!Array.isArray(this.ws.teamMembers)) {
      this.ws.teamMembers = [member];
      return;
    }
    const i = this.ws.teamMembers.findIndex((m) => m.id === member.id);
    if (i > -1) {
      this.ws.teamMembers[i] = member;
    } else {
      this.ws.teamMembers.push(member);
    }
  }

  removeTeamMemberById(id: string) {
    if (Array.isArray(this.ws.teamMembers)) {
      const idx = this.ws.teamMembers.findIndex((me) => me.id === id);
      if (idx > -1) {
        this.ws.teamMembers.splice(idx, 1);
      }
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}

export class WorkspaceStore {
  isLoading: boolean;
  workspaces: Workspace[] = [];
  selectedWorkspace?: Workspace;

  constructor(workspaces: Workspace[] = []) {
    makeObservable(
      this,
      {
        workspaces: observable,
        selectedWorkspace: observable,
        isLoading: observable,
        setLoading: action.bound,
        getWorkspace: action.bound,
        addWorkspace: action.bound,
        createWorkspace: action.bound,
        addMembersToWorkspace: action.bound,
        fetchWorkspaceById: action.bound,
        removeWorkspaceById: action.bound,
        setWorkspaceForModal: action.bound,
        orderWorkspaces: action.bound,
      },
      { autoBind: true }
    );
    this.isLoading = false;
    this.workspaces = observable.array(workspaces);
  }

  setWorkspaceForModal(workspace?: Workspace) {
    this.selectedWorkspace = workspace;
  }

  getWorkspace(workspaceId: string) {
    return this.workspaces.find((w) => w.ws.workspace.id === workspaceId);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  addWorkspace(ws: Workspace) {
    this.workspaces.push(ws);
  }

  upsertWorkspace(wsId: string, w: ReadWorkspace) {
    const idx = this.workspaces.findIndex((w) => w.ws.workspace.id === wsId);
    if (idx < 0) {
      this.addWorkspace(new Workspace(this.workspaces.length, w));
      return;
    }
    this.workspaces[idx].ws.workspace = w;
  }

  addMembersToWorkspace(wsId: string, members: ReadUser[]) {
    let ws = this.workspaces.find((w) => w.ws.workspace.id === wsId);
    if (ws) {
      ws.addTeamMembers(members);
    }
  }

  async createWorkspace(req: CreateWorkspace): Promise<string> {
    const w = await doFetch<string>("POST", `/workspaces`, req);
    await this.fetchWorkspaceById(w);
    return w;
  }

  async fetchWorkspaceById(wsId: string) {
    this.setLoading(true);
    const w = await doFetch<ReadWorkspace>("GET", `/workspaces/${wsId}`);
    this.upsertWorkspace(w.id, w);
    this.setLoading(false);
  }

  removeWorkspaceById(id: string) {
    const idx = this.workspaces.findIndex((el) => el.ws.workspace.id === id);
    if (idx > -1) {
      this.workspaces.splice(idx, 1);
    }
  }

  orderWorkspaces(ownWorkspaceId: string) {
    this.workspaces.sort((a, b) => {
      if (a.ws.workspace.id === ownWorkspaceId) return -1;
      if (b.ws.workspace.id === ownWorkspaceId) return 1;
      return a.ws.workspace.name.localeCompare(b.ws.workspace.name);
    });
  }
}
