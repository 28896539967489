import { Box, Button, Typography } from "@material-ui/core";
import { useState } from "react";

import PasswordStrengthChips from "../PasswordStrengthChips";

import PasswordInput from "./formComponents/PasswordInput";

import { useScopedTranslation } from "../../i18n";
import { passwordStrength } from "../../utils/password";

interface Props {
  onValidate: (password: string) => void;
  validateButtonTitle?: string;
}

export default function ResetPasswordForm({ onValidate, validateButtonTitle }: Props) {
  const { t } = useScopedTranslation("reset_password_page");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [strength, setStrength] = useState(passwordStrength(password));

  const onPasswordChange = (password: string) => {
    setPassword(password);
    setStrength(passwordStrength(password));
  };

  const onValidateButtonClick = () => {
    onValidate(password);
  };

  return (
    <Box display="flex" flexDirection="column">
      <PasswordInput label={t("field_new_password")} password={password} onChange={onPasswordChange} />
      <PasswordStrengthChips strength={strength} />

      <PasswordInput
        label={t("field_confirm_new_password")}
        password={confirmPassword}
        onChange={setConfirmPassword}
      />

      {confirmPassword.length > 0 && password !== confirmPassword && (
        <Typography variant="caption" color="error">
          {t("notice_password_dont_match")}
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={strength?.type !== "strong-enough" || password !== confirmPassword}
        style={{
          marginTop: "5%",
        }}
        onClick={onValidateButtonClick}
      >
        {validateButtonTitle ? validateButtonTitle : t("button_reset_password")}
      </Button>
    </Box>
  );
}
