import {
  FormControl,
  Grid,
  GridSize,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LockOutlined from "@material-ui/icons/LockOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HelpIcon from "@material-ui/icons/Help";
import { useState, useEffect, SetStateAction, Dispatch } from "react";

import CustomTextField from "./formComponents/CustomTextField";

import { EditUser, Role } from "../../types/userCRUD";
import { getRoles } from "../../services/api_services";
import { useScopedTranslation } from "../../i18n";
import { translatedRoleDescription, translatedRoleName } from "../../utils/i18n";

type Props = {
  //TODO: improve typing of the any below
  setFormData: Dispatch<SetStateAction<any>>;
  userFormData: EditUser;
  targetWorkspaceId?: string;
  isWorkspaceCreationForm?: boolean;
};

export default function UserForm({
  setFormData,
  userFormData,
  targetWorkspaceId,
  isWorkspaceCreationForm = false,
}: Props) {
  const { t, tCommon } = useScopedTranslation("create_user_dialog");

  const [roles, setRoles] = useState<Role[]>();
  const [roleIdsByName, setRoleIdsByName] = useState<Record<string, string>>({});
  const [personalDataReaderDisclaimer, setPersonalDataReaderDisclaimer] = useState<boolean>();

  useEffect(() => {
    (async () => {
      let rIdsByName: typeof roleIdsByName = {};
      const r = await getRoles(targetWorkspaceId);
      setRoles(r?.sort((a, b) => a.name.localeCompare(b.name)));
      r?.forEach((role) => {
        // if (role.name === "Personal Data Reader" || role.name === "Administrator")
        if (role.name === "Administrator") rIdsByName[role.name] = role.id;
      });
      setRoleIdsByName(rIdsByName);
    })();
  }, [targetWorkspaceId]);

  useEffect(() => {
    // Set Administrator and Personal Data Reader role by default if the form is a User Creation form
    // TODO: DS-18 should we only select Administrator role instead of Administrator & Personal Data Reader?
    if (isWorkspaceCreationForm && roles && roleIdsByName["Administrator"]) {
      setFormData((previousValue: EditUser) => ({
        ...previousValue,
        roleIds: roleIdsByName["Administrator"],
      }));
    }
  }, [isWorkspaceCreationForm, roleIdsByName, roles, setFormData]);

  function UserFormTextField(fieldName: keyof Omit<EditUser, "roleIds">, label: string, w: GridSize = 6) {
    return (
      <CustomTextField
        width={w}
        name={fieldName}
        onChange={(e) => setFormData({ ...userFormData, [fieldName]: e.target.value })}
        value={userFormData[fieldName]}
        label={label}
        onBlur={(e) => setFormData({ ...userFormData, [fieldName]: e.target.value.trim() })}
      />
    );
  }

  function setDisclaimerOnRoleChange(selectedRoleIds: string[]): void {
    //  Here I find out if the role "Personal Data Reader" is selected using its name
    //  I am unsure if the role ids will stay the same in the production database
    const pdrRoleId = roleIdsByName["Personal Data Reader"];
    if (!pdrRoleId) {
      console.error("Personal Data Reader role not found in role selection");
      return;
    }
    setPersonalDataReaderDisclaimer(selectedRoleIds.includes(pdrRoleId));
  }

  const roleSelectorLabel = t(`field_role_${isWorkspaceCreationForm ? "workspace_create" : "team_invite"}`);

  function roleSelectorRenderValue(): string {
    if (!roles) return "";
    return roles.find((r) => userFormData.roleIds === r.id)?.name ?? "";
  }

  return (
    <Grid container spacing={2}>
      {UserFormTextField("firstName", t("field_first_name"))}
      {UserFormTextField("lastName", t("field_last_name"))}
      {UserFormTextField("email", t("field_email"), 12)}
      <Grid item xs={12}>
        <FormControl required fullWidth variant="outlined">
          <InputLabel id="select-role-label">{roleSelectorLabel}</InputLabel>
          <Select
            labelId="select-role-label"
            label={roleSelectorLabel}
            // multiple
            value={userFormData.roleIds ?? ""}
            renderValue={roleSelectorRenderValue}
            MenuProps={{
              variant: "menu",
              getContentAnchorEl: null,
            }}
            onChange={(e) => {
              const roleIds = e.target.value as string;
              setFormData({ ...userFormData, roleIds });
              // FIXME: DS-18 do we need to keep this disclaimer?
              setDisclaimerOnRoleChange([roleIds]);
            }}
            disabled={isWorkspaceCreationForm}
            IconComponent={isWorkspaceCreationForm ? LockOutlined : ArrowDropDownIcon}
          >
            <MenuItem key="placeholder" style={{ display: "none" }} />
            {roles ? (
              roles.map((role, idx) => (
                <MenuItem dense value={role.id} key={idx}>
                  <ListItemText primary={translatedRoleName(role, tCommon)} />
                  <ListItemIcon>
                    <Tooltip
                      title={<Typography>{translatedRoleDescription(role, tCommon)}</Typography>}
                      interactive
                    >
                      <HelpIcon />
                    </Tooltip>
                  </ListItemIcon>
                </MenuItem>
              ))
            ) : (
              <Typography variant={"h6"} align={"center"}>
                {tCommon("loading")}
              </Typography>
            )}
          </Select>
        </FormControl>
      </Grid>

      {personalDataReaderDisclaimer && (
        <Typography color={"error"}>{t("notice_gdpr_personal_data_reader_selected")}</Typography>
      )}
    </Grid>
  );
}
