import {
  Box,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import theme from "../../../config/theme";
import { InsufficientFrameCountError, RndError } from "../../../types/session";

type Props = {
  name?: string;
  weakResults: Array<{ name: string; probability: number; error?: RndError }>;
  result?: { name: string; probability: number };
  threshold?: number;
};

// styles
const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  table_cell: {
    color: theme.palette.grey[500],
    // padding: "6px 24px 6px 16px"
  },
});

function insufficientFrameCountToReadable(error: InsufficientFrameCountError, kind: "target" | "reference") {
  const { count, required } = error;
  return `Insufficient frames for ${kind}: ${count} / ${required}`;
}

function rndErrorToReadable(error: RndError): string {
  if (error.padError) {
    const { count, required } = error.padError;
    return `Got ${count} frame(s), expecting ${required} frame(s)`;
  }
  if (error.recoLandmarkError) {
    const { referenceFail, targetFail } = error.recoLandmarkError;
    if (referenceFail && targetFail) {
      return "Landmarks error on target and reference";
    }
    if (referenceFail) {
      return "Landmarks error on reference";
    }
    if (targetFail) {
      return "Landmarks error on target";
    }
    return "Landmarks error on neither the target nor the reference ?";
  }
  if (error.recoTargetError) {
    return insufficientFrameCountToReadable(error.recoTargetError, "target");
  }
  if (error.recoRefError) {
    return insufficientFrameCountToReadable(error.recoRefError, "reference");
  }
  return "Unknown error";
}

export function ResultsTable(props: Props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {props.name && (
          <Typography variant="h6" component="h6">
            {props.name}
          </Typography>
        )}

        <Table className={classes.table} size="small" padding="none" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.table_cell}>Name</TableCell>
              <TableCell className={classes.table_cell} align="right">
                Probability
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.weakResults
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((result, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {result.name ?? "N/A"}
                  </TableCell>
                  {result.error ? (
                    <TableCell align="right" style={{ color: "#E47589" }}>
                      {rndErrorToReadable(result.error)}
                    </TableCell>
                  ) : (
                    <TableCell align="right">{result.probability}</TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      {props.result && (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Box
              padding={1}
              bgcolor="#DDF1F7"
              borderRadius={10}
              textAlign="center"
              fontWeight="fontWeightBold"
              color="#53BAD1"
            >
              <Box component="span" color={theme.palette.common.black} fontWeight="normal">
                {props.result.name + " "}
              </Box>
              {props.result.probability}
            </Box>

            {props.threshold !== undefined && (
              <Box
                padding={1}
                bgcolor="#ededed"
                borderRadius={10}
                textAlign="center"
                fontWeight="fontWeightBold"
                color="#202020"
              >
                <Box component="span" color={theme.palette.common.black} fontWeight="normal">
                  Threshold{" "}
                </Box>
                {props.threshold}
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
