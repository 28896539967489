import { Button, CircularProgress, DialogProps } from "@material-ui/core";
import { useState } from "react";
import { useScopedTranslation } from "../../i18n";

import { ModalV2 as Modal } from "../../components/Modal";
import { Notif } from "../../types/notif";
import { UpdateUserExtended } from "../../types/userCRUD";
import UpdateTeamMemberForm, { ExceptionEditStatus } from "./UpdateTeamMemberForm";
import {
  addPersonalDataAuthException,
  removePersonalDataAuthException,
  updateRoles,
  updateUser,
} from "../../services/api_services";
import { useEffect } from "react";
import { toJS } from "mobx";

type Props = DialogProps & {
  onClose: () => void;
  onEdit: () => void;
  onNotif: Notif;
  userToEdit: Required<UpdateUserExtended>;
  targetWorkspaceId?: string;
};

export default function UpdateTeamMemberModal({
  open,
  onClose,
  onEdit,
  onNotif,
  userToEdit,
  targetWorkspaceId,
}: Props) {
  const { t } = useScopedTranslation("workspace_page.update_member");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userToUpdate, setUserToUpdate] = useState<Required<UpdateUserExtended>>(userToEdit);
  const [personalDataException, setPersonalDataException] = useState<ExceptionEditStatus>("none");

  useEffect(() => {
    setUserToUpdate(userToEdit);
  }, [userToEdit]);

  const onUpdate = async () => {
    setIsLoading(true);

    const { exceptions, ...u } = toJS(userToUpdate);

    const tasks: (() => Promise<unknown | undefined>)[] = [
      () => updateUser(onNotif, u, u.id),
      () => updateRoles(onNotif, u.roleIds, u.id),
    ];

    if (personalDataException === "add") {
      tasks.push(() => addPersonalDataAuthException(onNotif, u.id));
    } else if (personalDataException === "delete") {
      tasks.push(() => removePersonalDataAuthException(onNotif, u.id));
    }

    let editionsSucceeded = true;
    for (const task of tasks) {
      const result = await task();

      // Note (Guillaume): When an api call returns undefined it should mean
      // that it failed
      // This is a very dubious design but that's what we have at to work with
      // at the moment…
      if (result === undefined) {
        editionsSucceeded = false;
        break;
      }
    }

    onEdit();

    if (editionsSucceeded) onNotif({ textKey: "success.user_edited" });

    setIsLoading(false);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      maxWidth="md"
      body={
        <UpdateTeamMemberForm
          userToEdit={userToUpdate}
          targetWorkspaceId={targetWorkspaceId}
          onFieldChanged={(field, value) => setUserToUpdate({ ...userToUpdate, [field]: value })}
          onPersonalDataChange={(v) => setPersonalDataException(v)}
        />
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onUpdate}
          startIcon={isLoading && <CircularProgress size={14} />}
          disabled={isLoading}
          fullWidth
        >
          {t("save")}
        </Button>
      }
    />
  );
}
