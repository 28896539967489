export type Templatable = string | number | bigint | boolean | null | undefined;

export type StripPrefix<Prefix extends Templatable, T> = T extends `${Prefix}${infer Suffix}`
  ? Suffix
  : never;

export type IndexWithPath<O, Path> = IndexWithPathImpl<O, Path>;

type IndexOrNever<O, K> = K extends keyof O ? O[K] : never;

type IndexWithPathImpl<O, Path> = Path extends ""
  ? O
  : Path extends `${infer Head}.${infer Tail}`
  ? IndexWithPathImpl<IndexOrNever<O, Head>, Tail>
  : IndexOrNever<O, Path>;

export type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: TObj[TKey] extends object
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & (string | number)];

export function twoWayMapping<Pairs extends (readonly [any, any])[]>(
  pairs: readonly [...Pairs]
): TwoWayMapping<Pairs> {
  return [Object.fromEntries(pairs), Object.fromEntries(pairs.map(([x0, x1]) => [x1, x0]))];
}

type TwoWayMapping<P extends (readonly [any, any])[]> = [
  { [T0 in Pairs0<P>]: PairsFind1<P, T0> },
  { [T1 in Pairs1<P>]: PairsFind0<P, T1> }
];

type Pairs0<P> = P extends readonly (readonly [infer T0, any])[] ? T0 : never;
type Pairs1<P> = P extends readonly (readonly [any, infer T1])[] ? T1 : never;

type PairsFind1<P, K0> = P extends [readonly [infer T0, infer T1], ...infer PRest]
  ? T0 extends K0
    ? T1
    : PairsFind1<PRest, K0>
  : never;

type PairsFind0<P, K1> = P extends [readonly [infer T0, infer T1], ...infer PRest]
  ? T1 extends K1
    ? T0
    : PairsFind0<PRest, K1>
  : never;
