import { CSSProperties } from "react";
import { Chip, ChipTypeMap } from "@material-ui/core";

import theme from "../config/theme";
import { useScopedTranslation } from "../i18n";
import { FaceComparisonResult, LivenessResult } from "../types/results";

type Props = {
  value: LivenessResult | FaceComparisonResult | "N/A";
};

function SessionResultChip({ value }: Props) {
  const { t } = useScopedTranslation("chips");

  const label = t(value);
  const { disabled, color, borderColor } = chipProps(value);

  return (
    <Chip
      label={label}
      variant="outlined"
      disabled={disabled}
      color={color}
      style={{
        borderColor,
        fontSize: "0.9rem",
        width: "150px",
        maxWidth: "100%",
        cursor: "inherit",
      }}
    />
  );
}

function chipProps(value: LivenessResult | FaceComparisonResult | "N/A"): {
  color: ChipTypeMap["props"]["color"];
  borderColor: CSSProperties["borderColor"];
  disabled: boolean;
} {
  switch (value) {
    case "N/A":
      return {
        color: "default",
        borderColor: undefined,
        disabled: true,
      };

    case "genuine":
    case "match":
      return {
        color: "primary",
        borderColor: theme.palette.success.main,
        disabled: false,
      };

    case "fail":
    case "mismatch":
      return {
        color: "primary",
        borderColor: "#3d59e8", // Unissey's "fail blue"
        disabled: false,
      };

    case "not_reached":
      return {
        color: "primary",
        borderColor: theme.palette.error.main,
        disabled: false,
      };

    case "error":
      return {
        color: "primary",
        borderColor: theme.palette.error.main,
        disabled: false,
      };
  }
}

// We provide wrappers to "hide" errors from users and fallback to fail/mismatch

export function LivenessChip({ value }: { value?: LivenessResult }) {
  const failed = value === "error" || value === "not_reached";
  const overridenValue = failed ? "fail" : value;
  return <SessionResultChip value={overridenValue ?? "N/A"} />;
}

export function FaceComparisonChip({ value }: { value?: FaceComparisonResult }) {
  const failed = value === "error" || value === "not_reached";
  const overridenValue = failed ? "mismatch" : value;
  return <SessionResultChip value={overridenValue ?? "N/A"} />;
}
