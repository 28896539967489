import React, { useRef, useEffect, useState } from "react";
import { Typography, Tooltip } from "@material-ui/core";

type Props = { text: string; maxWidth?: number; width?: number };
export default function OverflowTooltip({ text, maxWidth, width }: Props) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (textElementRef.current)
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);

  return (
    <div style={{ maxWidth: "100%", width }}>
      <Tooltip title={<Typography>{text}</Typography>} interactive disableHoverListener={!isOverflowed}>
        <Typography ref={textElementRef} noWrap style={{ textOverflow: "ellipsis", fontSize: "0.9rem" }}>
          {text}
        </Typography>
      </Tooltip>
    </div>
  );
}
