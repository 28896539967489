import React from "react";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Grid, GridSize, makeStyles } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { AuthContext } from "../../../contexts/auth_context";
import { DateRange } from "@material-ui/icons";

interface CustomDateTimePickerProps {
  label: string;
  value: Date | null;
  onChange: (date: MaterialUiPickersDate) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  width: GridSize;
  disableFuture?: boolean;
  disablePast?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles({
  inputLabelRoot: {
    fontSize: "small",
  },
});

export default function CustomDateTimePicker({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  width,
  disableFuture,
  fullWidth,
}: CustomDateTimePickerProps) {
  const auth = React.useContext(AuthContext);
  if (!minDate) minDate = auth.user!.workspaceCreatedAt;
  const styles = useStyles();

  return (
    <Grid item xs={width} style={{ paddingTop: 0 }}>
      <DateTimePicker
        clearable
        fullWidth={fullWidth}
        variant="dialog"
        disableFuture={disableFuture}
        ampm={false}
        format="dd/MM/yy HH:mm:ss"
        label={label}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        InputProps={{
          startAdornment: <DateRange />,
          margin: "dense",
        }}
        InputLabelProps={{
          margin: "dense",
          classes: { root: styles.inputLabelRoot },
        }}
      />
    </Grid>
  );
}
