import DateFnsUtils from "@date-io/date-fns";
import { Box } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderFilterMenu } from ".";
import CustomDateTimePicker from "../forms/formComponents/CustomDateTimePicker";

type DateRange = {
  after?: Date;
  before?: Date;
};

type Props = {
  initialRange: DateRange;
  onRangeChanged: (range: DateRange) => void;
};

export function DateHeaderFilter({ initialRange, onRangeChanged }: Props) {
  const { t } = useTranslation();
  const [range, setRange] = useState(initialRange);

  return (
    <HeaderFilterMenu
      onConfirm={() => onRangeChanged(range)}
      onCancel={() => setRange(initialRange)}
      onClearAll={() => setRange({})}
      activeIndicator={(initialRange.after || initialRange.before) && "*"}
    >
      <Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CustomDateTimePicker
            value={range.after ?? null}
            onChange={(date) => setRange({ ...range, after: date ?? undefined })}
            label={t("sessions_page.filters.label_filter_start_date")}
            maxDate={range.before}
            width={"auto"}
          />
          <CustomDateTimePicker
            value={range.before ?? null}
            onChange={(date) => setRange({ ...range, before: date ?? undefined })}
            label={t("sessions_page.filters.label_filter_end_date")}
            minDate={range.after}
            width={"auto"}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </HeaderFilterMenu>
  );
}
