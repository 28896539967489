import { observable, reaction, toJS } from "mobx";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Chip, makeStyles, Typography } from "@material-ui/core";

import { Session, SessionAnnotation } from "../../../types/session";
import { Annotations, MultiValueAnnotation, SingleValueAnnotation } from "../../../stores/annotations";

type AnnotationSpec = {
  title: string;
  items: SpecItem[];
};

type SpecItem = {
  value: number;
  label: string;
};

type SingleValueAnnotationKey = keyof Required<SingleValueAnnotation>;
type MultiValueAnnotationKey = keyof Required<MultiValueAnnotation>;

const singleAnnotationSpecs: { [_ in SingleValueAnnotationKey]: AnnotationSpec } = {
  type: {
    title: "Type",
    items: [
      { value: 0, label: "Train" },
      { value: 1, label: "Test" },
    ],
  },
  rejected: {
    title: "Rejected",
    items: [
      { value: 0, label: "Occlusion" },
      { value: 1, label: "Movement" },
      { value: 2, label: "Position" },
      { value: 3, label: "Luminosity" },
      { value: 4, label: "Attack" },
      { value: 5, label: "Child" },
      { value: 6, label: "Duplicated" },
    ],
  },
  // For some historic reason, the following two items start at `1`
  // TODO (Guillaume): We should ask the R&D if it's simple for them to adjust
  // their script, so that we can normalize those and make them start at 0
  // This would even remove the need for associating a value to each label
  label: {
    title: "Label",
    items: [
      { value: 1, label: "Genuine" },
      { value: 2, label: "Attack" },
    ],
  },
  attack_type: {
    title: "Attack Type",
    items: [
      { value: 1, label: "Paper" },
      { value: 2, label: "Screen" },
      { value: 3, label: "Replay" },
      { value: 4, label: "3D Mask" },
      { value: 5, label: "Other" },
    ],
  },

  gender: {
    title: "Gender",
    items: [
      { value: 0, label: "Man" },
      { value: 1, label: "Woman" },
      { value: 2, label: "Other" },
    ],
  },
  geography: {
    title: "Geography",
    items: [
      { value: 0, label: "Caucasian" },
      { value: 1, label: "Asian" },
      { value: 2, label: "African" },
      { value: 3, label: "Latin American" },
      { value: 4, label: "Middle East" },
      { value: 5, label: "Other" },
    ],
  },
  environment: {
    title: "Environment",
    items: [
      { value: 0, label: "Indoor" },
      { value: 1, label: "Outdoor" },
    ],
  },
  glasses: {
    title: "Glasses",
    items: [
      { value: 0, label: "Glasses" },
      { value: 1, label: "No Glasses" },
    ],
  },
  age: {
    title: "Age",
    items: [
      { value: 0, label: "Junior" },
      { value: 1, label: "Senior (> 55 years old)" },
    ],
  },
  face_match: {
    title: "Face Comparison",
    items: [
      { value: 0, label: "Match" },
      { value: 1, label: "Mismatch" },
    ],
  },
};

const multiAnnotationSpecs: { [_ in MultiValueAnnotationKey]: AnnotationSpec } = {
  target_face_quality: {
    title: "Target Face Quality",
    items: [
      { value: 0, label: "Low res" },
      { value: 1, label: "Over-exposure" },
      { value: 2, label: "Reflection" },
      { value: 3, label: "Eye closed" },
      { value: 4, label: "Mouth open" },
      { value: 5, label: "Profile/bad angle" },
    ],
  },
  reference_face_quality: {
    title: "Reference Face Quality",
    items: [
      { value: 0, label: "Low res" },
      { value: 1, label: "Over-exposure" },
      { value: 2, label: "Reflection" },
      { value: 3, label: "Eye closed" },
      { value: 4, label: "Mouth open" },
      { value: 5, label: "Profile/bad angle" },
    ],
  },
};

const SINGLE_ANNOTATION_KEYS = Object.keys(singleAnnotationSpecs) as SingleValueAnnotationKey[];
const MULTI_ANNOTATION_KEYS = Object.keys(multiAnnotationSpecs) as MultiValueAnnotationKey[];

type Props = {
  session: Session;
  onChange: (annotation: SessionAnnotation) => void;
};

const SessionAnnotationControls = observer(({ session, onChange }: Props) => {
  const [annotations, setAnnotations] = useState(() => observable(new Annotations(session.annotation)));

  useEffect(() => {
    // We notify changes every time annotations change.
    // TODO (Guillaume); The long term idea would be to avoid having a reaction
    // here and hoist the annotation store up at the session level instead
    // The store could also be responsible for making the api call
    const disposer = reaction(
      () => toJS(annotations.sessionAnnotation),
      () => onChange(toJS(annotations.sessionAnnotation))
    );

    return disposer;
  }, [annotations, onChange]);

  useEffect(() => {
    setAnnotations(observable(new Annotations(session.annotation)));
  }, [session]);

  const singleSections = SINGLE_ANNOTATION_KEYS.map((key) => {
    const spec = singleAnnotationSpecs[key];

    const chipItems = spec.items.map((item) => {
      const selected = annotations.sessionAnnotation[key] === item.value;
      return { ...item, selected };
    });

    return [spec.title, chipItems, (value: number) => annotations.toggleSingle(key, value)] as const;
  });

  const multiSections = MULTI_ANNOTATION_KEYS.map((key) => {
    const spec = multiAnnotationSpecs[key];

    const chipItems = spec.items.map((item) => {
      const selected = annotations.sessionAnnotation[key]?.includes(item.value) ?? false;
      return { ...item, selected };
    });

    return [spec.title, chipItems, (value: number) => annotations.toggleMulti(key, value)] as const;
  });

  return (
    <Box display="flex" flexDirection="column">
      {[...singleSections, ...multiSections].map(([title, chipItems, onClick]) => {
        return (
          <Box display="flex" marginBottom="2%" width="100%" flexDirection="column">
            <Typography variant="subtitle2" color="textSecondary">
              {title}
            </Typography>

            <Box m={0.3} />

            <ItemChips items={chipItems} onClick={onClick} />
          </Box>
        );
      })}
    </Box>
  );
});

export default SessionAnnotationControls;

const useChipsStyles = makeStyles({
  chipLabelSelected: {
    color: "white",
  },
});

type ItemChipsProps = {
  items: (SpecItem & { selected: boolean })[];
  onClick: (value: number) => void;
};

const ItemChips = observer(({ items, onClick }: ItemChipsProps) => {
  const classes = useChipsStyles();

  return (
    <Box display="flex">
      {items.map((item) => {
        return (
          <Box marginRight="0.5%" key={item.value}>
            <Chip
              variant={item.selected ? undefined : "outlined"}
              label={item.label}
              onClick={() => onClick(item.value)}
              color={item.selected ? "primary" : undefined}
              style={{ color: "white" }}
              classes={{
                label: item.selected ? classes.chipLabelSelected : undefined,
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
});
