import { DialogProps, FormGroup, Box, CircularProgress, Grid, Button } from "@material-ui/core";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/auth_context";

import { Notif } from "../../types/notif";
import { TeamMemberForm } from "../../types/userCRUD";

import { createUser } from "../../services/api_services";
// import { useStores } from "../../hooks/use-stores";
// import { hasAuthorizations } from "../../utils/auth_util";
// import { Authorizations } from "@unissey/utils";
import { useScopedTranslation } from "../../i18n";

import { ModalV2 as Modal } from "../../components/Modal";
import UserForm from "../../components/forms/UserForm";
import CustomSelect from "../../components/forms/formComponents/CustomSelect";
import { reaction } from "mobx";
import { useStores } from "../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import LockOutlined from "@material-ui/icons/LockOutlined";

type Props = {
  onClose: () => void;
  onNotif: Notif;
};

export type SelectWorkspace = {
  name: string;
  id: string;
};

function CreateTeamMemberModal({ open, onClose, onNotif }: Props & DialogProps) {
  const { workspaceStore } = useStores();
  const auth = useContext(AuthContext);
  const { t } = useScopedTranslation("team_page.create_user_dialog");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<SelectWorkspace[]>([]);
  const [userToCreate, setUserToCreate] = useState<TeamMemberForm>({
    firstName: "",
    lastName: "",
    email: "",
    roleIds: "",
    workspaceid: "",
    ownerid: auth.user!.workspaceId,
  });

  const dispose = reaction(
    () => workspaceStore.selectedWorkspace,
    (current) => {
      if (current !== undefined) {
        setUserToCreate({
          ...userToCreate,
          workspaceid: current.ws.workspace.id,
        });
        setWorkspaces([{ id: current.ws.workspace.id, name: current.ws.workspace.name }]);
      } else {
        setUserToCreate({
          ...userToCreate,
          workspaceid: "",
        });
        setWorkspaces([]);
      }
    }
  );

  const handleUserCreation = async () => {
    if (userToCreate && auth.user) {
      const user = {
        ...userToCreate,
        cguConsent: true,
      };
      setIsLoading(true);
      if (await createUser(onNotif, user)) {
        onNotif({ textKey: "success.user_created" });
        if (workspaceStore.selectedWorkspace) {
          await workspaceStore.selectedWorkspace.fetchTeamMembers();
        }
      }
      dispose();
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={() => {
        dispose();
        onClose();
      }}
      title={t("title")}
      body={
        <FormGroup>
          <Grid container>
            <UserForm
              setFormData={setUserToCreate}
              userFormData={userToCreate}
              targetWorkspaceId={userToCreate.workspaceid}
            />
            <Box py={2} width="100%">
              <CustomSelect
                width={12}
                labelId="create-team-member-select-workspace-label"
                labelValue={t("field_select_workspace")}
                value={userToCreate.workspaceid}
                menuItems={workspaces.map((workspace) => [workspace.id, workspace.name])}
                onChange={(e) => setUserToCreate({ ...userToCreate, workspaceid: e.target.value as string })}
                IconComponent={LockOutlined}
                disabled
              />
            </Box>
          </Grid>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleUserCreation}
          startIcon={isLoading && <CircularProgress size={14} />}
          disabled={isLoading}
          fullWidth
        >
          {t("button_invite")}
        </Button>
      }
    />
  );
}

export default observer(CreateTeamMemberModal);
