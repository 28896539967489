import type { Authorizations } from "@unissey/utils";

import { Box, Grid, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { useScopedTranslation } from "../../i18n";

type Props = {
  userAuthorizations: Authorizations[];
  requiredAuthorizations: Authorizations[];
};

export default function RestrictedPage({ userAuthorizations, requiredAuthorizations }: Props) {
  const { t } = useScopedTranslation("restricted_access_page");

  return (
    <>
      <Box display="flex" flex="1" alignItems="center" justifyContent="center">
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={8} lg={6} item>
            <Box display="flex" flex="1" alignItems="center">
              <LockIcon style={{ fontSize: 40, color: "#00bcd4" }} />
              <Box mr={1.5} />
              <Typography variant="h4">{t("title")}</Typography>
            </Box>
            <Box m={2} />

            <Box m={2} />

            <Typography variant="h5">{t("content")}</Typography>
            <Box m={2} />

            <Typography>
              {t("user_authorizations")}: <b>{userAuthorizations.join(", ")}</b>
            </Typography>
            <Typography>
              {t("required_authorizations")}: <b>{requiredAuthorizations.join(", ")}</b>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
