import { Box, CircularProgress, Typography, makeStyles, Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LanguageSwitchMenu } from "../../components/LanguageSwitchMenu";
import theme from "../../config/theme";
import { useScopedTranslation } from "../../i18n";
import { DemoMode } from "../../types/demo";
import { Demo } from "../demo/DemoPage";
import { TermsOfUse } from "../demo/TermsOfUsePage";
import { activateTemporaryUser, getTemporaryUser } from "../../services/api_services";
import { Trans } from "react-i18next";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    overflow: "auto",
  },

  demoContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
  },

  welcomeTextContainer: {
    margin: "1em",
    display: "flex",
    flexDirection: "column",
    "& > *": {
      lineHeight: "3em",
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
    },
  },

  helpText: {
    margin: "auto 5%",
  },
});

type PageState =
  | { type: "extract-params" }
  | { type: "fetching-api-key"; temporaryUserId: string }
  | { type: "demo-to-activate"; temporaryUserId: string; activated: boolean }
  | { type: "demo-ready"; temporaryUserId: string; apiKey: string; timeLeft: number }
  | { type: "demo-started"; temporaryUserId: string; apiKey: string; timeLeft: number; mode: DemoMode }
  | { type: "error"; reason: "missing-user-id" | "user-expired" };

export function TransientDemoPage() {
  const { t, tCommon } = useScopedTranslation("transient_demo_page");
  const location = useLocation();
  const classes = useStyles();

  const history = useHistory();

  const [pageState, setPageState] = useState<PageState>({ type: "extract-params" });

  const extractParams = useCallback(() => {
    const query = new URLSearchParams(location.search);
    const temporaryUserId = query.get("user_id");
    if (temporaryUserId !== null) {
      setPageState({ type: "fetching-api-key", temporaryUserId });
    } else {
      setPageState({ type: "error", reason: "missing-user-id" });
    }
  }, [location]);

  const fetchApiKey = useCallback((temporaryUserId: string) => {
    getTemporaryUser(temporaryUserId)
      .then(({ hash, timeLeft, activated }) => {
        if (activated) setPageState({ type: "demo-ready", apiKey: hash, timeLeft, temporaryUserId });
        else setPageState({ type: "demo-to-activate", temporaryUserId, activated });
      })
      .catch((_e) => setPageState({ type: "error", reason: "user-expired" }));
  }, []);

  const activateUser = useCallback((temporaryUserId: string) => {
    activateTemporaryUser(temporaryUserId)
      .then(({ hash, timeLeft }) => {
        setPageState({ type: "demo-ready", apiKey: hash, timeLeft, temporaryUserId });
      })
      .catch((_e) => setPageState({ type: "error", reason: "user-expired" }));
  }, []);

  useEffect(() => {
    switch (pageState.type) {
      case "extract-params":
        return extractParams();
      case "fetching-api-key":
        return fetchApiKey(pageState.temporaryUserId);
      default:
        break;
    }
  }, [pageState, extractParams, fetchApiKey]);

  const Content = () => {
    switch (pageState.type) {
      case "extract-params":
      case "fetching-api-key":
        return (
          <Box minHeight="80vh" display="flex" alignItems="center">
            <CircularProgress size={100} />
          </Box>
        );
      case "demo-to-activate":
        return (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <UnisseyLogo />
              <WelcomeText />

              <Button
                variant="contained"
                color="primary"
                disableElevation={true}
                size="large"
                onClick={() => activateUser(pageState.temporaryUserId)}
              >
                {t("start_button").toUpperCase()}
              </Button>
            </Box>
          </Box>
        );
      case "demo-ready":
        return (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <UnisseyLogo />
              <DemoReadyText timeLeft={pageState.timeLeft} />
            </Box>
            <TermsOfUse
              availableModes={["liveness-and-face-comparison"]}
              onDemoModeChosen={(mode) => setPageState({ ...pageState, type: "demo-started", mode })}
            />
          </Box>
        );
      case "demo-started":
        return (
          <Demo
            mode={pageState.mode}
            apiKey={pageState.apiKey}
            gdprConsent={true} // User passed TOU screen
            onExit={() => setPageState({ ...pageState, type: "demo-ready" })}
            onFinish={() =>
              setPageState({ type: "fetching-api-key", temporaryUserId: pageState.temporaryUserId })
            }
            finishText={tCommon("demo_page.results_screen.retry")}
            userId={pageState.temporaryUserId}
            onBack={() => history.goBack()}
            preset="FAST"
            vqcLevel={0}
          />
        );
      case "error":
        return (
          <>
            <ContactIcon />
            <Typography variant="h4" align="center" className={classes.helpText}>
              {t(
                `notice_error_${pageState.reason === "missing-user-id" ? "missing_user_id" : "user_expired"}`
              )}
            </Typography>
            <Box m={1}></Box>
            <Typography variant="h5" align="center" className={classes.helpText}>
              <Trans
                i18nKey="transient_demo_page.notice_error_help_text"
                values={{ salesLeadEmail: "sales@unissey.com" }}
                components={[<a href="mailto:sales@unissey.com">email</a>]}
              />
            </Typography>
          </>
        );
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <LanguageSwitchMenu />
      </Box>

      <Box className={classes.mainContainer}>
        <Content />
      </Box>
    </Box>
  );
}

function CountDown({ initialTime }: { initialTime: number }) {
  const [time, setTime] = useState(Math.floor(initialTime));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (time > 0) setTime((time) => time - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [time]);

  const fmtTime = (t: number) => {
    const seconds = t % 60;
    const minutes = (t - seconds) / 60;

    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  return <strong>{fmtTime(time)}</strong>;
}

function DemoReadyText({ timeLeft }: { timeLeft: number }) {
  const { t } = useScopedTranslation("transient_demo_page");
  const classes = useStyles();

  return (
    <Box className={classes.welcomeTextContainer} display="flex" textAlign="center">
      <Typography variant="h5" style={{ lineHeight: "35px" }}>
        {t("notice_welcome_p4")} <br />
        {t("notice_welcome_p5")} <CountDown initialTime={timeLeft} /> {t("notice_welcome_p6")}
      </Typography>
    </Box>
  );
}

function WelcomeText() {
  const { t } = useScopedTranslation("transient_demo_page");
  const classes = useStyles();

  return (
    <Box className={classes.welcomeTextContainer} display="flex" textAlign="center">
      <Typography variant="h6">
        {" "}
        <strong>{t("notice_welcome_p1")} </strong>{" "}
      </Typography>
      <Typography variant="h5"> {t("notice_welcome_p2")} </Typography>
      <Typography variant="h5"> {t("notice_welcome_p3")} </Typography>
    </Box>
  );
}

function UnisseyLogo() {
  return (
    <Box maxWidth="250px" minWidth="200px" width="100%">
      <img src={`${process.env.PUBLIC_URL}/images/unissey-logo-only.svg`} alt="Unissey" />
    </Box>
  );
}

function ContactIcon() {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <img
        src={`${process.env.PUBLIC_URL}/images/illustration_contact.svg`}
        alt="contact"
        style={{
          minWidth: "200px",
          maxWidth: "400px",
        }}
      />
    </Box>
  );
}
