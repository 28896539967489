import { Box, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { GetSubscriptionResponse } from "../../types/subscriptionsCRUD";
import { Notif } from "../../types/notif";
import { getSubscription } from "../../services/api_services";
import OverflowTooltip from "../../components/OverflowTooltip";
import { dateFormat } from "../../utils/parsing";
import CustomTable from "../../components/CustomTable";
import TitledSection from "../../components/TitledSection";
import { isSubscriptionActive } from "./SubscriptionsPage";
import { StripPrefix } from "../../utils/typings";
import { I18nCommonKeys, useScopedTranslation } from "../../i18n";

import { ModalV2 as Modal } from "../../components/Modal";

type Props = {
  onNotif: Notif;
  selectedSubscriptionId: number | undefined;
  onClose: () => void;
};

type I18nTableKeys = StripPrefix<"row_", I18nCommonKeys<"subscription_page.details_modal">>;

function SubscriptionDetails({ subInfo }: { subInfo: GetSubscriptionResponse }) {
  const { t, tCommon } = useScopedTranslation("subscription_page.details_modal");

  const { subscription: sub, applications: apps } = subInfo;
  const maxSessionCount = sub.maxSessionCount === 0 ? undefined : sub.maxSessionCount; // Note (Guillaume): This should really be typed as a number | undefined in the model to begin with…
  const totalSessionCount = apps.reduce((a, b) => a + b.sessionCount, 0);
  const remainingSessionCount = maxSessionCount && Math.max(0, maxSessionCount - totalSessionCount);
  const isActive = isSubscriptionActive(sub, new Date());

  const tableRow = ([name, value]: [string, string]) => [
    <OverflowTooltip text={name} width={600} />,
    <OverflowTooltip text={value} maxWidth={300} />,
  ];
  const tableRows = (info: [string, string][]) => info.map(tableRow);
  const transTableRows = (info: [I18nTableKeys, string | undefined][]) =>
    tableRows(info.map(([name, value]) => [t(`row_${name}`), value ?? t("N/A")]));

  const subscriptionTableRows = transTableRows([
    ["name", sub.name],
    ["type", tCommon(`sub_kind.${sub.kind}`)],
    ["status", t(`status_${isActive ? "active" : "inactive"}`)],
    ["workspace", sub.workspaceName],
    ["max_sessions", maxSessionCount?.toString()],
    ["start_date", dateFormat(sub.startDate)],
    ["end_date", sub.endDate && dateFormat(sub.endDate)],
  ]);

  const consumptionTableRows = transTableRows([
    ["total_session_count", totalSessionCount.toString()],
    ["remaining_session_count", remainingSessionCount?.toString()],
  ]);

  const detailedConsumptionTableRows = tableRows(apps.map((app) => [app.name, app.sessionCount.toString()]));

  return (
    <>
      <Box pb={6}>
        <TitledSection
          title={t("title_details")}
          child={<CustomTable rows={subscriptionTableRows} lastColAlignRight />}
        />
      </Box>
      {apps.length !== 0 && (
        <>
          <Box pb={4}>
            <TitledSection
              title={t("title_consumption")}
              child={<CustomTable rows={consumptionTableRows} lastColAlignRight />}
            />
          </Box>
          <Box pb={6}>
            <TitledSection
              title={t("title_details_consumption")}
              child={<CustomTable rows={detailedConsumptionTableRows} lastColAlignRight />}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default function SubscriptionModal({ onNotif, selectedSubscriptionId, onClose }: Props) {
  const { t } = useScopedTranslation("subscription_page.details_modal");

  const [subInfo, setSubInfo] = useState<GetSubscriptionResponse>();

  useEffect(() => {
    (async () => {
      if (!selectedSubscriptionId) return;
      const info = await getSubscription(onNotif, selectedSubscriptionId);
      setSubInfo(info);
    })();
  }, [selectedSubscriptionId, onNotif]);

  return (
    <Modal
      open={selectedSubscriptionId !== undefined}
      onClose={() => {
        setSubInfo(undefined);
        onClose();
      }}
      onCancel={() => {
        setSubInfo(undefined);
        onClose();
      }}
      hideCancel
      fullWidth
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      title={t("title")}
      body={
        <>
          {subInfo ? (
            <SubscriptionDetails subInfo={subInfo} />
          ) : (
            <Box pt={20} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </>
      }
      action={<></>}
    />
  );
}
