import { RecordData } from "@unissey/sdk-react__next/dist/types";
import { config } from "../constants/env";

export enum VqcHint {
  CovidMask = 100,
  Darkness = 200,
  Brightness = 201,
}

type MediaValidation = "valid" | "unreadable" | "insufficient_frame_count";
type FaceDetectionResult =
  | "face_detected"
  | "face_not_detected"
  | "face_not_centered"
  | "multiple_faces_detected";

type ConfidenceLevel = "low" | "high";
type LivenessResult = "genuine" | "suspected_spoof";

type FaceComparisonResult = "match" | "suspected_mismatch" | "not_reached";

type InjectionResult = "suspected_injection" | "trusted";

type FaceDetection = {
  result: FaceDetectionResult;
  face_count?: number;
  face_bbox?: unknown[];
};

export type AnalyzeResponse = {
  message: string;
  status: number;
  data: {
    session_id: string;
    session_group_id: string;
    retries_remaining: number;
    is_match?: boolean;
    is_genuine?: boolean;
    details: {
      media_validation?: {
        selfie: MediaValidation;
        reference?: MediaValidation;
      };
      face_detection?: {
        selfie: FaceDetection;
        reference?: FaceDetection;
      };
      liveness?: {
        result: LivenessResult;
        confidence_level?: ConfidenceLevel;
      };
      face_comparison?: {
        result: FaceComparisonResult;
        confidence_level?: ConfidenceLevel;
      };
      hints_for_retry?: {
        selfie: {
          hints: VqcHint[];
        };
      };
      injection?: {
        result: InjectionResult;
        activated: boolean;
      };
    };
  };
  error?: {
    status: number;
    message: string;
    details: string;
  };
};

export type AnalyzeError = {
  code: number;
};

type AnalyzeParams = {
  apiKey: string;
  selfie: RecordData;
  gdprConsent: Boolean;
  reference?: Blob;
  bundleId?: String;
};

export async function analyze({
  apiKey,
  selfie,
  gdprConsent,
  reference,
  bundleId,
}: AnalyzeParams): Promise<AnalyzeResponse> {
  const body = new FormData();
  body.append("selfie", selfie.video);
  body.append("metadata", JSON.stringify(selfie.meta));

  if (reference) {
    body.append("reference", reference);
    body.append("processings", "liveness,face-comparison");
  } else {
    body.append("processings", "liveness");
  }

  body.append("gdpr-consent", `${gdprConsent}`);

  const analyzeUrl = `${config.apiAnalyzeUrl}/analyze/${bundleId ?? ""}`;

  return fetch(analyzeUrl, {
    method: "POST",
    headers: {
      Authorization: apiKey,
    },
    body: body,
  })
    .then((response) => response.json())
    .then((data: AnalyzeResponse) => data);
}

type Hint = "generic" | "mask" | "brightness" | "mask-brightness";

export function getVqcHintsType(apiResponse: AnalyzeResponse): Hint {
  const hints = apiResponse.data.details.hints_for_retry?.selfie.hints ?? [];

  const hasMaskHint = hints.includes(VqcHint.CovidMask);
  const hasLuminosityHint = hints.includes(VqcHint.Brightness) || hints.includes(VqcHint.Darkness);

  if (hasMaskHint && hasLuminosityHint) return "mask-brightness";

  if (hasLuminosityHint) return "brightness";

  if (hasMaskHint) return "mask";

  return "generic";
}

export function hasError(apiResponse: AnalyzeResponse) {
  return ![200, 206].includes(apiResponse.status);
}
