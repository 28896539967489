import { ROLE_IDS } from "@unissey/utils";
import { TFunction } from "react-i18next";
import { Role } from "../types/userCRUD";

export function translatedRoleName(role: Role, translator: TFunction): string {
  const roleId = ROLE_IDS.find((id) => id === role.roleId);
  if (roleId !== undefined) {
    return translator(`roles._${roleId}`);
  }
  return role.name;
}

export function translatedRoleDescription(role: Role, translator: TFunction): string {
  const roleId = ROLE_IDS.find((id) => id === role.roleId);
  if (roleId !== undefined) {
    return translator(`roles_descriptions._${roleId}`);
  }
  return role.description;
}
