import { Notif } from "../../types/notif";
import TitledSection from "../../components/TitledSection";
import { useEffect, useState, useContext } from "react";
import { Button, capitalize, Typography } from "@material-ui/core";
import { ReadApplication } from "../../types/applicationsCRUD";
import { getApplications, revokeApplication } from "../../services/api_services";
import { AuthContext } from "../../contexts/auth_context";
import { hasAuthorizations } from "../../utils/auth_util";
import { Authorizations } from "@unissey/utils";
import CreateApiKeyModal from "./CreateApiKey";
import EditApiKeyModal from "./EditApiKey";
import theme from "../../config/theme";
import { ConfirmDialogV2 as ConfirmDialog } from "../../components/ConfirmDialog";
import ShowApiKeyModal from "./ShowApiKey";
import { useScopedTranslation } from "../../i18n";
import DataTable, { DataTableColumn } from "../../components/DataTable";

type ApiKeysDisplay = {
  name: string;
  subscription: string;
  subKind: string;
  workspace: string;
  display: JSX.Element;
  editApiKeys: JSX.Element;
  revokeApiKeys: JSX.Element;
};

export default function ApiKeyPage({ onNotif }: { onNotif: Notif }) {
  const auth = useContext(AuthContext);
  const { t, tCommon } = useScopedTranslation("api_page");

  const [applications, setApplications] = useState<ReadApplication[]>();
  const [creationModalIsOpen, setCreationModalIsOpen] = useState<boolean>(false);
  const [editionModalIsOpen, setEditionModalIsOpen] = useState<boolean>(false);
  const [apiKeyToEdit, setApiKeyToEdit] = useState<{ id: string; name: string }>();
  const [revokeModalIsOpen, setRevokeModalIsOpen] = useState<boolean>(false);
  const [apiKeyToRevoke, setApiKeyToRevoke] = useState<ReadApplication>();
  const [apiKeyModalIsOpen, setApiKeyModalIsOpen] = useState<boolean>(false);
  const [apiKeyToShow, setApiKeyToShow] = useState<ReadApplication>();

  const [isLoading, setIsLoading] = useState(true);

  const canEditApiKeys = hasAuthorizations(auth, [Authorizations.APIKEY_EDIT]);
  const canViewApiKeySecrets = hasAuthorizations(auth, [Authorizations.APIKEY_SECRET]);

  useEffect(() => {
    const compareApplicationFn = (a: ReadApplication, b: ReadApplication) => {
      //  Sort by Workspace, then Subscription, then Environment, then Name
      let tmp = a.workspaceName.localeCompare(b.workspaceName);
      if (tmp !== 0) return tmp;
      tmp = a.subscriptionName.localeCompare(b.subscriptionName);
      if (tmp !== 0) return tmp;
      return a.name.localeCompare(b.name);
    };

    getApplications(onNotif, undefined, true).then((apps) => {
      const sortedApps = apps?.sort(compareApplicationFn);
      setApplications(sortedApps);
      setIsLoading(false);
    });
  }, [onNotif, revokeModalIsOpen, editionModalIsOpen, creationModalIsOpen]);

  async function revokeApiKey() {
    if (!apiKeyToRevoke) return;
    if (await revokeApplication(onNotif, apiKeyToRevoke.id))
      onNotif({
        textKey: "success.api_key_revoked",
        textParams: { appplicationName: apiKeyToRevoke.name },
      });
    setApiKeyToRevoke(undefined);
    setRevokeModalIsOpen(false);
  }

  const columns: DataTableColumn<ApiKeysDisplay>[] = [
    {
      dataKey: "name",
      title: t(`table.header_name`),
      sorter: (a, b) => (a.name < b.name ? -1 : 1),
      filter: {
        label: t(`table.header_name`),
        widget: "select",
        values: applications?.map((app) => capitalize(app.name)),
        lookup: "contains",
      },
    },
    {
      dataKey: "subscription",
      title: t(`table.header_subscription_name`),
      sorter: (a, b) => (a.subscription < b.subscription ? -1 : 1),
      filter: {
        label: t(`table.header_subscription_name`),
        widget: "select",
        values: Array.from(new Set(applications?.map((app) => capitalize(app.subscriptionName)))),
        lookup: "contains",
      },
    },
    {
      dataKey: "subKind",
      title: t(`table.header_subscription_kind`),
      sorter: (a, b) => (a.subscription < b.subscription ? -1 : 1),
      filter: {
        label: t(`table.header_subscription_kind`),
        widget: "select",
        values: Array.from(new Set(applications?.map((app) => tCommon(`sub_kind.${app.subscriptionKind}`)))),
        lookup: "contains",
      },
    },
    {
      dataKey: "workspace",
      title: t(`table.header_workspace`),
      sorter: (a, b) => (a.subscription < b.subscription ? -1 : 1),
      filter: {
        label: t(`table.header_workspace`),
        widget: "select",
        values: Array.from(new Set(applications?.map((app) => capitalize(app.workspaceName)))),
        lookup: "contains",
      },
    },
  ];

  if (canEditApiKeys)
    columns.push({ dataKey: "editApiKeys", title: "" }, { dataKey: "revokeApiKeys", title: "" });

  const displayApiKeys = (): ApiKeysDisplay[] => {
    return (
      applications?.map((app) => ({
        name: capitalize(app.name),
        subscription: capitalize(app.subscriptionName),
        subKind: tCommon(`sub_kind.${app.subscriptionKind}`),
        workspace: capitalize(app.workspaceName),
        display: app.isRevoked ? (
          <Typography style={{ color: theme.palette.grey["600"], paddingLeft: "14px" }}>
            {t("table.key_revoked")}
          </Typography>
        ) : (
          <Button
            onClick={() => {
              setApiKeyModalIsOpen(true);
              setApiKeyToShow(app);
            }}
            variant="outlined"
            disabled={!canViewApiKeySecrets}
          >
            {t("table.button_display")}
          </Button>
        ),
        editApiKeys: canEditApiKeys ? (
          <Button
            onClick={() => {
              setEditionModalIsOpen(true);
              setApiKeyToEdit({ id: app.id, name: app.name });
            }}
            variant="outlined"
          >
            {tCommon("button_edit")}
          </Button>
        ) : (
          <> </>
        ),
        revokeApiKeys: canEditApiKeys ? (
          <Button
            onClick={() => {
              setApiKeyToRevoke(app);
              setRevokeModalIsOpen(true);
            }}
            style={{ color: app.isRevoked ? undefined : theme.palette.error.main }}
            variant="outlined"
            disabled={app.isRevoked}
          >
            {t("table.button_revoke")}
          </Button>
        ) : (
          <> </>
        ),
      })) || []
    );
  };

  function CreateApiKeyButton() {
    if (!canEditApiKeys) return null;
    return (
      <Button variant="outlined" onClick={() => setCreationModalIsOpen(true)}>
        {t("button_create")}
      </Button>
    );
  }

  return (
    <>
      <TitledSection
        child={
          <DataTable
            columns={columns}
            dataSource={displayApiKeys()}
            isLoading={isLoading}
            noDataMsg={t("no_data")}
          />
        }
        action={<CreateApiKeyButton />}
      />

      {canEditApiKeys ? (
        <CreateApiKeyModal
          open={creationModalIsOpen}
          onClose={() => setCreationModalIsOpen(false)}
          onNotif={onNotif}
        />
      ) : null}

      {canEditApiKeys && apiKeyToRevoke ? (
        <ConfirmDialog
          open={revokeModalIsOpen}
          confirmText={apiKeyToRevoke.name}
          onClose={() => {
            setApiKeyToRevoke(undefined);
            setRevokeModalIsOpen(false);
          }}
          onConfirm={revokeApiKey}
        />
      ) : null}

      {canEditApiKeys && apiKeyToEdit ? (
        <EditApiKeyModal
          open={editionModalIsOpen}
          onClose={() => {
            setApiKeyToEdit(undefined);
            setEditionModalIsOpen(false);
          }}
          apiKeyFields={apiKeyToEdit}
          onNotif={onNotif}
        />
      ) : null}

      {canViewApiKeySecrets && apiKeyToShow ? (
        <ShowApiKeyModal
          open={apiKeyModalIsOpen}
          application={apiKeyToShow}
          onClose={() => {
            setApiKeyToShow(undefined);
            setApiKeyModalIsOpen(false);
          }}
          onNotif={onNotif}
        />
      ) : null}
    </>
  );
}
