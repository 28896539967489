import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
// constants
import { Routes } from "../../constants/routes";
// services
import StorageService from "../../services/storage_service";
import { isMobileDevice } from "../../utils/misc_util";
import FaceRounded from "@material-ui/icons/FaceRounded";
import WifiTetheringRounded from "@material-ui/icons/WifiTetheringRounded";
import theme from "../../config/theme";
import { useScopedTranslation } from "../../i18n";
import { DemoMode } from "../../types/demo";
import { InjectionSettings } from "./InjectionSettings";

const useStyles = makeStyles({
  formControlLabel: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[200]}`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 0,
  },
});

export default function TermsOfUsePage() {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box mt="2em">
      <TermsOfUse
        onDemoModeChosen={(mode) => {
          history.push({
            pathname: Routes.DEMO_CAPTURE,
            search: location.search,
            state: {
              faceMatching: mode === "liveness-and-face-comparison",
            },
          });
        }}
        availableModes={["liveness-and-face-comparison", "liveness-only"]}
      />
    </Box>
  );
}

type Props = {
  availableModes: DemoMode[];
  onDemoModeChosen: (mode: DemoMode) => void;
};

export function TermsOfUse({ availableModes, onDemoModeChosen }: Props) {
  const { t } = useScopedTranslation("demo_page");
  const classes = useStyles();

  const user = StorageService.getStoredUserAuth();

  const [termsOfUseChecked, setTermsOfUseChecked] = useState(user?.terms_of_use ?? false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(user?.privacy_policy ?? false);

  const canContinue = termsOfUseChecked && privacyPolicyChecked;

  const onContinue = (mode: DemoMode) => () => {
    if (!canContinue) return;
    // Note (Guillaume): That part of the logic is dubious. We only ever store 2 `true`s here
    // I'm really not sure what the original intent behind that feature was
    StorageService.storeTermsOfUse(termsOfUseChecked, privacyPolicyChecked);
    onDemoModeChosen(mode);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end" mr="1.5em">
        <InjectionSettings activated={true} user={user} />
      </Box>
      <div style={{ height: "100%", overflow: "auto", margin: "4px 4px" }}>
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="center"
          paddingTop={isMobileDevice() ? 8 : 2}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={8} lg={6} item>
              <Box display="flex" flexDirection="column" alignItems="stretch">
                <Typography align="center" variant="h6">
                  {t("title")}
                </Typography>

                <Box m={1} />

                <FormGroup row aria-required>
                  <Grid xs={12} item>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          checked={termsOfUseChecked}
                          onChange={(event) => setTermsOfUseChecked(event.target.checked)}
                          color="primary"
                        />
                      }
                      label={
                        <Trans
                          i18nKey="demo_page.terms_of_use"
                          components={[
                            <a href={t("terms_of_use_url")} target="_blank" rel="noopener noreferrer">
                              policy
                            </a>,
                          ]}
                        />
                      }
                    />
                    <Box m={1} />
                  </Grid>

                  <Grid xs={12} item>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          checked={privacyPolicyChecked}
                          onChange={(event) => setPrivacyPolicyChecked(event.target.checked)}
                          color="primary"
                        />
                      }
                      label={
                        <Trans
                          i18nKey="demo_page.privacy_policy"
                          components={[
                            <a href={t("privacy_policy_url")} target="_blank" rel="noopener noreferrer">
                              policy
                            </a>,
                          ]}
                        />
                      }
                    />
                    <Box m={1} />
                  </Grid>
                </FormGroup>

                <Box m={1} />

                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection={isMobileDevice() ? "column" : "row"}
                >
                  {availableModes.includes("liveness-and-face-comparison") && (
                    <Button
                      startIcon={<FaceRounded />}
                      color="primary"
                      variant="contained"
                      disableElevation
                      disabled={!canContinue}
                      onClick={onContinue("liveness-and-face-comparison")}
                    >
                      {t("liveness_plus_face_comparison")}
                    </Button>
                  )}
                  <Box m={1} />
                  {availableModes.includes("liveness-only") && (
                    <Button
                      startIcon={<WifiTetheringRounded />}
                      color="primary"
                      variant="contained"
                      disableElevation
                      disabled={!canContinue}
                      onClick={onContinue("liveness-only")}
                    >
                      {t("liveness")}
                    </Button>
                  )}
                </Box>
                <Box m={4} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Box>
  );
}
