import { Box, Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import { useState } from "react";
import { Notif } from "../../types/notif";
import { ModalV2 as Modal } from "../../components/Modal";
import { EditApplication } from "../../types/applicationsCRUD";
import { editApplication } from "../../services/api_services";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { has } from "../../types/utils";
import { useScopedTranslation } from "../../i18n";
import { isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  editTextField: {
    width: isMobile ? "95%" : "500px",
  },
});

type Props = DialogProps & {
  onClose: () => void;
  onNotif: Notif;
  apiKeyFields: { id: string; name: string };
};

export default function EditApiKeyModal({ open, onClose, onNotif, apiKeyFields }: Props) {
  const { t, tCommon } = useScopedTranslation("api_page.edit_dialog");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiKeyForm, setApiKeyForm] = useState<EditApplication>({ name: apiKeyFields.name, referers: [] });

  const classes = useStyles();

  async function editApiKey() {
    setIsLoading(true);
    if (!has(apiKeyForm, "name")) {
      onNotif({
        textKey: "error.api_key_edit_missing_fields",
      });
      setIsLoading(false);
      return;
    }
    if (await editApplication(onNotif, apiKeyFields.id, apiKeyForm))
      onNotif({
        textKey: "success.api_key_edited",
        textParams: { applicationName: apiKeyForm.name },
      });
    onClose();
    setIsLoading(false);
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      body={
        <FormGroup>
          <Box py={2}>
            <Grid container spacing={2}>
              <CustomTextField
                name="applicationName"
                label={t("field_application_name")}
                width="auto"
                className={classes.editTextField}
                value={apiKeyForm.name}
                autoFocus
                onChange={(e) => setApiKeyForm({ ...apiKeyForm, name: e.target.value })}
                onBlur={(e) => setApiKeyForm({ ...apiKeyForm, name: e.target.value.trim() })}
              />
            </Grid>
          </Box>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={editApiKey}
          startIcon={isLoading && <CircularProgress size={14} />}
          disabled={isLoading}
          fullWidth
        >
          {tCommon("button_edit")}
        </Button>
      }
    />
  );
}
