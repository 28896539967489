import { config } from "../constants/env";
import {
  ReadApplication,
  CreateApplication,
  EditApplication,
  ApplicationEnv,
  Application,
} from "../types/applicationsCRUD";
// sessions
import { Session, SessionAnnotation, SimplifiedSession } from "../types/session";
// types
import { CreateUser, ReadUser, Role, UpdateUser, User } from "../types/userCRUD";
import { CreateWorkspace, ReadWorkspace, UpdateWorkspaceType } from "../types/workspaceCRUD";
// utils
import StorageServices from "./storage_service";
import {
  CreateSubscription,
  GetSubscriptionResponse,
  Subscription,
  SubscriptionFields,
  UpdateSubscription,
} from "../types/subscriptionsCRUD";
import { Notif } from "../types/notif";
import { ErrorCode, ERROR_CODES, FIELD_CODES } from "@unissey/utils";
import { FaceComparisonResult, LivenessResult } from "../types/results";
import {
  CreateTemporaryUserResponse,
  GetTemporaryUserResponse,
  TemporaryDemoUsageReponse,
  TemporaryUserFormData,
} from "../types/temporaryUserCRUD";

async function safeApiCall(apiCallFunction: Promise<Response>, onNotif: Notif | false): Promise<any> {
  const response = await apiCallFunction;
  let responseJSON;
  try {
    const rawBody = await response.text();
    // NOTE (Martin Barreau): response.json() will throw if we try to parse an empty HTTP body response
    // this situation will happen when for instance we receive a 204 HTTP response (No content HTTP response)
    // since response.json() (https://github.com/github/fetch/blob/master/fetch.js#L324)
    // doesn't do a check before calling JSON.parse we need to do it on our own
    responseJSON = rawBody ? JSON.parse(rawBody).data : {};
  } catch (error) {
    if (onNotif)
      onNotif({
        textKey: `error.code_${ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION}`,
      });
    return undefined;
  }
  if (!response.ok) {
    let errorCode: typeof ERROR_CODES[number] = responseJSON.errorCode;
    if (!ERROR_CODES.includes(errorCode)) {
      errorCode = ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION;
    }

    let errorFields = undefined;
    const fields: typeof FIELD_CODES = responseJSON.data?.fields;
    if (Array.isArray(fields) && fields.every((f) => FIELD_CODES.includes(f))) {
      errorFields = fields;
    }

    if (onNotif) onNotif({ textKey: `error.code_${errorCode}`, errorFields });
    else throw Error(`Unhandled error: ${errorCode}`);
    return undefined;
  }
  return responseJSON;
}

export async function customFetch(
  method: string,
  url: string,
  onNotif?: Notif | false,
  body?: any,
  authToken: boolean | string = true
): Promise<any> {
  // Setup Fetch Parameters
  const params: RequestInit = { method: method, headers: { "Content-Type": "application/json" } };

  if (authToken) {
    const token = typeof authToken === "string" ? authToken : StorageServices.getStoredUserAuth()?.token;
    params.headers = { ...params.headers, Authorization: `Bearer ${token}` };
  }

  if (body) params.body = JSON.stringify(body);
  // Fetch with the given url, check for errors before returning response
  return safeApiCall(fetch(config.apiAdminUrl + url, params), onNotif ?? false);
}

export type SessionSortableField =
  | "date"
  | "workspace"
  | "application"
  | "subscription"
  | "liveness"
  | "face_comparison"
  | "attempts"
  | "status";
export type SessionFieldOrder = { field: SessionSortableField; order: "asc" | "desc" };

export async function getSessions(
  onNotif: Notif,
  page: number,
  pageSize = 100,
  [afterDate, beforeDate]: [Date | null, Date | null] = [null, null],
  workspaceIds: string[] = [],
  applicationIds: string[] = [],
  subscriptionTypes: string[] = [],
  livenessResults: (LivenessResult | undefined)[] = [],
  faceComparisonResults: (FaceComparisonResult | undefined)[] = [],
  attempts: ("1" | "2" | "3+")[] = [],
  syncStatus: "synchronized" | "unsynchronized" | "any" = "any",
  orderBy: SessionFieldOrder[] = [],
  gdprConsentStatus: boolean[] = []
): Promise<SimplifiedSession[] | undefined> {
  const endpoint = "/sessions";
  const params = new URLSearchParams({
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  const addArrayParam = (name: string, array: string[]) => {
    if (array.length > 0) params.append(name, array.join(","));
  };

  if (afterDate) params.append("after_date", afterDate.toISOString());
  if (beforeDate) params.append("before_date", beforeDate.toISOString());
  addArrayParam("workspace_ids", workspaceIds);
  addArrayParam("application_ids", applicationIds);
  addArrayParam("subscription_types", subscriptionTypes);
  addArrayParam(
    "liveness_results",
    livenessResults.map((r) => r ?? "n/a")
  );
  addArrayParam(
    "face_comparison_results",
    faceComparisonResults.map((r) => r ?? "n/a")
  );
  addArrayParam("attempts", attempts);
  params.append("sync_status", syncStatus);

  addArrayParam(
    "order_by",
    orderBy.map(({ field, order }) => `${field}:${order}`)
  );

  addArrayParam(
    "gdpr_consent",
    gdprConsentStatus.map((s) => (s ? "true" : "false"))
  );

  return customFetch("GET", `${endpoint}?${params}`, onNotif);
}

export async function getSession(onNotif: Notif | false, sessionId: string): Promise<Session | undefined> {
  const session: Session = await customFetch("GET", `/sessions/${sessionId}`, onNotif);
  if (session) {
    session.children = await Promise.all(
      session.childrenIDs.map((id) => customFetch("GET", `/sessions/${id}`, onNotif))
    );
  }
  return session;
}

export async function syncSession(onNotif: Notif, sessionID: string, sync: number) {
  return customFetch("PUT", `/sessions/sync/${sessionID}`, onNotif, { sync: sync });
}

export async function annotateSession(onNotif: Notif, annot: SessionAnnotation, sessionID: string) {
  return customFetch("PUT", `/sessions/annotations/${sessionID}`, onNotif, annot);
}

export async function setSessionUserId(onNotif: Notif, bundleId: string, userId: string) {
  return customFetch("PATCH", `/sessions/set-user/${bundleId}`, onNotif, { userId });
}

export async function getUsers(onNotif: Notif): Promise<ReadUser[] | undefined> {
  return customFetch("GET", "/users", onNotif);
}

export async function getApplications(
  onNotif: Notif | false,
  workspaceIds?: string[],
  excludeDemo?: boolean,
  token?: string
): Promise<ReadApplication[] | undefined> {
  const searchParams = new URLSearchParams();
  if (excludeDemo) searchParams.append("excludeDemo", excludeDemo.toString());
  if (workspaceIds) searchParams.append("workspaceIds", workspaceIds.join(","));
  return customFetch("GET", "/applications?" + searchParams.toString(), onNotif, false, token);
}

export async function getDemoApplications(
  environment: ApplicationEnv,
  onNotif: Notif | false
): Promise<Application[]> {
  return customFetch("GET", `/applications/${environment}/demo`, onNotif);
}

export async function getApiKeySecret(onNotif: Notif, applicationId: string): Promise<string | undefined> {
  return (await customFetch("GET", `/applications/${applicationId}/secret`, onNotif))?.key;
}

export async function createApplication(
  onNotif: Notif,
  fields: Required<CreateApplication>
): Promise<boolean | undefined> {
  return await customFetch("POST", "/applications", onNotif, fields);
}

export async function editApplication(
  onNotif: Notif,
  id: string,
  fields: Required<EditApplication>
): Promise<boolean | undefined> {
  return await customFetch("PUT", `/applications/${id}`, onNotif, fields);
}

export async function revokeApplication(onNotif: Notif, id: string): Promise<boolean | undefined> {
  return await customFetch("DELETE", `/applications/${id}`, onNotif);
}

export async function getWorkspaces(
  onNotif: Notif,
  directChildren?: boolean
): Promise<ReadWorkspace[] | undefined> {
  let url = "/workspaces";
  if (directChildren) url += `?directChildren=true`;
  return customFetch("GET", url, onNotif);
}

export async function updateWorkspace(
  onNotif: Notif,
  ws: UpdateWorkspaceType,
  wsId: string
): Promise<boolean | undefined> {
  return await customFetch("PUT", `/workspaces/${wsId}`, onNotif, ws);
}

export async function createWorkspace(onNotif: Notif, ws: CreateWorkspace): Promise<boolean | undefined> {
  return await customFetch("POST", "/workspaces", onNotif, ws);
}

export async function deleteWorkspace(onNotif: Notif, wsId: string): Promise<boolean | undefined> {
  return await customFetch("DELETE", `/workspaces/${wsId}`, onNotif);
}

export async function createUser(onNotif: Notif, user: CreateUser): Promise<boolean | undefined> {
  return await customFetch("POST", "/users", onNotif, user);
}

export async function updateUser(
  onNotif: Notif,
  user: UpdateUser,
  userId: string
): Promise<User | undefined> {
  return await customFetch("PUT", `/users/${userId}`, onNotif, user);
}

export async function updateRoles(
  onNotif: Notif,
  roleIDs: string,
  userID: string
): Promise<{ updatedUser: string } | undefined> {
  return await customFetch("PUT", `/users/${userID}/roles`, onNotif, { roleIDs });
}

export async function updateUserPassword(
  onNotif: Notif,
  oldPassword: string,
  newPassword: string
): Promise<boolean | undefined> {
  return await customFetch("PUT", "/users/password", onNotif, { oldPassword, newPassword });
}

export async function deleteUser(onNotif: Notif, userID: string): Promise<boolean | undefined> {
  return await customFetch("DELETE", `/users/${userID}`, onNotif);
}

export async function getRoles(
  targetWorkspaceId?: string,
  onlyGenerics?: boolean
): Promise<Role[] | undefined> {
  const searchParams = new URLSearchParams();
  if (targetWorkspaceId) searchParams.append("targetWorkspaceId", targetWorkspaceId);
  if (onlyGenerics) searchParams.append("genericRoles", "true");
  return (await customFetch("GET", "/users/roles?" + searchParams.toString())).roles;
}

function setSubscriptionDateTypes(subscription: Subscription): Subscription {
  subscription.startDate = new Date(subscription.startDate);
  subscription.endDate = subscription.endDate ? new Date(subscription.endDate) : undefined;
  return subscription;
}

export async function getSubscriptions(
  onNotif: Notif,
  excludeKind?: string,
  isActive?: boolean
): Promise<Subscription[] | undefined> {
  const searchParams = new URLSearchParams();
  if (excludeKind) searchParams.append("excludeKind", excludeKind);
  if (isActive !== undefined) searchParams.append("isActive", isActive.toString());
  const subscriptions = await customFetch("GET", "/subscriptions?" + searchParams.toString(), onNotif);
  return subscriptions ? subscriptions.map(setSubscriptionDateTypes) : undefined;
}

export async function getSubscription(
  onNotif: Notif,
  id: number
): Promise<GetSubscriptionResponse | undefined> {
  let sub = await customFetch("GET", `/subscriptions/${id}`, onNotif);
  if (sub) sub.subscription = setSubscriptionDateTypes(sub.subscription);
  return sub;
}

function parseDates(reqBody: SubscriptionFields): { startDate: string; endDate: string | undefined } {
  const startDate = reqBody.startDate.toISOString();
  const endDate = reqBody.endDate ? reqBody.endDate.toISOString() : undefined;
  return { startDate, endDate };
}

export async function createSubscription(
  onNotif: Notif,
  reqBody: CreateSubscription
): Promise<boolean | undefined> {
  const { startDate, endDate } = parseDates(reqBody);
  return await customFetch("POST", `/subscriptions`, onNotif, { ...reqBody, startDate, endDate });
}

export async function updateSubscription(
  onNotif: Notif,
  reqBody: UpdateSubscription
): Promise<boolean | undefined> {
  const body = { ...reqBody, ...parseDates(reqBody) };
  return await customFetch("PUT", `/subscriptions/${reqBody.id}`, onNotif, body);
}

export async function deleteSubscription(onNotif: Notif, id: number): Promise<boolean | undefined> {
  return await customFetch("DELETE", `/subscriptions/${id}`, onNotif);
}

export async function createTemporaryUser(
  reqBody: TemporaryUserFormData,
  onNotif: Notif
): Promise<CreateTemporaryUserResponse | undefined> {
  return await customFetch("POST", "/temporary_user", onNotif, reqBody);
}

export async function getTemporaryUser(userId: string): Promise<GetTemporaryUserResponse> {
  return await customFetch("Get", `/temporary_user/${userId}`);
}

export async function activateTemporaryUser(userId: string): Promise<GetTemporaryUserResponse> {
  return await customFetch("Get", `/temporary_user/activate/${userId}`);
}

export function sendTemporaryUserInvitation(userId: string, firstName: string, lastName: string) {
  return customFetch("POST", "/temporary_user/send_invite", false, { id: userId, firstName, lastName });
}

export async function addPersonalDataAuthException(onNotif: Notif, userId: string): Promise<void> {
  return await customFetch("POST", `/users/${userId}/personal-data`, onNotif);
}

export async function removePersonalDataAuthException(onNotif: Notif, userId: string): Promise<void> {
  return await customFetch("DELETE", `/users/${userId}/personal-data`, onNotif);
}

export async function getTemporaryDemoUsage(
  onNotif: Notif,
  start: Date,
  end?: Date
): Promise<TemporaryDemoUsageReponse> {
  const searchParams = new URLSearchParams({
    start: start.toISOString(),
  });
  if (end) searchParams.append("end", end.toISOString());
  return await customFetch("GET", `/temporary_user/usage?${searchParams.toString()}`, onNotif);
}
