import { Button, CircularProgress, DialogProps, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

import { ModalV2 as Modal } from "../../components/Modal";
import SubscriptionForm from "./SubscriptionForm";

import { createSubscription, getWorkspaces } from "../../services/api_services";
import { Notif } from "../../types/notif";
import { useScopedTranslation } from "../../i18n";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CreateSubscriptionForm } from "../../stores/forms";
import { toJS } from "mobx";
import { ReadWorkspace } from "../../types/workspaceCRUD";

type Props = DialogProps & {
  onClose: () => void;
  onNotif: Notif;
};

export function CreateSubscriptionModal({ open, onClose, onNotif }: Props) {
  const { t } = useScopedTranslation("subscription_page");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<ReadWorkspace[]>([]);
  const form = useLocalObservable(() => new CreateSubscriptionForm());

  async function handleCreate() {
    if (!form.validate()) return;

    setIsLoading(true);
    if (await createSubscription(onNotif, toJS(form.data))) {
      onClose();
      onNotif({ textKey: "success.subscription_created" });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getWorkspaces(onNotif, true).then((workspaces) => setWorkspaces(workspaces ?? []));
  }, [onNotif]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        onCancel={onClose}
        maxWidth="md"
        title={t("create_dialog.title")}
        body={<SubscriptionForm kind="create" form={form} workspaces={workspaces} />}
        action={
          <div>
            <Grid container>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCreate}
                startIcon={isLoading && <CircularProgress size={14} />}
                disabled={isLoading || !form.isValid}
                fullWidth
              >
                {t("create_dialog.button_create")}
              </Button>
            </Grid>
          </div>
        }
      />
    </>
  );
}

export default observer(CreateSubscriptionModal);
