import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HelpIcon from "@material-ui/icons/Help";
import { useEffect, useState } from "react";

import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { useScopedTranslation } from "../../i18n";
import { getRoles } from "../../services/api_services";
import { Role, UpdateUserExtended } from "../../types/userCRUD";
import { translatedRoleDescription, translatedRoleName } from "../../utils/i18n";

export type ExceptionEditStatus = "none" | "add" | "delete";

type Props = {
  userToEdit: UpdateUserExtended;
  targetWorkspaceId?: string;
  onFieldChanged: <K extends keyof UpdateUserExtended>(field: K, value: UpdateUserExtended[K]) => void;
  onPersonalDataChange: (status: ExceptionEditStatus) => void;
};

export default function UpdateTeamMemberForm({
  userToEdit,
  onFieldChanged,
  onPersonalDataChange,
  targetWorkspaceId,
}: Props) {
  const hasGdpr = userToEdit.exceptions?.some((x) => x.id === "404") ?? false;
  const { t, tCommon } = useScopedTranslation("workspace_page.update_member");
  const [roles, setRoles] = useState<Role[]>();
  const [roleIdsByName, setRoleIdsByName] = useState<Record<string, string>>({});
  const [gdpr, setGdpr] = useState<boolean>(hasGdpr);
  const [initialHasGdprAuthException] = useState<boolean>(hasGdpr);

  function roleSelectorRenderValue(): string {
    const role = roles?.find((r) => userToEdit.roleIds === r.id);
    if (role !== undefined) return translatedRoleName(role, tCommon);

    return "";
  }

  useEffect(() => {
    (async () => {
      const rIdsByName: typeof roleIdsByName = {};
      const r = await getRoles(targetWorkspaceId);
      setRoles(r?.sort((a, b) => a.name.localeCompare(b.name)));
      r?.forEach((role) => {
        if (role.name === "Administrator") {
          rIdsByName[role.name] = role.id;
        }
        setRoleIdsByName(rIdsByName);
      });
    })();
  }, [targetWorkspaceId]);

  return (
    <FormGroup>
      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={6}
            name={"firstName"}
            label={t("fields.firstName")}
            value={userToEdit.firstName}
            onChange={(e) => onFieldChanged("firstName", e.target.value)}
            onBlur={(e) => onFieldChanged("firstName", e.target.value.trim())}
          />
          <CustomTextField
            width={6}
            name={"lastName"}
            label={t("fields.lastName")}
            value={userToEdit.lastName}
            onChange={(e) => onFieldChanged("lastName", e.target.value)}
            onBlur={(e) => onFieldChanged("lastName", e.target.value.trim())}
          />
        </Grid>
      </Box>
      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={6}
            name={"email"}
            label={t("fields.email")}
            value={userToEdit.email}
            onChange={(e) => onFieldChanged("email", e.target.value)}
            onBlur={(e) => onFieldChanged("email", e.target.value.trim())}
          />
          <Grid item xs={6}>
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="select-role-label">{t("fields.role")}</InputLabel>
              <Select
                labelId="select-role-label"
                label={t("fields.role")}
                value={userToEdit.roleIds ?? ""}
                renderValue={roleSelectorRenderValue}
                MenuProps={{
                  variant: "menu",
                  getContentAnchorEl: null,
                }}
                onChange={(e) => onFieldChanged("roleIds", e.target.value as string)}
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem key="placeholder" style={{ display: "none" }} />
                {roles ? (
                  roles.map((role, idx) => (
                    <MenuItem dense value={role.id} key={idx}>
                      <ListItemText primary={translatedRoleName(role, tCommon)} />
                      <ListItemIcon>
                        <Tooltip
                          title={<Typography>{translatedRoleDescription(role, tCommon)}</Typography>}
                          interactive
                        >
                          <HelpIcon />
                        </Tooltip>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                ) : (
                  <Typography variant={"h6"} align={"center"}>
                    {tCommon("loading")}
                  </Typography>
                )}
              </Select>
            </FormControl>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setGdpr(e.target.checked);
                  if (e.target.checked) {
                    onPersonalDataChange(initialHasGdprAuthException ? "none" : "add");
                  } else {
                    onPersonalDataChange(!initialHasGdprAuthException ? "none" : "delete");
                  }
                }}
                color="primary"
                checked={gdpr}
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="personal-data-reader"
              />
            }
            label={
              <Typography>
                <b>{t("fields.personalDataReader")}</b>
                <br /> {t("fields.personalDataReaderSubMsg")}
              </Typography>
            }
            labelPlacement="start"
          />
        </Grid>
      </Box>
    </FormGroup>
  );
}
