import {
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../config/theme";
import clsx from "clsx";

const useStyles = makeStyles({
  headCell: {
    outlineBottom: `20px solid white`,
    padding: `${theme.spacing(1)}px 0`,
  },
  headCellChild: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    padding: `0 ${theme.spacing(2)}px`,
  },
  onHover: { "&:hover": { backgroundColor: `${theme.palette.grey[300]} !important` } },
  rowStickyHeader: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: "sticky",
    verticalAlign: "super",
  },
  tableStickyHeader: {
    borderCollapse: "collapse",
  },
  fixedLayout: {
    tableLayout: "fixed",
  },
});

type Row = JSX.Element[] | { rowStyle: React.CSSProperties; elements: JSX.Element[] };

interface Props {
  rows: Row[] | undefined;
  heads?: (string | JSX.Element)[] | undefined;

  isLoading?: boolean; // displays a loading circle when set to true

  // onScroll and height work together when you want to dynamically display values (see SessionsPage.tsx)
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  height?: number | undefined;

  // optional onClick feature using the the index of the clicked row
  rowOnClick?: (index: number) => void;
  // The index of prop rows that should be highlighted
  selectedRowIndex?: number;
  // If true, the row height will be 50px
  setMinHeight?: boolean;
  // If true, the last column will be aligned to the right
  lastColAlignRight?: boolean;

  widths?: (number | string)[];

  noEvenOddColoring?: boolean;
  fixedLayout?: boolean;

  noDataMsg?: string;
}

export default function CustomTable({
  rows,
  heads,
  isLoading,
  onScroll,
  height,
  rowOnClick,
  selectedRowIndex,
  setMinHeight,
  lastColAlignRight,
  widths,
  noEvenOddColoring,
  fixedLayout,
  noDataMsg,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={0} onScroll={onScroll} style={{ maxHeight: height }}>
      <Table
        component="div"
        aria-label="simple table"
        stickyHeader
        classes={{ stickyHeader: clsx(classes.tableStickyHeader, fixedLayout && classes.fixedLayout) }}
      >
        {heads && (
          <TableHead component="div">
            <TableRow component="div">
              {heads.map((head, i) => (
                <TableCell
                  component="div"
                  key={i}
                  className={classes.headCell}
                  classes={{ stickyHeader: classes.rowStickyHeader }}
                >
                  {typeof head === "string" ? <div className={classes.headCellChild}>{head}</div> : head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody component="div">
          {rows?.length === 0 && !isLoading && <Typography>{noDataMsg ?? t("no_data")}</Typography>}
          {rows?.map((row, i) => {
            const [elements, rowStyle] = Array.isArray(row) ? [row, {}] : [row.elements, row.rowStyle];

            return (
              <TableRow
                component="div"
                key={i}
                hover={rowOnClick ? true : undefined}
                classes={{ hover: classes.onHover }}
                style={{
                  backgroundColor:
                    selectedRowIndex && i === selectedRowIndex
                      ? theme.palette.primary.main
                      : i % 2 === 0 && !noEvenOddColoring
                      ? "#F3F5F880"
                      : undefined,
                  cursor: rowOnClick && "pointer",
                  height: setMinHeight ? "50px" : undefined,
                  border: "1px solid #e9ecef",
                  borderWidth: "0 0 1px 0",
                  ...rowStyle,
                }}
                onClick={() => rowOnClick?.(i)}
              >
                {elements.map((cell, j) => (
                  <TableCell
                    key={j}
                    style={{
                      color: j !== 0 ? theme.palette.grey[600] : undefined,
                      padding: "8px",
                      maxWidth: "300px",
                      width: widths?.[j],
                    }}
                    component="div"
                    scope="row"
                    align={j === elements.length - 1 && lastColAlignRight ? "right" : undefined}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          {isLoading && (
            <TableRow component="div">
              <TableCell component="div" colSpan={7} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
