import { useEffect, useState } from "react";
import { Box, DialogProps } from "@material-ui/core";

import { ModalV2 as Modal } from "../../components/Modal";

import { Notif } from "../../types/notif";
import { TemporaryDemoUsageReponse } from "../../types/temporaryUserCRUD";
import { getTemporaryDemoUsage } from "../../services/api_services";

import { useScopedTranslation } from "../../i18n";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CustomDateTimePicker from "../../components/forms/formComponents/CustomDateTimePicker";
import DateFnsUtils from "@date-io/date-fns";
import DataTable from "../../components/DataTable";

type Props = DialogProps & {
  onClose: () => void;
  onNotif: Notif;
};

export default function TemporaryExtractModal({ open, onClose, onNotif }: Props) {
  const { t } = useScopedTranslation("workspace_page.temp_demo_usage_dialog");

  const [usage, setUsage] = useState<TemporaryDemoUsageReponse>();
  const [start, setStart] = useState(() => {
    const now = new Date();
    now.setDate(now.getDate() - 80);
    return now;
  });
  const [end, setEnd] = useState<Date>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      getTemporaryDemoUsage(onNotif, start, end).then((data) => {
        setUsage(data);
        setIsLoading(false);
      });
    }
  }, [start, end, onNotif, setUsage, open]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      hideCancel={true}
      maxWidth="md"
      body={
        <Box>
          <Box display="flex">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomDateTimePicker
                value={start}
                onChange={(date) => {
                  if (date) {
                    setStart(new Date(date));
                  }
                }}
                label={t("field_start")}
                width="auto"
              />
            </MuiPickersUtilsProvider>
            <Box m={1} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomDateTimePicker
                value={end ?? null}
                onChange={(date) => {
                  setEnd(date === null ? undefined : new Date(date));
                }}
                label={t("field_end")}
                width="auto"
              />
            </MuiPickersUtilsProvider>
          </Box>

          <Box m={5}></Box>

          <Box>
            {usage ? (
              usage.metrics.entries.length === 0 ? (
                <>No data</>
              ) : (
                <DataTable
                  columns={[
                    {
                      dataKey: "email",
                      title: t("column_email"),
                      sorter: (a, b) => a.email.localeCompare(b.email),
                      filter: {
                        label: t(`filter_domain`),
                        widget: "select",
                        values: Array.from(
                          new Set(usage.metrics.entries.map(({ user }) => user.email.split("@")[1]))
                        ),
                        lookup: "substring",
                      },
                    },
                    {
                      dataKey: "createdAt",
                      title: t("column_created_at"),
                      sorter: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
                    },
                    {
                      dataKey: "activatedAt",
                      title: t("column_activated_at"),
                      sorter: (a, b) => {
                        if (!(a.activatedAt instanceof Date)) {
                          return -1;
                        } else if (!(b.activatedAt instanceof Date)) {
                          return 1;
                        } else {
                          return a.activatedAt.getTime() - b.activatedAt.getTime();
                        }
                      },
                    },
                    {
                      dataKey: "sessionCount",
                      title: t("column_session_count"),
                      sorter: (a, b) => (a.sessionCount < b.sessionCount ? -1 : 1),
                    },
                  ]}
                  dataSource={usage.metrics.entries.map((entry) => ({
                    email: entry.user.email,
                    createdAt: new Date(entry.user.createdAt),
                    activatedAt: entry.user.activatedAt
                      ? new Date(entry.user.activatedAt)
                      : t("never_activated"),
                    sessionCount: entry.sessionCount,
                  }))}
                  defaultSort={{
                    key: "createdAt",
                    order: "desc",
                  }}
                  isLoading={isLoading}
                />
              )
            ) : null}
          </Box>
        </Box>
      }
      action={<></>}
    />
  );
}
