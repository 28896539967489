import { action, computed, makeObservable, observable } from "mobx";

import { SubscriptionFields, SUBSCRIPTION_KINDS, UpdateSubscription } from "../../types/subscriptionsCRUD";

export class UpdateSubscriptionForm {
  constructor(public data: UpdateSubscription) {
    makeObservable(this, {
      data: observable,

      setField: action.bound,

      isValid: computed,
    });
  }

  setField<K extends keyof SubscriptionFields>(field: K, value: SubscriptionFields[K]) {
    (this.data as SubscriptionFields)[field] = value;
  }

  get isValid() {
    const nonEmptyName = this.data.name.length > 0;
    const validKind = SUBSCRIPTION_KINDS.includes(this.data.kind);
    const validDateRange = this.data.endDate === undefined ? true : this.data.startDate < this.data.endDate;
    const validLimit = this.data.maxSessionCount === undefined ? true : this.data.maxSessionCount >= 0;

    return nonEmptyName && validKind && validDateRange && validLimit;
  }
}
