import { useState } from "react";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { HeaderFilterMenu } from ".";
import { useEffect } from "react";

interface Item {
  label: string | React.ReactNode;
}

type Props<T> = {
  items: T[];
  initialSelectedIndices: Set<number>;
  onSelectionChanged: (newIndices: Set<number>) => void;
  textFilter?: {
    label: string;
  };
};

const useStyles = makeStyles({
  input: {
    height: "20px",
    boxSizing: "border-box",
  },
});

export function ListHeaderFilter<T extends Item>({
  items,
  initialSelectedIndices,
  onSelectionChanged,
  textFilter,
}: Props<T>) {
  const classes = useStyles();

  const [selectedIndices, setSelectedIndices] = useState(new Set(initialSelectedIndices));
  const [subFiltering, setSubFiltering] = useState("");

  useEffect(() => {
    setSelectedIndices(new Set(initialSelectedIndices));
  }, [initialSelectedIndices]);

  const displayedItems = items
    .map((item, idx) => {
      return [item, idx] as const;
    })
    .filter(([item, _]) => {
      if (typeof item.label === "string") {
        return item.label.toLocaleLowerCase().includes(subFiltering.toLowerCase());
      } else {
        return true;
      }
    });

  return (
    <HeaderFilterMenu
      onConfirm={() => onSelectionChanged(selectedIndices)}
      onCancel={() => setSelectedIndices(new Set(initialSelectedIndices))}
      onClearAll={() => setSelectedIndices(new Set())}
      onSelectAll={() => setSelectedIndices(new Set(displayedItems.map(([_, idx]) => idx)))}
      activeIndicator={initialSelectedIndices.size > 0 ? initialSelectedIndices.size.toString() : undefined}
    >
      <Box>
        {textFilter && (
          <>
            <TextField
              value={subFiltering}
              onChange={(e) => setSubFiltering(e.target.value)}
              label={textFilter.label}
              margin="dense"
              variant="outlined"
              fullWidth
            ></TextField>
            <Box m={1} />
          </>
        )}
        <List style={{ maxHeight: "250px", maxWidth: "300px", overflowY: "auto" }}>
          {displayedItems.map(([item, idx]) => {
            return (
              <ListItem
                onClick={() => {
                  const newIndices = new Set(selectedIndices);
                  if (selectedIndices.has(idx)) newIndices.delete(idx);
                  else newIndices.add(idx);
                  setSelectedIndices(newIndices);
                }}
                dense
                disableGutters
                style={{ paddingRight: "8px" }}
              >
                <ListItemIcon style={{ minWidth: "unset" }}>
                  <Checkbox
                    edge="start"
                    checked={selectedIndices.has(idx)}
                    classes={{ root: classes.input }}
                    disableRipple
                  />
                </ListItemIcon>
                {typeof item.label === "string" ? (
                  <ListItemText primary={item.label} style={{ fontSize: "small", cursor: "pointer" }} />
                ) : (
                  <Box style={{ cursor: "pointer" }}>{item.label}</Box>
                )}
              </ListItem>
            );
          })}
        </List>
      </Box>
    </HeaderFilterMenu>
  );
}
