import { Link, Typography, TypographyTypeMap } from "@material-ui/core";

const Copyright = ({
  color,
  className,
}: {
  color?: TypographyTypeMap["props"]["color"];
  className?: string;
}) => (
  <Typography variant="caption" color={color ?? "textSecondary"} align="center" className={className}>
    {"Copyright © "}
    <Link color="inherit" href="https://unissey.com">
      Unissey
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

export default Copyright;
