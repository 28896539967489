// utils
import Keycloak, { KeycloakInitOptions, KeycloakInstance } from "keycloak-js";
// constants
import { config } from "../constants/env";
import { customFetch } from "./api_services";
import { SessionUser } from "../types/auth";
import { Notif } from "../types/notif";

interface DomainSSOData {
  idpHint: string;
  provider: string;
  workspaceName: string;
}
export default class AuthenticationService {
  static keycloak: KeycloakInstance;

  static async SSOinit(): Promise<void> {
    if (!!this.keycloak) return;
    this.keycloak = this.setupKeycloak();
    await this.initKeycloak();
  }

  static async signIn(email: string, password: string, onNotif: Notif): Promise<SessionUser | undefined> {
    return customFetch("POST", "/auth/login", onNotif, { username: email, password }, false);
  }

  static async getMe(token: string) {
    return customFetch("GET", "/users/me", false, false, token);
  }

  static async getWorkspace(token: string) {
    return customFetch("GET", "/workspaces/me", false, false, token);
  }

  static async getDemoApiKey(token: string) {
    return (await customFetch("GET", "/applications/demo_web/secret", false, false, token)).key;
  }

  static async refreshToken(refresh_token: string) {
    return customFetch("PUT", "/auth/refresh", false, { refresh_token });
  }

  static async resetPasswordStep2(token: string, password: string): Promise<boolean | undefined> {
    return customFetch("PUT", "/users/reset-password/step2", false, { newPassword: password, token }, false);
  }

  static async resetPasswordStep1(email: string) {
    return customFetch("POST", "/users/reset-password", false, { email }, false);
  }

  static async loginSSO(token: string): Promise<SessionUser> {
    //"Error during sso sign in, please use your password or try again later."
    return customFetch("POST", "/auth/login-sso", false, { token }, false);
  }

  static async generateSSOLinkData(domain: string): Promise<(DomainSSOData & { link: string }) | null> {
    const ssoData = await this.getDomainSSOData(domain);
    if (!ssoData) return null;
    return {
      ...ssoData,
      link: this.generateSSOLinkWithKeycloak(ssoData?.idpHint),
    };
  }

  static generateSSOLinkWithKeycloak(hint: string) {
    return this.keycloak.createLoginUrl({ idpHint: hint, prompt: "select_account" } as any);
  }

  private static async getDomainSSOData(domain: string): Promise<DomainSSOData | null> {
    // put true domain sso data resolution
    if (domain.match(".*thedeepsense.co"))
      // NOTE (Guillaume): ⚠️ Name change
      return {
        workspaceName: "Deepsense",
        provider: "Deepsense",
        idpHint: "google",
      };
    return null;
  }

  static isSSOCallback(location: { search: string }): Boolean {
    const q = new URLSearchParams(location.search);
    return !!q.get("code");
  }

  static getGrantFromKeycloak(): { token: string; refresh_token: string } {
    if (!this.keycloak)
      throw Error(
        "AuthenticationService:getGrantFromKeycloak:Auth service should have been initialized first !"
      );

    const grant = {
      token: this.keycloak?.token,
      refresh_token: this.keycloak?.refreshToken,
    };

    if (!grant.token || !grant.refresh_token)
      throw Error("AuthenticationService:getGrantFromKeycloak:No valid grant found");
    return grant as { token: string; refresh_token: string }; // force the type
  }

  static SSOSignOut() {
    this.keycloak?.logout();
  }

  private static async initKeycloak() {
    const initOpts: KeycloakInitOptions = {
      flow: "standard",
      enableLogging: true,
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: "/",
      responseMode: "query",
      redirectUri: window.location.href,
    };

    await this.keycloak.init(initOpts);
    this.clearExpiredSSOToken();
  }

  private static setupKeycloak() {
    const keycloakOpts = {
      url: config.kcAuthUrl,
      realm: config.kcRealm,
      clientId: config.kcResource,
      // Note (Guillaume): Below are keys that do not seem to be supported
      // according to the typings…
      // They have always been there so I am note sure if they actually control
      // any kind of behavior…
      "public-client": true,
      "ssl-required": "external",
      "confidential-port": 0,
      resource: config.kcResource,
      "auth-server-url": config.kcAuthUrl,
    };

    return Keycloak(keycloakOpts);
  }

  private static clearExpiredSSOToken() {
    if (!this?.keycloak?.isTokenExpired(30)) {
      return;
    }
    this.keycloak.clearToken();
  }
}
