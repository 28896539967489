import { createContext, useState, FC, ReactNode } from "react";
// services
import StorageService from "../services/storage_service";
import { IAuthContextInterface, SessionUser } from "../types/auth";

export const AuthContext = createContext<IAuthContextInterface>({
  user: null,
  setAuthUser: () => {},
  unsetAuthUser: () => {},
});

function useAuth(initialState: SessionUser | null) {
  const [user, setUser] = useState(initialState);

  const setAuthUser = (userAuth: SessionUser) => {
    StorageService.storeUserAuth(userAuth);
    setUser(userAuth);
  };

  const unsetAuthUser = () => {
    StorageService.clearUserAuth();
    setUser(null);
  };

  return {
    user,
    setAuthUser,
    unsetAuthUser,
  };
}

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user, setAuthUser, unsetAuthUser } = useAuth(StorageService.getStoredUserAuth());
  return (
    <AuthContext.Provider value={{ user, setAuthUser, unsetAuthUser }}>
      <>{children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
