import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useScopedTranslation } from "../i18n";
import { CloseIcon } from "./CloseIcon";

import CustomTextField from "./forms/formComponents/CustomTextField";

type Props = {
  confirmText: string;
  onConfirm: CallableFunction;
};

const useStyles = makeStyles({
  actionBtn: {
    borderRadius: "7px",
    textTransform: "none",
  },

  centered: {
    textAlign: "center",
  },

  centeredGroupBtns: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  closeIconSize: {
    width: "50%",
  },

  warningBtn: {
    background: "#E47589",
    color: "#FFFFFF",
  },
});

export default function ConfirmDialog({ confirmText, onConfirm, open, onClose }: Props & DialogProps) {
  const { t, tCommon } = useScopedTranslation("strong_confirm_dialog");
  const [input, setInput] = useState<String>("");

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey="strong_confirm_dialog.content"
            values={{ confirmText }}
            components={[<strong>Confirmation Text</strong>]}
          />
        </DialogContentText>
        <CustomTextField
          autoFocus
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onBlur={(e) => setInput(e.target.value.trim())}
          label={t("field_confirm_text")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose!({}, "backdropClick")} color="primary">
          {tCommon("button_cancel")}
        </Button>
        <Button onClick={() => onConfirm()} disabled={confirmText !== input} color="primary">
          {tCommon("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// NOTE (Martin): Bringing Unissey's new style for confirm dialogs...
export function ConfirmDialogV2({ confirmText, onConfirm, open, onClose }: Props & DialogProps) {
  const classes = useStyles();
  const { t, tCommon } = useScopedTranslation("strong_confirm_dialog");
  const [input, setInput] = useState<string>("");

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.centered} id="form-dialog-title">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flexGrow={1}>
            <Typography align="center" variant="h6">
              <Trans i18nKey="strong_confirm_dialog.title" />
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={() => onClose!({}, "backdropClick")}>
              <CloseIcon className={classes.closeIconSize} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.centered}>
        <DialogContentText>
          <Trans
            i18nKey="strong_confirm_dialog.content"
            values={{ confirmText }}
            components={[<strong>Confirmation Text</strong>]}
          />
        </DialogContentText>
        <CustomTextField
          autoFocus
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onBlur={(e) => setInput(e.target.value.trim())}
          label={t("field_confirm_text")}
        />
      </DialogContent>
      <DialogActions className={classes.centeredGroupBtns}>
        <Button
          autoFocus
          variant="contained"
          className={classes.actionBtn}
          disableElevation
          onClick={() => onClose!({}, "backdropClick")}
        >
          {tCommon("button_cancel")}
        </Button>
        <Button
          variant="contained"
          className={[classes.actionBtn, classes.warningBtn].join(" ")}
          disableElevation
          onClick={() => onConfirm()}
          disabled={confirmText !== input}
        >
          {tCommon("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
