import { ErrorCode, FIELD_CODES } from "@unissey/utils";

import { config } from "../constants/env";
import StorageService from "../services/storage_service";

export class ApiFetchError extends Error {
  readonly fetchError: string;
  // FIXME: DS-177 using a better type instead of any
  readonly fieldsError?: any;

  constructor(message: string, fetchErr: string, fieldsError?: any) {
    super(message);
    this.fetchError = fetchErr;
    this.fieldsError = fieldsError;
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

const safeApiFetch = async <T = any>(apiCallResp: Promise<Response>): Promise<T> => {
  const response = await apiCallResp;
  let responseJSON;

  try {
    const rawBody = await response.text();
    responseJSON = rawBody ? JSON.parse(rawBody).data : {};
  } catch (error) {
    throw new ApiFetchError(error.message, `error.code_${ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION}`);
  }

  if (!response.ok) {
    let errMsg = `error.code_${responseJSON.errorCode ?? ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION}`;
    let errorFields = undefined;
    const fields: typeof FIELD_CODES = responseJSON.data?.fields;
    if (Array.isArray(fields) && fields.every((f) => FIELD_CODES.includes(f))) errorFields = fields;
    throw new ApiFetchError("Fetch error", errMsg, errorFields);
  }
  return responseJSON;
};

export const doFetch = async <T = any>(
  method: string,
  url: string,
  body?: unknown,
  authToken: boolean | string = true
): Promise<T> => {
  const params: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (authToken) {
    const token = typeof authToken === "string" ? authToken : StorageService.getStoredUserAuth()?.token;
    params.headers = { ...params.headers, Authorization: `Bearer ${token}` };
  }

  if (body) params.body = JSON.stringify(body);
  return safeApiFetch(fetch(config.apiAdminUrl + url, params));
};
