import { action, computed, makeObservable, observable } from "mobx";
import { CreateSubscription, SubscriptionFields, SUBSCRIPTION_KINDS } from "../../types/subscriptionsCRUD";

export class CreateSubscriptionForm {
  data: Partial<CreateSubscription> = {};

  constructor() {
    makeObservable(this, {
      data: observable,

      setField: action.bound,
      setWorkspaceId: action.bound,

      isValid: computed,
    });
  }

  setField<K extends keyof SubscriptionFields>(field: K, value: SubscriptionFields[K]) {
    this.data[field] = value;
  }

  setWorkspaceId(wsId: string) {
    this.data.workspaceId = wsId;
  }

  get isValid() {
    if (!this.data.workspaceId) return false;
    if (!this.data.name) return false;
    if (!this.data.kind) return false;
    if (!this.data.startDate) return false;

    const nonEmptyName = this.data.name.length > 0;
    const validKind = SUBSCRIPTION_KINDS.includes(this.data.kind);
    const validDateRange = this.data.endDate === undefined ? true : this.data.startDate < this.data.endDate;
    const validLimit = this.data.maxSessionCount === undefined ? true : this.data.maxSessionCount >= 0;

    return nonEmptyName && validKind && validDateRange && validLimit;
  }

  validate(): this is Omit<this, "data"> & { data: CreateSubscription } {
    return this.isValid;
  }
}
