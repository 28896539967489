import React, { useState, Dispatch, SetStateAction } from "react";
// types
import { SimplifiedSession } from "../../../types/session";
import CustomTable from "../../../components/CustomTable";
import { AuthContext } from "../../../contexts/auth_context";
import { hasAuthorizations } from "../../../utils/auth_util";
import { Authorizations } from "@unissey/utils";
import OveflowTooltip from "../../../components/OverflowTooltip";
import { LivenessChip, FaceComparisonChip } from "../../../components/SessionResultChip";
import { dateFormat } from "../../../utils/parsing";
import { Notif } from "../../../types/notif";
import { useScopedTranslation } from "../../../i18n";
import { Box, IconButton } from "@material-ui/core";

import { getApplications, getWorkspaces, SessionSortableField } from "../../../services/api_services";
import { ReadWorkspace } from "../../../types/workspaceCRUD";
import { ReadApplication } from "../../../types/applicationsCRUD";
import { useEffect } from "react";

import { Chip } from "@material-ui/core";
import theme from "../../../config/theme";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { DateHeaderFilter } from "../../../components/filters/DateFilter";
import { ListHeaderFilter } from "../../../components/filters/ListFilter";
import { FaceComparisonResult, LivenessResult } from "../../../types/results";
import { makeStyles } from "@material-ui/styles";
import { SubscriptionKind, SUBSCRIPTION_KINDS } from "../../../types/subscriptionsCRUD";
import StopIcon from "../../../components/StopIcon";
import OkIcon from "../../../components/OkIcon";

type Props = {
  sessions: SimplifiedSession[];
  isLoading: boolean;
  height: number | undefined;
  onScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onSessionIdSelected: (sessionId: string) => void;
  selectedSessionId: string | null;
  onNotif: Notif;
  sorting: SessionsSorting;
  onSorting: (_: SessionsSorting) => void;

  // TODO: state manager…
  selDateRange: [Date | null, Date | null];
  setSelDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  selWorkspaces: ReadWorkspace[];
  setSelWorkspaces: Dispatch<SetStateAction<ReadWorkspace[]>>;
  selApplications: ReadApplication[];
  setSelApplications: Dispatch<SetStateAction<ReadApplication[]>>;
  selSubscriptionTypes: SubscriptionKind[];
  setSelSubscriptionTypes: Dispatch<SetStateAction<SubscriptionKind[]>>;
  selLivenessResults: (LivenessResult | undefined)[];
  setSelLivenessResults: Dispatch<SetStateAction<(LivenessResult | undefined)[]>>;
  selFaceComparisonResults: (FaceComparisonResult | undefined)[];
  setSelFaceComparisonResults: Dispatch<SetStateAction<(FaceComparisonResult | undefined)[]>>;
  selAttempts: AttemptFilter[];
  setSelAttempts: Dispatch<SetStateAction<AttemptFilter[]>>;
  selSyncStatus: SyncStatusFilter[];
  setSelSyncStatus: Dispatch<SetStateAction<SyncStatusFilter[]>>;
  selGdprConsentStatus: boolean[];
  setSelGdprConsentStatus: Dispatch<SetStateAction<boolean[]>>;

  emptySessions: () => void;
};

export type SessionsSorting = { [K in SessionSortableField]?: SortOrder };

const LIVENESS_RESULTS: (LivenessResult | undefined)[] = ["genuine", "fail", undefined];
const FACE_COMPARISON_RESULTS: (FaceComparisonResult | undefined)[] = ["match", "mismatch", undefined];
const ATTEMPTS = ["1", "2", "3+"] as const;
const SYNC_STATUS = ["synchronized", "unsynchronized"] as const;

export type AttemptFilter = typeof ATTEMPTS[number];
export type SyncStatusFilter = typeof SYNC_STATUS[number];

export default function SessionsTable({
  sessions,
  isLoading,
  height,
  onScroll,
  onSessionIdSelected,
  selectedSessionId,
  onNotif,
  sorting,
  onSorting,
  selDateRange = [null, null],
  setSelDateRange,
  selWorkspaces,
  setSelWorkspaces,
  selApplications,
  setSelApplications,
  selSubscriptionTypes,
  setSelSubscriptionTypes,
  selLivenessResults,
  setSelLivenessResults,
  selFaceComparisonResults,
  setSelFaceComparisonResults,
  selAttempts,
  setSelAttempts,
  selSyncStatus,
  setSelSyncStatus,
  selGdprConsentStatus,
  setSelGdprConsentStatus,
  emptySessions,
}: Props) {
  const { t, tCommon } = useScopedTranslation("sessions_page.table");
  const auth = React.useContext(AuthContext);

  const canViewStatus = hasAuthorizations(auth, [Authorizations.SESSION_DATA_WRITE]);
  const canListWorkspaces = hasAuthorizations(auth, [Authorizations.WORKSPACE_LIST]);
  const canListApiKeys = hasAuthorizations(auth, [Authorizations.APIKEY_LIST]);

  const [workspaces, setWorkspaces] = useState<ReadWorkspace[]>([]);
  const [applications, setApplications] = useState<ReadApplication[]>([]);
  const [selectableApplications, setSelectableApplications] = useState<ReadApplication[]>([]);

  const onFieldOrderChanged = (field: SessionSortableField, order: SortOrder) => {
    // Prevent unsetting date sort
    if (field === "date" && order === undefined) order = "desc";

    let newSorting = { ...sorting, [field]: order };

    if (field !== "date") {
      const dateOrder = sorting.date ?? "desc";
      newSorting = { [field]: order, date: dateOrder };
    }

    onSorting(newSorting);
  };

  useEffect(() => {
    (async () => {
      let workspaceIds = [auth.user!.workspaceId];
      if (canListWorkspaces) {
        const workspaces = await getWorkspaces(onNotif);
        setWorkspaces((workspaces ?? []).sort((w1, w2) => w1.name.localeCompare(w2.name)));
        if (workspaces) workspaceIds = workspaces.map((w) => w.id);
      }
      if (canListApiKeys)
        setApplications(
          ((await getApplications(onNotif, workspaceIds)) ?? []).sort((a1, a2) =>
            a1.name.localeCompare(a2.name)
          )
        );
    })();
  }, [canListWorkspaces, onNotif, canListApiKeys, auth.user]);

  useEffect(() => {
    const filterApps = (app: ReadApplication) => selWorkspaces.some((ws) => ws.id === app.workspaceId);
    setSelectableApplications(applications.filter(filterApps));
    if (selWorkspaces.length > 0) {
      const newSelection = selApplications.filter(filterApps);
      if (newSelection.length !== selApplications.length) setSelApplications(newSelection);
    } else {
      setSelectableApplications(applications);
    }
  }, [selWorkspaces, selApplications, applications, setSelectableApplications, setSelApplications]);

  const sessionRows = sessions.map((s: SimplifiedSession) => {
    const row = [
      <OveflowTooltip maxWidth={200} text={dateFormat(s.createdAt)} />,
      <OveflowTooltip maxWidth={300} text={s.application.workspaceName} />,
      <OveflowTooltip maxWidth={300} text={s.application.name} />,
      <OveflowTooltip maxWidth={300} text={tCommon(`sub_kind.${s.subscriptionKind}`)} />,
      <LivenessChip value={s.liveness} />,
      <FaceComparisonChip value={s.faceComparison} />,
      <Box display="flex" justifyContent="center">
        <Chip label={(s.nRetry + 1).toString()} variant="outlined" style={{ width: "40px" }} />
      </Box>,
      <Box display="flex" justifyContent="center">
        {s.gdprConsent.toLowerCase() === "false" ? <StopIcon /> : <OkIcon />}
      </Box>,
    ];
    if (canViewStatus)
      row.push(
        <Chip
          label={t(`chip_${s.hasSynchronizedSession ? "synchronized" : "unsynchronized"}`)}
          variant="outlined"
          style={{
            borderColor: s.hasSynchronizedSession ? theme.palette.success.main : theme.palette.error.main,
          }}
        />
      );
    return row;
  });

  const headers = [
    <Box>
      <Header
        text={t(`header_date`)}
        sort={{
          order: sorting["date"],
          onOrderChanged: (sorting) => onFieldOrderChanged("date", sorting),
        }}
        filter={
          <DateHeaderFilter
            initialRange={{ after: selDateRange[0] ?? undefined, before: selDateRange[1] ?? undefined }}
            onRangeChanged={(range) => {
              emptySessions();
              setSelDateRange([range.after ?? null, range.before ?? null]);
            }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_workspace`)}
        sort={{
          order: sorting["workspace"],
          onOrderChanged: (sorting) => onFieldOrderChanged("workspace", sorting),
        }}
        filter={
          <ListHeaderFilter
            items={workspaces.map((ws) => ({ ...ws, label: ws.name }))}
            initialSelectedIndices={
              new Set(
                workspaces
                  .map((ws, idx) => {
                    if (selWorkspaces.find((w) => w.id === ws.id) !== undefined) return idx;
                    return undefined;
                  })
                  .filter((x): x is number => x !== undefined)
              )
            }
            onSelectionChanged={(s) => {
              emptySessions();
              setSelWorkspaces(Array.from(s.values()).map((v) => workspaces[v]));
            }}
            textFilter={{ label: tCommon("sessions_page.filters.label_filter_workspace") }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_application`)}
        sort={{
          order: sorting["application"],
          onOrderChanged: (sorting) => onFieldOrderChanged("application", sorting),
        }}
        filter={
          <ListHeaderFilter
            items={selectableApplications.map((app) => ({ ...app, label: app.name }))}
            initialSelectedIndices={
              new Set(
                selectableApplications
                  .map((a, idx) => {
                    if (selApplications.find((app) => app.id === a.id) !== undefined) return idx;
                    return undefined;
                  })
                  .filter((x): x is number => x !== undefined)
              )
            }
            onSelectionChanged={(s) => {
              emptySessions();
              setSelApplications(Array.from(s.values()).map((v) => selectableApplications[v]));
            }}
            textFilter={{ label: tCommon("sessions_page.filters.label_filter_applications") }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_subscription_type`)}
        sort={{
          order: sorting["subscription"],
          onOrderChanged: (sorting) => onFieldOrderChanged("subscription", sorting),
        }}
        filter={
          <ListHeaderFilter
            items={SUBSCRIPTION_KINDS.map((kind) => ({ kind, label: tCommon(`sub_kind.${kind}`) }))}
            initialSelectedIndices={
              new Set(
                SUBSCRIPTION_KINDS.map((s, idx) => {
                  if (selSubscriptionTypes.includes(s)) return idx;
                  return undefined;
                }).filter((x): x is number => x !== undefined)
              )
            }
            onSelectionChanged={(s) => {
              emptySessions();
              setSelSubscriptionTypes(Array.from(s.values()).map((i) => SUBSCRIPTION_KINDS[i]));
            }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_liveness`)}
        sort={{
          order: sorting["liveness"],
          onOrderChanged: (sorting) => onFieldOrderChanged("liveness", sorting),
        }}
        filter={
          <ListHeaderFilter
            items={LIVENESS_RESULTS.map((value) => ({ label: <LivenessChip value={value} /> }))}
            initialSelectedIndices={
              new Set(
                LIVENESS_RESULTS.map((s, idx) => {
                  if (selLivenessResults.includes(s)) return idx;
                  return undefined;
                }).filter((x): x is number => x !== undefined)
              )
            }
            onSelectionChanged={(s) => {
              emptySessions();
              const newLivenessResults = Array.from(s.values()).map((i) => LIVENESS_RESULTS[i]);
              if (newLivenessResults.includes("fail")) newLivenessResults.push("error");
              setSelLivenessResults(newLivenessResults);
            }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_face_comparison`)}
        sort={{
          order: sorting["face_comparison"],
          onOrderChanged: (sorting) => onFieldOrderChanged("face_comparison", sorting),
        }}
        filter={
          <ListHeaderFilter
            items={FACE_COMPARISON_RESULTS.map((value) => ({ label: <FaceComparisonChip value={value} /> }))}
            initialSelectedIndices={
              new Set(
                FACE_COMPARISON_RESULTS.map((s, idx) => {
                  if (selFaceComparisonResults.includes(s)) return idx;
                  return undefined;
                }).filter((x): x is number => x !== undefined)
              )
            }
            onSelectionChanged={(s) => {
              emptySessions();
              const newFaceComparisonResults = Array.from(s.values()).map((i) => FACE_COMPARISON_RESULTS[i]);
              if (newFaceComparisonResults.includes("mismatch")) newFaceComparisonResults.push("error");
              setSelFaceComparisonResults(newFaceComparisonResults);
            }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_retries`)}
        sort={{
          order: sorting["attempts"],
          onOrderChanged: (sorting) => onFieldOrderChanged("attempts", sorting),
        }}
        filter={
          <ListHeaderFilter
            items={ATTEMPTS.map((value) => ({
              label: <Chip label={value} variant="outlined" style={{ width: "50px" }} />,
            }))}
            initialSelectedIndices={
              new Set(
                ATTEMPTS.map((s, idx) => {
                  if (selAttempts.includes(s)) return idx;
                  return undefined;
                }).filter((x): x is number => x !== undefined)
              )
            }
            onSelectionChanged={(s) => {
              emptySessions();
              setSelAttempts(Array.from(s.values()).map((i) => ATTEMPTS[i]));
            }}
          />
        }
      />
    </Box>,
    <Box>
      <Header
        text={t(`header_gdpr`)}
        filter={
          <ListHeaderFilter
            items={[{ label: <OkIcon /> }, { label: <StopIcon /> }]}
            initialSelectedIndices={new Set(selGdprConsentStatus.map((value) => (value ? 0 : 1)))}
            onSelectionChanged={(s) => {
              emptySessions();
              const filterValues: boolean[] = [];
              if (s.has(0)) filterValues.push(true); // filter with gdpr consent set to true
              if (s.has(1)) filterValues.push(false);
              setSelGdprConsentStatus(filterValues);
            }}
          />
        }
      />
    </Box>,
  ];

  if (canViewStatus)
    headers.push(
      <Box>
        <Header
          text={t(`header_status`)}
          sort={{
            order: sorting["status"],
            onOrderChanged: (sorting) => onFieldOrderChanged("status", sorting),
          }}
          filter={
            <ListHeaderFilter
              items={SYNC_STATUS.map((value) => ({
                label: (
                  <Chip
                    label={t(`chip_${value}`)}
                    variant="outlined"
                    style={{
                      borderColor:
                        value === "synchronized" ? theme.palette.success.main : theme.palette.error.main,
                    }}
                  />
                ),
              }))}
              initialSelectedIndices={
                new Set(
                  SYNC_STATUS.map((s, idx) => {
                    if (selSyncStatus.includes(s)) return idx;
                    return undefined;
                  }).filter((x): x is number => x !== undefined)
                )
              }
              onSelectionChanged={(s) => {
                emptySessions();
                setSelSyncStatus(Array.from(s.values()).map((i) => SYNC_STATUS[i]));
              }}
            />
          }
        />
      </Box>
    );

  function onSessionClick(idx: number) {
    onSessionIdSelected(sessions[idx].id);
  }

  return (
    <CustomTable
      rows={sessionRows}
      heads={headers}
      isLoading={isLoading}
      rowOnClick={onSessionClick}
      onScroll={onScroll}
      height={height}
      selectedRowIndex={selectedSessionId ? sessions.findIndex((s) => s.id === selectedSessionId) : undefined}
      fixedLayout
    />
  );
}

type SortOrder = "asc" | "desc" | undefined;

type HeaderProps = {
  text: string;
  sort?: {
    order: SortOrder;
    onOrderChanged: (_: SortOrder) => void;
  };
  filter?: React.ReactNode;
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    margin: "4px",
    borderRight: "1px solid #eeeeee",
  },
  textAndFilterMenuContainer: {
    display: "flex",
    alignItems: "center",
  },
  sortContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerText: {
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  iconTop: {
    padding: "unset",
    position: "relative",
    top: "4px",
  },
  iconBottom: {
    padding: "unset",
    position: "relative",
    bottom: "4px",
  },
});

function Header({ text, sort, filter }: HeaderProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <span className={classes.headerText}>{text}</span>
      <Box>{filter}</Box>
      {sort && (
        <Box className={classes.sortContainer}>
          <IconButton
            className={classes.iconTop}
            onClick={() => sort.onOrderChanged(sort.order === "asc" ? undefined : "asc")}
          >
            <ArrowDropUp
              fontSize="small"
              htmlColor={sort.order === "asc" ? theme.palette.success.main : undefined}
            />
          </IconButton>
          <IconButton
            className={classes.iconBottom}
            onClick={() => sort.onOrderChanged(sort.order === "desc" ? undefined : "desc")}
          >
            <ArrowDropDown
              fontSize="small"
              htmlColor={sort.order === "desc" ? theme.palette.success.main : undefined}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
