import { Presets } from "@unissey/sdk-react__next";

import { twoWayMapping } from "../utils/typings";

const SUPPORTED_PRESETS = <const>[
  [Presets.FAST, "FAST"],
  [Presets.SUBSTANTIAL, "SUBSTANTIAL"],
  [Presets.ACQ_SESSION, "ACQSESSION"],
  [Presets.HITL, "HITL SESSION"],
];

export type ReadablePreset = typeof SUPPORTED_PRESETS[number][1];
export const [enumPresetToHumanReadable, humanReadableToEnumPreset] = twoWayMapping(SUPPORTED_PRESETS);
