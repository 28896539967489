import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Icons
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import HowToRegIcon from "@material-ui/icons/HowToRegOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import SupervisedUserIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import VideocamIcon from "@material-ui/icons/VideocamOutlined";
import DnsIcon from "@material-ui/icons/Dns";
import LocalOffer from "@material-ui/icons/LocalOffer";
import MenuBook from "@material-ui/icons/MenuBookOutlined";
// enums
import { Authorizations } from "@unissey/utils";
import clsx from "clsx";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
// components
import Copyright from "../components/Copyright";
import PrivateRoute from "../components/PrivateRoute";
// constants
import { pageHeader, Routes } from "../constants/routes";
// contexts
import { AuthContext } from "../contexts/auth_context";
import AuthenticationService from "../services/authentication_service";
// types
import { Notif } from "../types/notif";
import { getSessionUser, hasAuthorizations, logoutUser } from "../utils/auth_util";
// utils
import { isMobileDevice } from "../utils/misc_util";
// pages
import WorkspacePage from "./workspace/WorkspacePage";
import DemoPage from "./demo/DemoPage";
import NotFoundPage from "./error/NotFoundPage";
import ProfilePage from "./profile/ProfilePage";
import ReportPage from "./report/ReportPage";
import SessionsPage from "./session/SessionsPage";
import TeamPage from "./team/TeamPage";
import TermsOfUsePage from "./demo/TermsOfUsePage";
import ApiKeyPage from "./api/ApiKeyPage";
import SubscriptionsPage from "./subscription/SubscriptionsPage";
import DocumentationPage from "./documentation/DocumentationPage";

import theme from "../config/theme";

import { useTranslation } from "react-i18next";
import { LanguageSwitchMenu } from "../components/LanguageSwitchMenu";
import { ProfileMenu } from "../components/ProfileMenu";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { UnisseySymbolIcon } from "../components/UnisseySymbolIcon";
import { useInjection } from "../hooks/use-injection";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    height: "100vh",
  },
  toolbar: {
    paddingRight: isMobileDevice() ? theme.spacing(1) : 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: `0 ${theme.spacing(1.2)}px`,
    ...theme.mixins.toolbar,
  },
  logo: {
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 120,
  },
  iconHitbox: {
    width: 32,
    height: 32,
    borderRadius: 15,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    border: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: isMobileDevice() ? 0 : drawerWidth,
    width: isMobileDevice() ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  userButton: {
    maxWidth: isMobileDevice() ? "50%" : "auto",
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    // paddingBottom: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#F3F5F8",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave-main.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    backgroundSize: "auto 153px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `${isMobileDevice() ? theme.spacing(6) : 0}px ${
      isMobileDevice() ? theme.spacing(1) : theme.spacing(3)
    }px`,
    position: "relative",
    overflow: "auto",
    justifyContent: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: `${isMobileDevice() ? theme.spacing(2) : theme.spacing(2)}px`,
    paddingRight: `${isMobileDevice() ? theme.spacing(2) : theme.spacing(2)}px`,
    height: "100%",
  },
  navBottom: {
    marginTop: "auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: "white",

    height: "100%",

    backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave-nav.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
  },
  navBackgroundOpen: {
    backgroundSize: "100% auto",
  },
  navBackgroundClosed: {
    backgroundSize: "auto 154px",
    backgroundPositionX: "right",
  },
  copyright: {
    marginTop: "auto",
  },
  iconClosedDrawer: {
    marginLeft: theme.spacing(1),
  },
  menuItemFocused: {
    backgroundColor: "lightgray",
  },
}));

export default function Dashboard({ handleNotif }: { handleNotif: Notif }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { disableInjection } = useInjection();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const auth = useContext(AuthContext);

  let refreshTimeout: NodeJS.Timeout;

  const mainMenuItems: {
    name: string;
    route: string;
    icon: any;
    authorizations: Array<Authorizations>;
    displayCondition?: boolean;
  }[] = [
    {
      name: t("nav_menu.demo"),
      route: Routes.DEMO_TOU,
      icon: VideocamIcon,
      authorizations: [Authorizations.DEMO_USE],
    },
    {
      name: t("nav_menu.sessions"),
      route: Routes.SESSIONS,
      icon: HowToRegIcon,
      authorizations: [Authorizations.SESSION_LIST],
    },
    {
      name: t("nav_menu.workspaces"),
      route: Routes.WORKSPACE,
      icon: SupervisedUserIcon,
      authorizations: [Authorizations.WORKSPACE_LIST],
    },
    {
      name: t("nav_menu.subscriptions"),
      route: Routes.SUBSCRIPTIONS,
      icon: LocalOffer,
      authorizations: [Authorizations.SUBSCRIPTION_LIST],
      displayCondition: auth.user?.workspaceIsIntegrator,
    },
    {
      name: t("nav_menu.applications"),
      route: Routes.APPLICATIONS,
      icon: DnsIcon,
      authorizations: [Authorizations.APIKEY_LIST],
      displayCondition: auth.user?.workspaceIsIntegrator,
    },
    {
      name: t("nav_menu.documentation"),
      route: Routes.DOCUMENTATION,
      icon: MenuBook,
      authorizations: [Authorizations.DOCUMENTATION_LIST],
    },
    {
      name: t("nav_menu.report"),
      route: Routes.REPORT,
      icon: AssessmentOutlinedIcon,
      authorizations: [Authorizations.SESSION_LIST, Authorizations.SESSION_REPORT],
    },
  ];

  function handleLogout() {
    clearTimeout(refreshTimeout);
    logoutUser(auth);
    disableInjection();
    history.push("/");
  }

  const getDisplayName = () =>
    auth.user?.firstName && auth.user?.lastName && auth.user?.workspaceName
      ? `${auth.user?.firstName} ${auth.user?.lastName} (${auth.user?.workspaceName})`
      : auth.user?.auth_name;

  const availableMenuItems = mainMenuItems.filter(
    (m) => hasAuthorizations(auth, m.authorizations!) && m.displayCondition !== false
  );

  const hasDemoAccess = hasAuthorizations(auth, [Authorizations.DEMO_USE]);
  const defaultRoute = hasDemoAccess ? Routes.DEMO_TOU : availableMenuItems[0].route; // there should always at least be one item available

  useEffect(() => {
    (async function refreshToken(nextRefreshToken) {
      if (!nextRefreshToken) return handleLogout();
      try {
        const user = await AuthenticationService.refreshToken(nextRefreshToken);
        //TODO:
        // ESLint: Assignments to the 'refreshTimeout' variable from inside React Hook useEffect will be lost after each render.
        // To preserve the value over time, store it in a useRef Hook and keep the mutable value in the '.current' property.
        // Otherwise, you can move this variable directly inside useEffect. (react-hooks/exhaustive-deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refreshTimeout = setTimeout(() => refreshToken(user?.refresh_token), (user.expires_in - 60) * 1000); // Prevent front end requests delay (60 = delay in seconds)
        const sessionUser = await getSessionUser(user);
        if (!sessionUser) auth.unsetAuthUser();
        else auth.setAuthUser(sessionUser);
      } catch (error) {
        handleLogout();
      }
    })(auth?.user?.refresh_token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {!drawerOpen && (
            <>
              {auth.user && auth.user?.workspaceLogo?.length > 0 ? <MenuIcon /> : <UnisseySymbolIcon />}
              <IconButton
                className={clsx(classes.iconHitbox, classes.iconClosedDrawer)}
                onClick={() => setDrawerOpen(true)}
                edge="end"
              >
                <ArrowRight />
              </IconButton>
            </>
          )}
          <Box pt={8}></Box>

          <Typography color="textPrimary" variant="h6" noWrap className={classes.title}>
            {pageHeader(location.pathname, t)}
          </Typography>

          <ProfileMenu
            displayName={getDisplayName() ?? ""}
            onProfileClicked={() => history.replace(Routes.PROFILE + location.search)}
            onLogoutClicked={handleLogout}
          />

          <LanguageSwitchMenu />
        </Toolbar>
      </AppBar>

      {!isMobileDevice() && (
        <Drawer
          variant="permanent"
          classes={{ paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose) }}
          open={drawerOpen}
        >
          <div className={classes.toolbarIcon}>
            {/* <IconButton onClick={() => setDrawerOpen(false)}>
              <MenuIcon />
            </IconButton> */}
            <img
              className={classes.logo}
              alt="logo"
              src={
                auth.user && auth.user?.workspaceLogo?.length > 0
                  ? `data:image;base64,${auth.user?.workspaceLogo}`
                  : `${process.env.PUBLIC_URL}/images/unissey-logo.svg`
              }
            />
            <IconButton className={classes.iconHitbox} onClick={() => setDrawerOpen(false)}>
              <ArrowLeft />
            </IconButton>
            <div></div>
          </div>
          <Box p={1}>
            <List>
              {availableMenuItems.map((menuItem) => (
                <React.Fragment key={menuItem.route}>
                  <ListItem
                    button
                    onClick={() => history.replace(menuItem.route + location.search)}
                    style={{
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: theme.spacing(1),
                      backgroundColor:
                        menuItem.route === `/${location.pathname.split("/")[1]}`
                          ? theme.palette.action.selected
                          : undefined,
                    }}
                  >
                    <ListItemIcon style={{ color: theme.palette.common.black }}>
                      <menuItem.icon />
                    </ListItemIcon>
                    <ListItemText primary={menuItem.name} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Box>
          <Box
            className={clsx(
              classes.navBottom,
              drawerOpen ? classes.navBackgroundOpen : classes.navBackgroundClosed
            )}
            pt={4}
            display="flex"
            justifyContent="center"
          >
            {drawerOpen && <Copyright className={classes.copyright} color="inherit" />}
          </Box>
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Switch>
            <Route exact path={Routes.HOME}>
              <Redirect to={defaultRoute + location.search} />
            </Route>

            <PrivateRoute
              exact
              path={`${Routes.SESSIONS}`}
              authorizations={[Authorizations.SESSION_LIST]}
              redirectRoute={Routes.DEMO_TOU}
            >
              <SessionsPage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={`${Routes.SESSIONS}/:sessionId`}
              authorizations={[Authorizations.SESSION_LIST]}
            >
              <SessionsPage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute exact path={Routes.REPORT} authorizations={[Authorizations.SESSION_REPORT]}>
              <Box height="100%" overflow="scroll">
                <ReportPage onNotif={handleNotif} />
              </Box>
            </PrivateRoute>

            <PrivateRoute
              exact
              path={`${Routes.DEMO_TOU}`}
              authorizations={[Authorizations.DEMO_USE]}
              redirectRoute={Routes.PROFILE}
            >
              <TermsOfUsePage />
            </PrivateRoute>

            <PrivateRoute exact path={Routes.PROFILE} authorizations={[]}>
              <ProfilePage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute exact path={Routes.DEMO_CAPTURE} authorizations={[Authorizations.DEMO_USE]}>
              <DemoPage />
            </PrivateRoute>

            <PrivateRoute exact path={Routes.WORKSPACE} authorizations={[Authorizations.WORKSPACE_LIST]}>
              <WorkspacePage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute exact path={Routes.TEAM} authorizations={[Authorizations.USER_LIST_OWN]}>
              <TeamPage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute exact path={Routes.APPLICATIONS} authorizations={[Authorizations.APIKEY_LIST]}>
              <ApiKeyPage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={Routes.SUBSCRIPTIONS}
              authorizations={[Authorizations.SUBSCRIPTION_LIST]}
            >
              <SubscriptionsPage onNotif={handleNotif} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={Routes.DOCUMENTATION}
              authorizations={[Authorizations.DOCUMENTATION_LIST]}
            >
              <DocumentationPage />
            </PrivateRoute>

            <Route component={NotFoundPage} />
          </Switch>
        </Container>
      </main>
    </div>
  );
}
