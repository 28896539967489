import { loadConfig } from "@unissey/utils";

// Note: variables are injected by create-react-app so they are prefixed with 'REACT_APP_'
export const config = loadConfig({
  apiAnalyzeUrl: {
    key: "REACT_APP_API_ANALYZE_URL",
    default: "https://api-analyze.unissey.com/api/v3",
  },
  apiAnalyzeV2DocUrl: {
    key: "REACT_APP_API_ANALYZE_V2_DOC_URL",
    default: "https://api-analyze.unissey.com/api/v2/dev/doc",
  },
  apiAnalyzeDocUrl: {
    key: "REACT_APP_API_ANALYZE_DOC_URL",
    default: "https://api-analyze.unissey.com/api/v3/dev/doc",
  },
  apiAdminUrl: {
    key: "REACT_APP_API_ADMIN_URL",
    default: "https://api-admin.unissey.com",
  },

  kcAuthUrl: {
    key: "REACT_APP_KC_AUTH_SERVER_URL",
    default: "https://identity.unissey.com/auth",
  },
  kcRealm: {
    key: "REACT_APP_KC_REALM",
    default: "ds-cluster-workspace",
  },
  kcResource: {
    key: "REACT_APP_KC_RESSOURCE", // Note (Guillaume): typo…
    default: "api_admin",
  },

  i18Debug: {
    key: "REACT_APP_I18N_DEBUG",
    default: false,
    resolver: (raw: string) => ["1", "true"].includes(raw.toLowerCase()),
  },

  useContinuousIntegrationSubscriptions: {
    key: "REACT_APP_USE_CONTINUOUS_INTEGRATION_SUBSCRIPTIONS",
    default: false,
    resolver: (raw: string) => ["1", "true"].includes(raw.toLowerCase()),
  },
});
