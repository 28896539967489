import { ERROR_CODES, FIELD_CODES, ROLE_IDS } from "@unissey/utils";

export function errorCodeKey<T extends typeof ERROR_CODES[number]>(x: T): `code_${T}` {
  return `code_${x}`;
}

export function fieldCodeKey<T extends typeof FIELD_CODES[number]>(x: T): `field_${T}` {
  return `field_${x}`;
}

export function roleKey<T extends typeof ROLE_IDS[number]>(x: T): `_${T}` {
  return `_${x}`;
}
