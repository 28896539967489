import { Box, FormGroup, Grid } from "@material-ui/core";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import CustomSelect, { MenuItemsType } from "../../components/forms/formComponents/CustomSelect";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomDateTimePicker from "../../components/forms/formComponents/CustomDateTimePicker";
import { SubscriptionKind, SUBSCRIPTION_KINDS } from "../../types/subscriptionsCRUD";
import { useScopedTranslation } from "../../i18n";
import { ReadWorkspace } from "../../types/workspaceCRUD";
import { CreateSubscriptionForm, UpdateSubscriptionForm } from "../../stores/forms";
import { observer } from "mobx-react-lite";

type Props =
  | {
      kind: "create";
      form: CreateSubscriptionForm;
      workspaces: ReadWorkspace[];
    }
  | { kind: "update"; form: UpdateSubscriptionForm };

function SubscriptionForm(props: Props) {
  const { form } = props;

  const { t, tCommon } = useScopedTranslation("subscription_page.form");

  const subscriptionMenuItems: MenuItemsType = SUBSCRIPTION_KINDS.map((kind) => [
    kind,
    tCommon(`sub_kind.${kind}`),
  ]);

  return (
    <FormGroup>
      <Box py={2} mb={1}>
        <Grid container spacing={3}>
          <CustomTextField
            name="subscriptionName"
            width={props.kind === "create" ? 6 : 12}
            onChange={(e) => form.setField("name", e.target.value)}
            value={form.data.name ?? ""}
            label={t("field_subscription_name")}
            autoFocus
            onBlur={(e) => form.setField("name", e.target.value.trim())}
          />
          {props.kind === "create" && (
            <CustomSelect
              width={6}
              labelId="create-application-form-select-workspace"
              labelValue={t("field_workspace")}
              menuItems={props.workspaces.map((ws) => [ws.id, ws.name])}
              onChange={(e) => props.form.setWorkspaceId(e.target.value as string)}
              value={props.form.data.workspaceId}
            />
          )}

          <CustomSelect
            width={6}
            labelId="subscription-form-select-kind"
            labelValue={t("field_subscription_type")}
            menuItems={subscriptionMenuItems}
            onChange={(e) => {
              const subKind = e.target.value as SubscriptionKind;
              form.setField("kind", subKind);
            }}
            value={form.data.kind}
          />
          <CustomTextField
            name="subscriptionMaxSessionCount"
            type="number"
            required={false}
            width={6}
            onChange={(e) => {
              const parsed = parseInt(e.target.value, 10);
              form.setField("maxSessionCount", isNaN(parsed) ? undefined : parsed);
            }}
            value={form.data.maxSessionCount ?? ""}
            label={t("field_max_sessions")}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CustomDateTimePicker
              value={form.data.startDate ?? null}
              onChange={(date) => {
                if (date) {
                  form.setField("startDate", date);
                }
              }}
              label={t("field_start_date")}
              maxDate={form.data.endDate}
              width={6}
              fullWidth
            />
            <CustomDateTimePicker
              value={form.data.endDate ?? null}
              onChange={(date) => form.setField("endDate", date ?? undefined)}
              label={t("field_end_date")}
              minDate={form.data.startDate}
              width={6}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Box>
    </FormGroup>
  );
}

export default observer(SubscriptionForm);
