import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { getApplications, getWorkspaces } from "../services/api_services";
import { ReadApplication } from "../types/applicationsCRUD";
import { ReadWorkspace } from "../types/workspaceCRUD";
import SelectWithTags from "./forms/formComponents/SelectWithTags";
import { Notif } from "../types/notif";
import { AuthContext } from "../contexts/auth_context";
import { hasAuthorizations } from "../utils/auth_util";
import { Authorizations } from "@unissey/utils";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomDateTimePicker from "./forms/formComponents/CustomDateTimePicker";
import { useScopedTranslation } from "../i18n";

interface Props {
  selWorkspaces?: ReadWorkspace[];
  setSelWorkspaces?: Dispatch<SetStateAction<ReadWorkspace[]>>;
  selApplications?: ReadApplication[];
  setSelApplications?: Dispatch<SetStateAction<ReadApplication[]>>;
  selDateRange?: [Date | null, Date | null];
  setSelDateRange?: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  onSessionSelected?: (selectedSession: string) => void;
  onNotif: Notif;
  emptySessions?: () => void;
}

export default function SessionsFilterBar({
  selWorkspaces = [],
  setSelWorkspaces,
  selApplications = [],
  setSelApplications,
  selDateRange = [null, null],
  setSelDateRange,
  onSessionSelected,
  onNotif,
  emptySessions,
}: Props) {
  const { t } = useScopedTranslation("sessions_page.filters");

  const [sessionToFind, setSessionToFind] = useState<string>("");
  const [workspaces, setWorkspaces] = useState<ReadWorkspace[]>([]);
  const [applications, setApplications] = useState<ReadApplication[]>([]);
  const [fromDate, setFromDate] = useState<Date | null>(selDateRange ? selDateRange[0] : null);
  const [toDate, setToDate] = useState<Date | null>(selDateRange ? selDateRange[1] : null);

  const auth = React.useContext(AuthContext);
  const canListWorkspaces = hasAuthorizations(auth, [Authorizations.WORKSPACE_LIST]);
  const canListApiKeys = hasAuthorizations(auth, [Authorizations.APIKEY_LIST]);

  useEffect(() => {
    (async () => {
      let workspaceIds = [auth.user!.workspaceId];
      if (canListWorkspaces) {
        const workspaces = await getWorkspaces(onNotif);
        setWorkspaces(workspaces ?? []);
        if (workspaces) workspaceIds = workspaces.map((w) => w.id);
      }
      if (canListApiKeys) setApplications((await getApplications(onNotif, workspaceIds)) ?? []);
    })();
  }, [canListWorkspaces, onNotif, canListApiKeys, auth.user]);

  useEffect(() => {
    if (toDate && fromDate && toDate <= fromDate) {
      onNotif?.({ textKey: "error.invalid_date_filter_input" });
      return;
    }
    if (emptySessions) emptySessions();
    if (setSelDateRange) setSelDateRange([fromDate, toDate]);
    // TODO: missing dependency: 'emptySessions'. Either include it or remove the dependency array.
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelDateRange, onNotif, fromDate, toDate]);

  const _onSelWorkspaceChange = (selectedWorkspaces: ReadWorkspace[]) => {
    if (emptySessions) emptySessions();
    if (setSelWorkspaces) setSelWorkspaces(selectedWorkspaces.filter((w) => !!w));
  };

  const _onSelApplicationChange = (selectedApplications: ReadApplication[]) => {
    if (emptySessions) emptySessions();
    if (setSelApplications) setSelApplications(selectedApplications.filter((a) => !!a));
  };

  const getApplicationsOptions = () => {
    if (!selWorkspaces || selWorkspaces.length === 0) return applications;
    return applications.filter((a) => selWorkspaces.some((w) => a.workspaceId === w.id));
  };

  return (
    <Box width="100%" bgcolor="#F5F5F5" style={{ padding: 10 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {onSessionSelected && (
          <Grid item xs={4} style={{ paddingBottom: 0 }}>
            <TextField
              fullWidth
              label={t("label_search_by_id")}
              value={sessionToFind}
              onChange={(e) => setSessionToFind(e.target.value.trim())}
              onKeyPress={(e) => {
                if (e.key === "Enter" && sessionToFind.length >= 24) {
                  onSessionSelected(sessionToFind);
                }
              }}
              variant="outlined"
            />
          </Grid>
        )}
        {setSelWorkspaces && (
          <Grid item xs={4} style={{ paddingBottom: 0 }}>
            <SelectWithTags
              options={workspaces}
              values={selWorkspaces}
              onChange={_onSelWorkspaceChange}
              label={t("label_filter_workspace")}
              placeholder={"workspace"}
            />
          </Grid>
        )}
        {setSelApplications && (
          <Grid item xs={4} style={{ paddingBottom: 0 }}>
            <SelectWithTags
              options={getApplicationsOptions()}
              values={selApplications}
              onChange={_onSelApplicationChange}
              label={t("label_filter_applications")}
              placeholder={"application"}
            />
          </Grid>
        )}
        {setSelDateRange && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CustomDateTimePicker
              value={selDateRange[0]}
              onChange={setFromDate}
              label={t("label_filter_start_date")}
              maxDate={selDateRange[1]}
              width={4}
              disableFuture
            />
            <CustomDateTimePicker
              value={selDateRange[1]}
              onChange={setToDate}
              label={t("label_filter_end_date")}
              minDate={selDateRange[0]}
              width={4}
              disableFuture
            />
          </MuiPickersUtilsProvider>
        )}
        <Grid item xs={4} />
      </Grid>
    </Box>
  );
}
