import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";

type Props = { label: string; password: string; onChange: (pw: string) => void };

export default function PasswordInput({ label, password, onChange }: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="password-input" required>
        {label}
      </InputLabel>
      <OutlinedInput
        required
        label={label}
        id="password-input"
        type={showPassword ? "text" : "password"}
        value={password}
        autoComplete="current-password"
        onChange={(e) => onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
