import { Box, Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { Notif } from "../../types/notif";
import { ModalV2 as Modal } from "../../components/Modal";
import { CreateApplication } from "../../types/applicationsCRUD";
import { has } from "../../types/utils";
import { createApplication, getSubscriptions, getWorkspaces } from "../../services/api_services";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import CustomSelect, { MenuItemsType } from "../../components/forms/formComponents/CustomSelect";
import { hasAuthorizations } from "../../utils/auth_util";
import { Authorizations } from "@unissey/utils";
import { AuthContext } from "../../contexts/auth_context";
import { useScopedTranslation } from "../../i18n";

type Props = DialogProps & {
  onClose: () => void;
  onNotif: Notif;
};

export default function CreateApiKeyModal({ open, onClose, onNotif }: Props) {
  const { t } = useScopedTranslation("api_page.create_dialog");

  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<MenuItemsType>([]);
  const [subscriptions, setSubscriptions] = useState<MenuItemsType>([]);
  const [apiKeyForm, setApiKeyForm] = useState<CreateApplication>({
    name: undefined,
    referers: [],
    subscriptionId: undefined,
    workspaceId: undefined,
  });

  const canListWorkspaces = hasAuthorizations(auth, [Authorizations.WORKSPACE_LIST]);

  useEffect(() => {
    (async () => {
      if (canListWorkspaces) {
        const workspaces = await getWorkspaces(onNotif);
        setWorkspaces((workspaces?.map((ws) => [ws.id, ws.name]) as MenuItemsType) ?? []);
      } else {
        setApiKeyForm((form) => ({ ...form, workspaceId: auth.user!.workspaceId }));
        setWorkspaces([[auth.user!.workspaceId, auth.user!.workspaceName]]);
      }
      const subscriptions = await getSubscriptions(onNotif, "free_demo", true);
      setSubscriptions((subscriptions?.map((sub) => [sub.id, sub.name]) as MenuItemsType) ?? []);
    })();
  }, [canListWorkspaces, onNotif, auth.user]);

  async function createApiKey() {
    setIsLoading(true);
    if (!has(apiKeyForm, "subscriptionId") || !has(apiKeyForm, "name") || !has(apiKeyForm, "workspaceId")) {
      onNotif({
        textKey: "error.missing_api_key_fields",
      });
      setIsLoading(false);
      return;
    }
    if (await createApplication(onNotif, apiKeyForm))
      onNotif({
        textKey: "success.api_key_created",
        textParams: { applicationName: apiKeyForm.name },
      });
    onClose();
    setIsLoading(false);
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      maxWidth="md"
      body={
        <FormGroup>
          <Box py={2}>
            <Grid container spacing={2}>
              <CustomTextField
                autoFocus
                name="applicationName"
                label={t("field_application_name")}
                width={12}
                value={apiKeyForm.name}
                onChange={(e) => setApiKeyForm({ ...apiKeyForm, name: e.target.value })}
                onBlur={(e) => setApiKeyForm({ ...apiKeyForm, name: e.target.value.trim() })}
              />
              {/* </Grid> */}
              {/* <Grid container spacing={2}> */}
              <CustomSelect
                width={6}
                labelId="create-application-form-select-workspace"
                labelValue={t("field_workspace")}
                disabled={!canListWorkspaces}
                menuItems={workspaces}
                onChange={(e) => setApiKeyForm({ ...apiKeyForm, workspaceId: e.target.value as string })}
                value={apiKeyForm.workspaceId}
              />
              <CustomSelect
                width={6}
                labelId="create-application-form-select-environment"
                labelValue={t("field_subscription")}
                menuItems={subscriptions}
                onChange={(e) => setApiKeyForm({ ...apiKeyForm, subscriptionId: e.target.value as number })}
                value={apiKeyForm.subscriptionId}
              />
            </Grid>
          </Box>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={createApiKey}
          startIcon={isLoading && <CircularProgress size={14} />}
          fullWidth
          disabled={isLoading}
        >
          {t("button_create")}
        </Button>
      }
    />
  );
}
