import React, { MouseEvent, useState } from "react";
import { Box, IconButton, Menu, Link, Button, Chip } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import theme from "../../config/theme";
import { useTranslation } from "react-i18next";

type Props = {
  onClearAll?: () => void;
  onSelectAll?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  activeIndicator?: string;
};

export const HeaderFilterMenu: React.FC<Props> = ({
  onClearAll,
  onSelectAll,
  onCancel,
  onConfirm,
  activeIndicator,
  children,
}) => {
  const { t } = useTranslation();
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null);

  const callAndCloseMenu = (cb: () => void) => () => {
    setFilterAnchor(null);
    cb();
  };

  const menuContent = (
    <Box padding={1}>
      <Box display="flex" m={1}>
        {onClearAll && (
          <Link onClick={onClearAll} underline="always" style={{ cursor: "pointer" }}>
            {t("clear_all")}
          </Link>
        )}
        <Box m={1} />
        {onSelectAll && (
          <Link onClick={onSelectAll} underline="always" style={{ cursor: "pointer" }}>
            {t("select_all")}
          </Link>
        )}
      </Box>
      <Box m={1}>{children}</Box>
      <Box m={1} />
      <Box display="flex" justifyContent="center" m={1}>
        <Button
          onClick={callAndCloseMenu(onCancel)}
          variant="outlined"
          style={{
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            borderWidth: "1px",
            borderStyle: "solid",
            padding: "unset",
            textTransform: "unset",
          }}
        >
          Cancel
        </Button>
        <Box m={1} />
        <Button
          onClick={callAndCloseMenu(onConfirm)}
          variant="contained"
          style={{
            backgroundColor: theme.palette.success.main,
            color: "white",
            padding: "unset",
            textTransform: "unset",
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box display="flex">
      <IconButton
        aria-haspopup="true"
        aria-controls="customized-menu"
        onClick={(e: MouseEvent<HTMLElement>) => setFilterAnchor(e.currentTarget)}
        size="small"
      >
        {filterAnchor ? (
          <ExpandLess htmlColor={activeIndicator && theme.palette.success.main} />
        ) : (
          <ExpandMore htmlColor={activeIndicator && theme.palette.success.main} />
        )}
      </IconButton>
      {activeIndicator && (
        <Chip size="small" label={activeIndicator} style={{ backgroundColor: theme.palette.success.main }} />
      )}
      <Menu
        anchorEl={filterAnchor}
        getContentAnchorEl={null}
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(filterAnchor)}
        variant="menu"
        onClose={() => {
          setFilterAnchor(null);
          onCancel();
        }}
      >
        {menuContent}
      </Menu>
    </Box>
  );
};
