import { Box, Chip, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CheckOutlined } from "@material-ui/icons";

import { useScopedTranslation } from "../i18n";
import { PasswordCriterion, PasswordStrength, PASSWORD_CRITERIA, SPECIAL_CHARS } from "../utils/password";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const useChipStyle = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(1),
    marginLeft: "4px",
    marginRight: "4px",
    fontSize: "0.75em",
  },
  chipValid: {
    backgroundColor: "#BFEADB",
  },
  chipValidLabel: {
    color: "#009B65",
  },
  chipValidIcon: {
    color: "#009B65",
  },
}));

type Props = {
  strength: PasswordStrength;
};

export default function PasswordStrengthChips({ strength }: Props) {
  const classes = useStyle();

  return (
    <Box mb={1} className={classes.container}>
      {PASSWORD_CRITERIA.map((criterion) => (
        <CriterionChip
          criterion={criterion}
          valid={strength.type === "strong-enough" || !strength.missingCriteria.includes(criterion)}
        />
      ))}
    </Box>
  );
}

type ChipProps = {
  criterion: PasswordCriterion;
  valid: boolean;
};

function CriterionChip({ criterion, valid }: ChipProps) {
  const classes = useChipStyle();
  const { t } = useScopedTranslation("reset_password_page.chips");

  const chip = (
    <Chip
      label={t(`label_${criterion}`)}
      className={classes.chip}
      classes={{
        root: valid ? classes.chipValid : undefined,
        label: valid ? classes.chipValidLabel : undefined,
      }}
      icon={valid ? <CheckOutlined className={classes.chipValidIcon} /> : undefined}
    />
  );

  if (criterion === "at-least-one-special-char") {
    return (
      <Tooltip
        title={
          <Typography>
            {t("tooltip_special-chars")}
            <div>{SPECIAL_CHARS.join(" ")}</div>
          </Typography>
        }
      >
        {chip}
      </Tooltip>
    );
  } else {
    return chip;
  }
}
