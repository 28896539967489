import { Box, Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import { useState, useContext, SetStateAction, Dispatch } from "react";
import theme from "../../config/theme";
// services
import { deleteUser, updateRoles, updateUser } from "../../services/api_services";
import { Notif } from "../../types/notif";
// types
import { UpdateUser } from "../../types/userCRUD";
import Modal from "../../components/Modal";
// components
import UserForm from "../../components/forms/UserForm";
import { AuthContext } from "../../contexts/auth_context";
import { useStores } from "../../hooks/use-stores";

type Props = DialogProps & {
  onClose: () => void;
  onEdit: () => void;
  userToEdit: Required<UpdateUser>;
  setUserToEdit: Dispatch<SetStateAction<Required<UpdateUser>>>;
  targetWorkspaceId?: string;
  onNotif: Notif;
};

export default function UpdateTeamMember({
  open,
  onClose,
  onEdit,
  userToEdit,
  setUserToEdit,
  targetWorkspaceId,
  onNotif,
}: Props) {
  const { workspaceStore } = useStores();
  const { getWorkspace } = workspaceStore;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authUserId = useContext(AuthContext).user!.userId;

  const handleUserUpdate = async () => {
    setIsLoading(true);

    if (
      (await updateUser(onNotif, userToEdit, userToEdit.id)) &&
      (await updateRoles(onNotif, userToEdit.roleIds, userToEdit.id))
    ) {
      const w = getWorkspace(targetWorkspaceId!);
      w?.fetchTeamMembers();
      onEdit();
      onNotif({ textKey: "success.user_edited" });
    }
    setIsLoading(false);
  };

  async function handleDelete() {
    setIsLoading(true);
    if (await deleteUser(onNotif, userToEdit.id)) {
      const w = getWorkspace(targetWorkspaceId!);
      w?.removeTeamMemberById(userToEdit.id);
      onClose();
      onNotif({ textKey: "success.user_deleted" });
      await w?.fetchTeamMembers();
    }
    setIsLoading(false);
  }

  function DeleteButton() {
    if (userToEdit.id === authUserId) return null;
    return (
      <Button
        variant="outlined"
        onClick={handleDelete}
        style={{ color: theme.palette.error.main }}
        startIcon={isLoading && <CircularProgress size={14} />}
      >
        Delete Team Member
      </Button>
    );
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        onClose={onClose}
        title="Edit Team Member"
        body={
          <FormGroup>
            <Box py={2} mb={1}>
              <Grid container spacing={3}>
                <UserForm
                  setFormData={setUserToEdit}
                  userFormData={userToEdit}
                  targetWorkspaceId={targetWorkspaceId}
                />
              </Grid>
            </Box>
          </FormGroup>
        }
        action={
          <div>
            <Grid container>
              <DeleteButton />
              <Box m={1} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUserUpdate}
                startIcon={isLoading && <CircularProgress size={14} />}
              >
                Confirm
              </Button>
            </Grid>
          </div>
        }
      />
    </>
  );
}
