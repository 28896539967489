export const PASSWORD_CRITERIA = <const>[
  "at-least-10-chars",
  "at-least-one-digit",
  "at-least-one-special-char",
  "at-least-one-lowercase",
  "at-least-one-uppercase",
];

export type PasswordCriterion = typeof PASSWORD_CRITERIA[number];

export type PasswordStrength =
  | { type: "strong-enough" }
  | { type: "too-weak"; missingCriteria: PasswordCriterion[] };

export function passwordStrength(password: string): PasswordStrength {
  const missingCriteria = PASSWORD_CRITERIA.filter((criterion) => !validateCriterion(password, criterion));

  if (missingCriteria.length === 0) {
    return { type: "strong-enough" };
  } else {
    return { type: "too-weak", missingCriteria };
  }
}

function validateCriterion(password: string, criterion: PasswordCriterion): boolean {
  // Rant (Guillaume): there is no real definition of "a character"
  // Humanity taught users to have passwords that satisfy weird requirements
  // involving "special characters" and such but nobody even agrees on what
  // "a character" is. Is it a unicode codepoint ? a scalar value ? a grapheme ?
  // We will just settle with javascript's "code points" here which are UTF-16
  // code points from my understanding of the ecmascript specification…
  const chars = Array.from(password);

  switch (criterion) {
    case "at-least-10-chars":
      return chars.length >= 10;

    case "at-least-one-digit":
      return chars.some(isDigit);

    case "at-least-one-lowercase":
      return chars.some(isAsciiLowercase);

    case "at-least-one-uppercase":
      return chars.some(isAsciiUppercase);

    case "at-least-one-special-char":
      return chars.some(isSpecial);
  }
}

function isDigit(chr: string): boolean {
  return chr >= "0" && chr <= "9";
}

function isAsciiLowercase(chr: string): boolean {
  return chr >= "a" && chr <= "z";
}

function isAsciiUppercase(chr: string): boolean {
  return chr >= "A" && chr <= "Z";
}

// Rant (Guillaume): This notion is very blurry too, what even is a "special"
// character ?
// Until it's properly specced and justified, I'll just go with the list at:
// https://owasp.org/www-community/password-special-characters
export const SPECIAL_CHARS = Array.from(" !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~");

function isSpecial(chr: string): boolean {
  return SPECIAL_CHARS.some((special) => special === chr);
}
