import { Authorizations } from "@unissey/utils";
import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
// constants
import { Routes } from "../constants/routes";
// contexts
import { AuthContext } from "../contexts/auth_context";
import RestrictedPage from "../pages/error/RestrictedPage";
// utils
import { hasAuthorizations } from "../utils/auth_util";

type Props = {
  authorizations: Authorizations[];
  redirectRoute?: string;
};

const PrivateRoute = ({ authorizations, redirectRoute, ...rest }: Props & RouteProps) => {
  const location = useLocation();
  const auth = React.useContext(AuthContext);

  if (!auth.user) {
    return (
      <Route {...rest}>
        <Redirect
          to={{
            pathname: Routes.SIGNIN,
            state: { from: location },
            search: location.search,
          }}
        />
      </Route>
    );
  }

  if (auth.user.authorizations.length === 0 && authorizations.length > 0) {
    return (
      <Route {...rest}>
        <RestrictedPage
          userAuthorizations={auth.user.entity.authorizations}
          requiredAuthorizations={authorizations}
        />
      </Route>
    );
  }

  return (
    <Route {...rest}>
      {auth.user && hasAuthorizations(auth, authorizations) ? (
        rest.children
      ) : redirectRoute ? (
        <Redirect
          to={{
            pathname: redirectRoute,
            state: { from: location },
            search: location.search,
          }}
        />
      ) : (
        <RestrictedPage
          userAuthorizations={auth.user.entity.authorizations}
          requiredAuthorizations={authorizations}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
