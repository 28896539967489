import { Button, CircularProgress, DialogProps } from "@material-ui/core";
import { useState } from "react";
// import theme from "../../config/theme";
// services
import { updateSubscription } from "../../services/api_services";
import { Notif } from "../../types/notif";
// types
import { ModalV2 as Modal } from "../../components/Modal";
import { UpdateSubscription } from "../../types/subscriptionsCRUD";

import SubscriptionForm from "./SubscriptionForm";
import { useScopedTranslation } from "../../i18n";
import { observer, useLocalObservable } from "mobx-react-lite";
import { UpdateSubscriptionForm } from "../../stores/forms";
import { toJS } from "mobx";

type Props = DialogProps & {
  subscription: UpdateSubscription;
  onClose: () => void;
  onNotif: Notif;
};

export function EditSubscriptionModal({ open, subscription, onClose, onNotif }: Props) {
  const { t, tCommon } = useScopedTranslation("subscription_page.edit_dialog");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useLocalObservable(() => new UpdateSubscriptionForm(subscription));

  async function handleUpdate() {
    setIsLoading(true);
    if (await updateSubscription(onNotif, toJS(form.data))) {
      onClose();
      onNotif({ textKey: "success.subscription_edited" });
    }
    setIsLoading(false);
  }

  // NOTE (19/11/21 Martin): Since deleteSubscription is not implemented
  // We at the moment hide the button to not mislead customers
  // async function handleDelete() {
  //   setIsLoading(true);
  //   if (!subscription.id) return;
  //   if (await deleteSubscription(onNotif, subscription.id)) {
  //     onClose();
  //     onNotif({ textKey: "success.workspace_deleted" });
  //   }
  //   setIsLoading(false);
  // }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        onCancel={onClose}
        maxWidth="md"
        title={t("title")}
        body={<SubscriptionForm kind="update" form={form} />}
        action={
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleUpdate}
            startIcon={isLoading && <CircularProgress size={14} />}
            disabled={isLoading || !form.isValid}
            fullWidth
          >
            {tCommon("button_confirm")}
          </Button>
          // NOTE (19/11/21 Martin): Since deleteSubscription is not implemented
          // We at the moment hide the button to not mislead customers
          /*<div>
            <Grid container>
              <Button
                variant="outlined"
                onClick={handleDelete}
                style={{ color: theme.palette.error.main }}
                startIcon={isLoading && <CircularProgress size={14} />}
              >
                {t("button_delete")}
              </Button>
              <Box m={1} />
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleUpdate}
                startIcon={isLoading && <CircularProgress size={14} />}
                disabled={isLoading}
                fullWidth
              >
                {tCommon("button_confirm")}
              </Button>
            </Grid>
          </div>*/
        }
      />
    </>
  );
}

export default observer(EditSubscriptionModal);
