import { ReadablePreset } from "../types/sdk";
import { usePersistedState } from "../utils/persistence";

export type InjectionStatus = "enabled" | "disabled";

export const NO_PRESET = "N/A";
export type RndPreset = ReadablePreset | typeof NO_PRESET;

export function useInjection() {
  const [status, setStatus, clearStatus] = usePersistedState<InjectionStatus>(
    "injectionStatus",
    "disabled",
    "session"
  );

  const [instructionsSeen, setInstructionsSeen] = usePersistedState(
    "injectionInstructionsSeen",
    false,
    "local"
  );

  const [rndPreset, setRndPreset] = usePersistedState<RndPreset>("rndPreset", NO_PRESET, "session");

  const disableInjection = () => {
    setStatus("disabled");
    clearStatus();
  };

  return {
    injectionEnabled: status === "enabled",
    setInjectionStatus: setStatus,
    disableInjection,
    instructionsSeen,
    setInstructionsSeen,
    rndPreset,
    setRndPreset,
  };
}
