import { SnackbarOrigin, SnackbarProps } from "@material-ui/core";
import { StringMap, TOptions } from "i18next";
import { ReactNode } from "react";
import { I18nCommonKeys } from "../i18n";
import { FIELD_CODES } from "@unissey/utils";

type NotifType = "success" | "error";

type TextKeyForType<T extends NotifType> = `${T}.${I18nCommonKeys<`notif.${T}`> extends string | number
  ? I18nCommonKeys<`notif.${T}`>
  : never}`;

type TextKey = TextKeyForType<"success"> | TextKeyForType<"error">;

export function extractNotifType<K extends TextKey>(k: K): NotifType | undefined {
  if (k.startsWith("error")) return "error";
  if (k.startsWith("success")) return "success";
}

export type NotifProps = {
  textKey: TextKey;
  textParams?: TOptions<StringMap>;
  errorFields?: typeof FIELD_CODES;
  icon?: ReactNode | undefined;
  anchorOrigin?: SnackbarOrigin | undefined;
};

export type AlertNotifProps = {
  notif?: NotifProps;
  snackbarProps?: SnackbarProps;
  isOpen?: boolean;
  onClose?: () => void;
};

export type Notif = (notif: NotifProps) => void;
