import { ThemeProvider } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// components
import PrivateRoute from "./components/PrivateRoute";
// themes
import theme from "./config/theme";
// constants
import { Routes } from "./constants/routes";
// providers
import AuthContextProvider from "./contexts/auth_context";
import FinalizeUserSignup from "./pages/authentification/FinalizeUserSignup";
// pages
import HomePage from "./pages/HomePage";
import ResetPasswordPage from "./pages/authentification/ResetPasswordPage";
import SignInPage from "./pages/authentification/SignInPage";
import { NotifProps } from "./types/notif";
import AlertNotif from "./components/AlertNotif";
import { TransientDemoPage } from "./pages/transient-demo/TransientDemoPage";

export default function App() {
  const [notifIsOpen, setNotifIsOpen] = useState(false);
  const [notifProps, setNotifProps] = useState<NotifProps | undefined>(undefined);

  const handleNotif = useCallback((props: NotifProps) => {
    setNotifProps(props);
    setNotifIsOpen(true);
  }, []);

  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path={Routes.TRANSIENT_DEMO} component={TransientDemoPage} />

            <Route exact path={Routes.SIGNIN} component={SignInPage} />
            <Route exact path={Routes.FINALIZE_SIGNUP} component={FinalizeUserSignup} />
            <Route exact path={Routes.RESET_PASSWORD} component={ResetPasswordPage} />

            <PrivateRoute path={Routes.HOME} authorizations={[]}>
              <HomePage handleNotif={handleNotif} />
            </PrivateRoute>
          </Switch>
          <AlertNotif notif={notifProps} isOpen={notifIsOpen} onClose={() => setNotifIsOpen(false)} />
        </Router>
      </ThemeProvider>
    </AuthContextProvider>
  );
}
