// utils
import { Authorizations, SimpleAuthEntity } from "@unissey/utils";
// types
import { IAuthContextInterface, Me, SessionUser, UserAuth } from "../types/auth";
import AuthenticationService from "../services/authentication_service";
import { getApplications, getDemoApplications } from "../services/api_services";
import { Application } from "../types/applicationsCRUD";

export function logoutUser(auth: IAuthContextInterface) {
  auth.unsetAuthUser();
  AuthenticationService.SSOSignOut();
}

export async function getSessionUser(user: SessionUser): Promise<SessionUser | null> {
  let sessionUser: SessionUser = user;
  try {
    // TODO: make getMe route also fetch workspace Create At date (to avoid too many api calls)
    const me = await AuthenticationService.getMe(user.token);
    const ws = await AuthenticationService.getWorkspace(user.token);

    let apps = undefined;
    try {
      apps = await getApplications(false, undefined, true, user.token);
    } catch (e) {
      // Missing rights probably ?
      console.error(`Failed to query applications: ${e}`);
    }
    const hasApiKeysMenuTab = apps !== undefined && apps.length > 0;

    let injectionApp: Application | undefined;
    let defaultApp: Application | undefined;

    try {
      if (user.authorizations.includes(Authorizations.DEMO_USE)) {
        const applications = await getDemoApplications("demo_web", false);
        injectionApp = applications.find((app) => app.kind === "anti-injection");
        defaultApp = applications.find((app) => app.kind === "default");
      }
    } catch (e) {
      console.error(`Failed to query demo applications: ${e}`);
    }

    const toMergeMe: Me = {
      injectionApp,
      defaultApp,
      auth_name: me.auth_name,
      firstName: me.firstName,
      lastName: me.lastName,
      email: me.email,
      roles: me.roles,
    };
    const toMergeUser: UserAuth = {
      token: user.token,
      expires_in: user.expires_in,
      refresh_token: user.refresh_token,
      refresh_expires_in: user.refresh_expires_in,
      workspaceId: me.workspace.id,
      workspaceCreatedAt: new Date(ws.createdAt),
      authorizedWorkspaceIds: user.authorizedWorkspaceIds,
      workspaceName: user.workspaceName,
      userId: user.userId,
      authorizations: user.authorizations,
      workspaceLogo: ws.logo,
      workspaceParentName: ws.parentName,
      workspaceIsIntegrator: ws.isIntegrator,
    };

    sessionUser = {
      ...toMergeMe,
      ...toMergeUser,
      terms_of_use: user.terms_of_use,
      privacy_policy: user.privacy_policy,
      entity: new SimpleAuthEntity(me.workspace.id, user.userId, [user.workspaceId], user.authorizations),
      hasApiKeysMenuTab,
    };
    return sessionUser;
  } catch (error) {
    return null;
  }
}

export const hasAuthorizations = (auth: IAuthContextInterface, authorizations: Array<Authorizations>) => {
  return authorizations.every((authorization: Authorizations) =>
    auth.user?.entity.hasAuthorization(authorization, auth.user.workspaceId, null)
  );
};
