import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";

import CustomTextField from "../../../components/forms/formComponents/CustomTextField";
import { useScopedTranslation } from "../../../i18n";

import AuthenticationService from "../../../services/authentication_service";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function ForgotPasswordModal({ isOpen, onClose }: Props) {
  const { t } = useScopedTranslation("signin_page.forgot_password_dialog");

  const [email, setEmail] = useState<string>("");

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("content")}</DialogContentText>
        <CustomTextField
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => setEmail(e.target.value.trim())}
          label={t("field_email")}
          type="email"
        />
        <DialogContentText style={{ fontSize: "14px" }}>* {t("instructions")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("button_cancel")}
        </Button>
        <Button
          onClick={() => {
            AuthenticationService.resetPasswordStep1(email);
            onClose();
          }}
          color="primary"
        >
          {t("button_ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
