import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import LockOutlined from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import ResetPasswordForm from "../../components/forms/ResetPasswordForm";
import { AuthContext } from "../../contexts/auth_context";
import { deleteUser, updateUser, updateUserPassword } from "../../services/api_services";
import { Notif } from "../../types/notif";
import { UpdateUser } from "../../types/userCRUD";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import theme from "../../config/theme";
import { getSessionUser, logoutUser } from "../../utils/auth_util";
import { ConfirmDialogV2 as ConfirmDialog } from "../../components/ConfirmDialog";
import { useScopedTranslation } from "../../i18n";
import PasswordInput from "../../components/forms/formComponents/PasswordInput";

export default function ProfilePage({ onNotif }: { onNotif: Notif }) {
  const auth = React.useContext(AuthContext);
  const { t, tCommon } = useScopedTranslation("profile_page");
  const user = auth.user!; // auth.user must be defined here

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isUserDataFormLoading, setIsUserDataFormLoading] = useState(false);
  const [confirmationDialogOpen, setconfirmationDialogOpen] = useState(false);
  const [accountDeletionLoading, setAccountDeletionLoading] = useState<boolean>(false);
  const [accDelModalIsOpen, setAccDelModalIsOpen] = useState<boolean>(false);

  const isUserDataFormValid = () => {
    return !!firstName && !!lastName && (firstName !== user.firstName || lastName !== user.lastName);
  };

  const onUserDataFormValiatedClick = async () => {
    setIsUserDataFormLoading(true);
    const editInfos: UpdateUser = {
      firstName,
      lastName,
    };

    if (!auth.user?.userId) {
      // TODO: place this in Error File
      onNotif({ textKey: "error.user_edit_id_not_found" });
      return;
    }

    await editUser(editInfos, user.userId);
    const sessionUser = await getSessionUser(auth.user);
    if (!sessionUser) auth.unsetAuthUser();
    else auth.setAuthUser(sessionUser);
    setIsUserDataFormLoading(false);
  };

  const onNewPasswordSubmit = async (passw: string) => {
    setNewPassword(passw);
    setconfirmationDialogOpen(true);
  };

  const editUser = async (infos: UpdateUser, userId: string) => {
    await updateUser(onNotif, infos, userId);
  };

  const handleNewPasswordConfirmation = async () => {
    await updateUserPassword(onNotif, oldPassword, newPassword);
    setOldPassword("");
    setconfirmationDialogOpen(false);
  };

  const handleAccountDeletion = async () => {
    setAccountDeletionLoading(true);
    if (await deleteUser(onNotif, user.userId)) {
      logoutUser(auth);
      onNotif({ textKey: "success.account_deleted" });
    }
    setAccountDeletionLoading(false);
  };

  const lockedInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <LockOutlined />
      </InputAdornment>
    ),
  };

  return (
    <Box mt={5}>
      <ConfirmDialog
        open={accDelModalIsOpen}
        confirmText={user.email}
        onClose={() => setAccDelModalIsOpen(false)}
        onConfirm={handleAccountDeletion}
      />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setconfirmationDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("change_password_dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("change_password_dialog.content")}</DialogContentText>
          <PasswordInput
            label={t("change_password_dialog.field_current_password")}
            password={oldPassword}
            onChange={setOldPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setconfirmationDialogOpen(false)} color="primary">
            {tCommon("button_cancel")}
          </Button>
          <Button onClick={handleNewPasswordConfirmation} disabled={oldPassword.length <= 0} color="primary">
            {tCommon("button_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography style={{ fontWeight: 700, fontSize: 16, marginBottom: 10 }}>
            {t("information.title")}
          </Typography>
          <CustomTextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={(e) => setFirstName(e.target.value.trim())}
            label={t("information.first_name")}
            autoComplete="given-name" // This allows browser autofill functionality
            required={false}
          />
          <CustomTextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={(e) => setLastName(e.target.value.trim())}
            label={t("information.last_name")}
            autoComplete="family-name" // This allows browser autofill functionality
            required={false}
          />
          <Box m={2} />
          <CustomTextField
            value={user.email}
            label={t("information.email")}
            disabled
            InputProps={lockedInputProps}
            required={false}
          />
          <CustomTextField
            disabled
            label={t("information.assigned_roles")}
            value={user.roles.map((role) => role.name).join(", ") ?? "N/A"}
            InputProps={lockedInputProps}
            required={false}
          />
          <CustomTextField
            value={user.workspaceName}
            label={t("information.workspace")}
            disabled
            InputProps={lockedInputProps}
            required={false}
          />
          <Box m={3} />
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={!isUserDataFormValid() || isUserDataFormLoading}
            onClick={onUserDataFormValiatedClick}
            fullWidth
          >
            {isUserDataFormLoading ? t("button_saving") : tCommon("button_edit")}
          </Button>
          <Box m={3} />
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setAccDelModalIsOpen(true)}
            style={{ backgroundColor: theme.palette.error.light }}
            startIcon={accountDeletionLoading && <CircularProgress size={14} />}
          >
            {t("button_delete_account")}
          </Button>
        </Grid>

        <Grid item sm={12} md={6}>
          <Typography style={{ fontWeight: 700, fontSize: 16, marginBottom: 10 }}>
            {t("change_password")}
          </Typography>
          <ResetPasswordForm onValidate={(passw) => onNewPasswordSubmit(passw)} />
        </Grid>
      </Grid>
    </Box>
  );
}
