import {
  Box,
  Chip,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Theme,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Authorizations } from "@unissey/utils";
import React, { useState } from "react";
import { SettingsBetaDisabledIcon } from "../../components/SettingsBetaDisabledIcon";
import { SettingsBetaEnabledIcon } from "../../components/SettingsBetaEnabledIcon";
import { SettingsDisabledIcon } from "../../components/SettingsDisabledIcon";
import { SettingsIcon } from "../../components/SettingsIcon";

import { NO_PRESET, RndPreset, useInjection } from "../../hooks/use-injection";
import { useScopedTranslation } from "../../i18n";
import { SessionUser } from "../../types/auth";
import { humanReadableToEnumPreset, ReadablePreset } from "../../types/sdk";
import { InjectionInstructionsModal } from "./InjectionInstructionsModal";
import { InjectionInstructionsPopover } from "./InjectionInstructionsPopover";

const capitalize = (word: string): string => word[0].toUpperCase() + word.slice(1).toLowerCase();

// TODO: Create a custom theme and use variables instead of hard coded colors
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: "#f5f5f5",
      padding: "10px",
      maxWidth: "300px",
    },
    notActive: {
      color: "#979797",
    },
    title: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    subheading: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  })
);

const GreenChip = withStyles({
  root: {
    backgroundColor: "#0cdb91",
  },
  label: {
    color: "#fff",
  },
})(Chip);

const LighterGreenChip = withStyles({
  root: {
    backgroundColor: "#9aebcf",
  },
  label: {
    color: "#fff",
  },
})(Chip);

const GreenSwitch = withStyles({
  switchBase: {
    color: "#5e6c75",
    "&$checked": {
      color: "#0cdb91",
    },
    "&$checked + $track": {
      backgroundColor: "#fff",
      border: "solid 1px #0cdb91",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#fff",
    border: "solid 1px #5e6c75",
  },
})(Switch);

const LighterGreenSwitch = withStyles({
  switchBase: {
    color: "#9aebcf",
    "&$checked": {
      color: "#9aebcf",
    },
    "&$checked + $track": {
      backgroundColor: "#fff",
      border: "solid 4px #9aebcf",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#fff",
    border: "solid 1px #9aebcf",
  },
})(Switch);

type InjectionSettingsProps = {
  activated?: boolean;
  user?: SessionUser | null;
};

export const InjectionSettings = ({ activated, user }: InjectionSettingsProps) => {
  const {
    injectionEnabled,
    setInjectionStatus,
    rndPreset,
    setRndPreset,
    instructionsSeen,
    setInstructionsSeen,
  } = useInjection();

  const classes = useStyles();

  const { tCommon } = useScopedTranslation("demo_page");

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [presetAnchorEl, setPresetAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedPreset, setSelectedPreset] = useState<number>(
    rndPreset !== NO_PRESET ? humanReadableToEnumPreset[rndPreset] : -1
  );
  const [instructionsOpen, setInstructionsOpen] = useState(false);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) =>
    setPresetAnchorEl(event.currentTarget);

  const handlePresetMenuItemClick = (_: React.MouseEvent<HTMLElement>, preset: RndPreset, index: number) => {
    setRndPreset(preset);
    setSelectedPreset(index);
    setPresetAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActivationChange = (_: React.ChangeEvent, checked: boolean) => {
    if (checked && !instructionsSeen) {
      setInstructionsOpen(true);
    } else {
      setInjectionStatus(checked ? "enabled" : "disabled");
    }
  };

  const handleInjectionInstructionConfirm = () => {
    setInstructionsOpen(false);
    setInstructionsSeen(true);
    setInjectionStatus("enabled");
  };

  let showRndPreset = false;

  if (activated && !!user) {
    showRndPreset = user.authorizations.includes(Authorizations.DEMO_PRESETS);
  }

  const open = Boolean(anchorEl);
  const id = open ? "injection-settings-popover" : undefined;

  const presetOpen = Boolean(presetAnchorEl);
  // const presetId = presetOpen ? "rnd-preset-settings-menu" : undefined;

  const displayedIcon = (() => {
    // Component is active (user can interact with it) and injection is enabled. It displays wheel icon with beta indicator
    if (activated && injectionEnabled) return <SettingsBetaEnabledIcon />;

    // Component is active and injection is disabled. It displays wheel icon without Beta
    if (activated && !injectionEnabled) return <SettingsIcon />;

    // Component is not active and injection is enabled. It displays lighter wheel icon with beta indicator
    if (!activated && injectionEnabled) return <SettingsBetaDisabledIcon />;

    // Component is not active and injection is disabled. It displays lighter wheel icon
    if (!activated && !injectionEnabled) return <SettingsDisabledIcon />;
  })();

  return (
    <Box>
      <InjectionInstructionsModal
        open={instructionsOpen}
        onClose={() => setInstructionsOpen(false)}
        onConfirm={handleInjectionInstructionConfirm}
      />

      <Box style={{ cursor: "pointer" }} onClick={handleClick}>
        {displayedIcon}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
      >
        <Box className={`${classes.box} ${activated ? "" : classes.notActive}`}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box mb="1em">
              <Typography className={`${classes.title}`}>
                <Box display="inline-block" mr="4px">
                  {activated ? (
                    <GreenChip size="small" label="BETA" />
                  ) : (
                    <LighterGreenChip size="small" label="BETA" />
                  )}
                </Box>
                {tCommon("settings.injection_activation_title")}
                <InjectionInstructionsPopover />
              </Typography>
            </Box>
            <Box ml="3em">
              {activated ? (
                <GreenSwitch
                  checked={injectionEnabled}
                  onChange={handleActivationChange}
                  style={{ display: "inline-block" }}
                />
              ) : (
                <LighterGreenSwitch checked={injectionEnabled} disabled />
              )}
            </Box>
          </Box>
          {showRndPreset ? (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box mb="1em">
                <Typography className={classes.title}>{tCommon("settings.preset_title")}</Typography>
              </Box>
              <Box ml="3em">
                <List>
                  <ListItem
                    button
                    id="preset-settings"
                    aria-haspopup="listbox"
                    aria-controls="rnd-preset-select"
                    aria-expanded={presetOpen ? "true" : undefined}
                    onClick={handleClickListItem}
                  >
                    <ListItemText secondary={rndPreset !== NO_PRESET ? capitalize(rndPreset) : NO_PRESET} />
                  </ListItem>
                </List>
                <Menu
                  anchorEl={presetAnchorEl}
                  id="rnd-preset-select"
                  open={presetOpen}
                  MenuListProps={{
                    "aria-labelledby": "preset-settings",
                    role: "listbox",
                  }}
                >
                  <MenuItem
                    key={-1}
                    onClick={(event) => handlePresetMenuItemClick(event, NO_PRESET, -1)}
                    //selected={selectedPreset === -1}
                  >
                    {NO_PRESET}
                  </MenuItem>
                  {Object.entries(humanReadableToEnumPreset).map(([name], idx) => (
                    <MenuItem
                      key={idx}
                      selected={idx === selectedPreset}
                      onClick={(event) => handlePresetMenuItemClick(event, name as ReadablePreset, idx)}
                    >
                      {capitalize(name)}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          ) : undefined}
          <Typography component="em" className={`${classes.subheading}`}>
            {tCommon("settings.injection_activation_help")}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};
