import { Box } from "@material-ui/core";
import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CircularProgress } from "@material-ui/core";

import { VqcLevels } from "@unissey/sdk-react__next/dist/types";

// SDK
import {
  FullCapture,
  TargetCapture,
  Button as UniButton,
  RetryResult,
  Presets,
} from "@unissey/sdk-react__next";

// components
import DemoResults from "./DemoResult";

// constants
// import { config } from "../../constants/env";
import { Routes } from "../../constants/routes";
// services
import StorageService from "../../services/storage_service";
// types
import { isMobileDevice } from "../../utils/misc_util";
import { DemoMode } from "../../types/demo";
import { useScopedTranslation } from "../../i18n";
import { InjectionSettings } from "./InjectionSettings";

import { useInjection } from "../../hooks/use-injection";
import { RecordData } from "@unissey/sdk-react__next/dist/types";
import { analyze, AnalyzeResponse, getVqcHintsType } from "../../services/analyze_service";
import { enumPresetToHumanReadable, humanReadableToEnumPreset, ReadablePreset } from "../../types/sdk";
import { setSessionUserId } from "../../services/api_services";
import InfoCard from "../../components/InfoCard";
import { ErrorOutlined, SignalWifiOff } from "@material-ui/icons";

export default function DemoPage() {
  const { t } = useScopedTranslation("demo_page.results_screen");

  const { injectionEnabled, rndPreset } = useInjection();

  const selectedPreset = rndPreset !== "N/A" ? rndPreset : enumPresetToHumanReadable[Presets.FAST];

  const user = StorageService.getStoredUserAuth();

  const location = useLocation();
  const history = useHistory();

  const faceMatching = location.state ? (location?.state as any).faceMatching : undefined;

  // We're not supposed to get here without authentication or keys -> redirect to signin page
  if (!user || !user.defaultApp || !user.injectionApp) {
    return (
      <Redirect
        to={{
          pathname: Routes.SIGNIN,
          state: { from: location },
          search: location.search,
        }}
      />
    );
  }

  // We're not supposed to get here without having consented to TOUs -> redirect to TOU page
  if (!user.privacy_policy && !user.terms_of_use) {
    return <Redirect to={Routes.DEMO_TOU + location.search} />;
  }

  const app = injectionEnabled ? user.injectionApp : user.defaultApp;

  const handleBack = () => history.goBack();

  return (
    <Demo
      mode={faceMatching ? "liveness-and-face-comparison" : "liveness-only"}
      apiKey={app.key}
      vqcLevel={app.preferredVqcLevel}
      gdprConsent={(user.privacy_policy && user.terms_of_use) === true}
      onExit={() => history.push(Routes.DEMO_TOU + location.search)}
      onFinish={() => history.push(Routes.DEMO_TOU)}
      finishText={t("finish")}
      preset={selectedPreset}
      userId={user.userId}
      onBack={handleBack}
    />
  );
}

type Props = {
  mode: DemoMode;
  apiKey: string;
  gdprConsent: boolean;
  onExit?: () => void;
  onFinish: () => void;
  finishText: string;
  userId: string;
  onBack: () => void;
  preset: ReadablePreset;
  vqcLevel: VqcLevels;
};

type DemoStep =
  | "capture"
  | "analyzing"
  | "retry"
  | "result"
  | "liveness-retry"
  | "network-error"
  | "analysis-error";

export function Demo({
  mode,
  apiKey,
  gdprConsent,
  onExit,
  onFinish,
  onBack,
  finishText,
  userId,
  preset,
  vqcLevel,
}: Props) {
  const { t, tCommon } = useScopedTranslation("demo_page");

  const [referenceData, setReferenceData] = React.useState(undefined as Blob | undefined);
  const [analyzeResponse, setAnalyzeResponse] = React.useState(undefined as AnalyzeResponse | undefined);
  const [bundleId, setBundleId] = React.useState("");
  const [step, setStep] = React.useState("capture" as DemoStep);

  const [analysisErrMsg, setAnalysisErrMsg] = React.useState("");
  const [analysisErrDetails, setAnalysisErrDetails] = React.useState("");

  const sdkStrings = t("sdk_strings", { returnObjects: true });

  const onApiResponse = async (response: AnalyzeResponse) => {
    try {
      if (userId) {
        await setSessionUserId(() => {}, `${response.data.session_group_id}`, userId);
      }
    } catch (e) {
      // TODO: Send error for logging.
    }
  };

  const handleRetry = () => setStep("liveness-retry");

  const handleFinish = () => setStep("result");

  const handleReference = (data: Blob) => {
    setReferenceData(data);
  };

  const handleTarget = async (data: RecordData) => {
    setStep("analyzing");

    try {
      const analyzeResponse = await analyze({
        apiKey,
        selfie: data,
        gdprConsent: true,
        reference: referenceData,
        bundleId,
      });

      if (analyzeResponse.error) {
        setAnalysisErrMsg(analyzeResponse.error.message);
        setAnalysisErrDetails(analyzeResponse.error.details);
        setStep("analysis-error");
        return;
      }

      setAnalyzeResponse(analyzeResponse);
      setBundleId(analyzeResponse.data.session_group_id);

      if (analyzeResponse.data.retries_remaining === 0) setStep("result");

      if (analyzeResponse.data.retries_remaining > 0) setStep("retry");

      await onApiResponse(analyzeResponse);
    } catch (e) {
      if (e instanceof TypeError) {
        setStep("network-error");
      }
    }
  };

  const isMobile = isMobileDevice();

  const backButton = (
    <UniButton variant="outlined" onClick={onBack}>
      <div slot="icon" style={{ fontSize: "12px" }}>
        <ArrowBackIosIcon fontSize="inherit" />
      </div>
      {tCommon("button_back")}
    </UniButton>
  );

  const stepToDisplay = {
    capture: (
      <>
        {mode === "liveness-only" && (
          <TargetCapture
            strings={sdkStrings.targetCapture}
            onTarget={handleTarget}
            actionBtns={backButton}
            videoRecorderOptions={{ preset: humanReadableToEnumPreset[preset], vqcLevel }}
            useExternal={true}
          />
        )}

        {mode === "liveness-and-face-comparison" && (
          <FullCapture
            strings={{
              target: sdkStrings.targetCapture,
              reference: sdkStrings.referenceCapture,
            }}
            onReference={handleReference}
            onTarget={handleTarget}
            actionBtns={backButton}
            videoRecorderOptions={{ preset: humanReadableToEnumPreset[preset], vqcLevel }}
            useExternal={true}
          />
        )}
      </>
    ),
    result: analyzeResponse && (
      <DemoResults finishText={finishText} response={analyzeResponse} onFinish={onFinish} />
    ),
    analyzing: (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="300px"
        height="350px"
      >
        <CircularProgress /> <br /> <br /> <span style={{ fontSize: "16px" }}>{t("analysing")}...</span>
      </Box>
    ),
    retry: analyzeResponse && (
      <RetryResult
        hintKind={`${getVqcHintsType(analyzeResponse)}`}
        retriesLeft={analyzeResponse.data.retries_remaining}
        onFinish={handleFinish}
        onRetry={handleRetry}
        strings={sdkStrings.retryResult}
      />
    ),
    "liveness-retry": (
      <TargetCapture strings={sdkStrings.targetCapture} onTarget={handleTarget} actionBtns={backButton} />
    ),
    "network-error": (
      <Box width="300px" height="300px" display="flex" flexDirection="column" alignItems="center">
        <InfoCard text={t("network_err")} icon={SignalWifiOff} /> <br /> {backButton}
      </Box>
    ),
    "analysis-error": (
      <Box width="300px" height="300px" display="flex" flexDirection="column" alignItems="center">
        <InfoCard text={`${analysisErrMsg} : ${analysisErrDetails}`} icon={ErrorOutlined} /> <br />{" "}
        {backButton}
      </Box>
    ),
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mr="1.5em" mt="2em" display="flex" justifyContent="flex-end">
        <InjectionSettings />
      </Box>
      <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
        <Box
          display={isMobile ? "block" : "flex"}
          alignItems="center"
          justifyContent="center"
          m={isMobile ? "0" : "auto"}
          paddingTop={isMobile ? 8 : 2}
          maxWidth={isMobile ? "100%" : "85%"}
        >
          {stepToDisplay[step]}
        </Box>
        <Box mt={20} />
      </div>
    </Box>
  );
}
