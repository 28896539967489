import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Copyright from "../../components/Copyright";
import { LanguageSwitchMenu } from "../../components/LanguageSwitchMenu";

const useStyles = makeStyles(() => ({
  background: {
    width: "100%",
    background: `url(${process.env.PUBLIC_URL}/images/login-bg.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "40% auto",
    backgroundPosition: "bottom 0px left 0px",
    opacity: 0.9,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    fontWeight: 700,
  },
}));

export const AuthPageContainer: React.FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.background} maxWidth={false}>
      <Box display="flex" justifyContent="flex-end">
        <LanguageSwitchMenu />
      </Box>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <img src={`${process.env.PUBLIC_URL}/images/unissey-logo.svg`} alt="Unissey" />

        <Grid container direction="column" style={{ flexGrow: 0.5 }}>
          <Box mt={8} />

          <Typography component="h1" variant="h5" align="center" className={classes.title}>
            {title}
          </Typography>

          <Box mt={8} />

          {children}

          <Box mt={8}>
            <Copyright />
          </Box>
        </Grid>
      </Container>
    </Container>
  );
};
